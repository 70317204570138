import React, {useState, useEffect} from 'react'
import { operacionesListado } from '../../estadosIniciales/listas/tipoOperacion-lista';
import { Modal, Form, Table, Button, Icon, Pagination, Container, Label  } from 'semantic-ui-react';
import {consultaServicios} from '../../servicios/servicios/servicios.srv'
import { estadoInicialFiltro} from '../../estadosIniciales/servicios/estadoInicial-servicio'

const ConsultaModalServicios = ({
    datosPaquete,
    modalServicios,
    actualizar,
    setModalServicios,
    setDatosPaquete,
}) => {

    const [datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ servicios, setServicios ] = useState([])
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })
    const consultar = async(filtro) => {
        const respuesta = await consultaServicios(filtro)
        if(respuesta.data.estatus){
            setServicios(respuesta.data.data.servicios)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.data.total / 10)
            })
        }
    }
    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setDatosFiltrado({
            ...datosFiltrado,
            salto: (activePage - 1) * 10 
        })
    }
    useEffect(() => {
       consultar(datosFiltrado)
    }, [datosFiltrado])

    const onChangeDatosFiltrado = (e, {name, value}) => {
        setDatosFiltrado({
            ...datosFiltrado,
            [name]:value
        })
    }
    const onChangeDatosFiltradoModelo = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            modelo:{
                ...datosFiltrado.modelo,
                [e.target.name]:e.target.value
            }
        })
    }
    const buttonActivo = () => {
        setDatosFiltrado({
            ...datosFiltrado,
            activo: !datosFiltrado.activo
        })
    }
    const elegirServicio = (id) => {
        let conjunto = datosPaquete.servicios;
        const existeServicio = conjunto.find( (i) => {
            if (id === i) {
                return i
            }
        } )
        if (existeServicio) {
            return;
        }
        conjunto.push(id)
        setDatosPaquete({
            ...datosPaquete,
            servicios:conjunto
        })
        actualizar(datosPaquete)
        setModalServicios(false)
    }
    return (
        <Modal
            open={modalServicios}
        >
            <Modal.Header> <Icon name="settings"/> Elegir Servicios </Modal.Header>
            <Modal.Content>
            <Form>
                <Form.Group
                    widths="equal"
                >
                    <Form.Input
                        label="Modelo"
                        placeholder="Codigo del modelo"
                        name="codigo"
                        value={datosFiltrado.modelo.codigo}
                        onChange={onChangeDatosFiltradoModelo}
                    />
                    <Form.Input
                        label="Version"
                        placeholder="Version del modelo"
                        name="version"
                        value={datosFiltrado.modelo.version}
                        onChange={onChangeDatosFiltradoModelo}
                    />
                    <Form.Input
                        label="Nombre"
                        placeholder="Nombre"
                        name="nombre"
                        value={datosFiltrado.nombre}
                        onChange={onChangeDatosFiltrado}
                    />
                    <Form.Input
                        label="Descripcion"
                        placeholder="Descripcion"
                        name="descripcion"
                        value={datosFiltrado.descripcion}
                        onChange={onChangeDatosFiltrado}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Select
                        clearable
                        label="Tipo"
                        placeholder="Tipo"
                        name="tipo"
                        options={operacionesListado}
                        value={datosFiltrado.tipo}
                        onChange={onChangeDatosFiltrado}
                    />
                    <Form.Field>
                        <label>Estado de Servicios</label>
                        <Form.Checkbox
                            style={{fontWeight: "bold", marginTop:5}}
                            checked={datosFiltrado.activo}
                            toggle
                            label="Activos"
                            name="activo"
                            onChange={() => buttonActivo()}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <Container fluid style={{overflowX: 'scroll'}}>
            <Table size="small" textAlign="center" celled structured>
                <Table.Header>
                    <Table.Row>
                            <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">TIPO</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">MODELO</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">FRECUENCIA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">VIGENCIA</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"> ACCIONES </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                            <Table.HeaderCell> CODIGO </Table.HeaderCell>
                            <Table.HeaderCell> VERSIONES </Table.HeaderCell>
                            <Table.HeaderCell> KILOMETRAJE</Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                            <Table.HeaderCell> KILOMETRAJE </Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {servicios !== undefined && servicios.length > 0 ?
                        servicios.map(item => (
                            <Table.Row key={item._id}>
                                <Table.Cell> {item.nombre} </Table.Cell>
                                <Table.Cell> {item.descripcion} </Table.Cell>
                                <Table.Cell> {item.tipo} </Table.Cell>
                                <Table.Cell> {item.modelo.codigo} </Table.Cell>
                                                    <Table.Cell> { item.modelo.version.map( (i, index) => {
                                    return <Label style={{ marginBottom: 5}} key={i+index}>{i}</Label>
                                })
                                    }  </Table.Cell>
                                <Table.Cell> {item.frecuencia.kilometraje} </Table.Cell>
                                <Table.Cell> {item.frecuencia.meses} </Table.Cell>
                                <Table.Cell> {item.vigencia.kilometraje} </Table.Cell>
                                <Table.Cell> {item.vigencia.meses} </Table.Cell>
                                <Table.Cell>
                                <Button
                                    icon="check"
                                    size="tiny"
                                    onClick={() => elegirServicio(item._id)}
                                    toggle
                                />
                                </Table.Cell>
                            </Table.Row>
                        )): null}
                </Table.Body>
            </Table>
            </Container>
                <Pagination 
                    style={{marginTop: 10 }}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="CANCELAR"
                    icon="times"
                    size="mini"
                    onClick={() => setModalServicios(false)}
                    style={{borderRadius: 25}}
                />
            </Modal.Actions>
        </Modal>
     );
}
 
export default ConsultaModalServicios;

