import { clienteAxios  } from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const consultaRepuestos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/repuesto/consulta', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: 'Ha ocurrido un error'
            }
        })
    }
}

export const consultarRepuestosModal = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/repuesto/modalConsulta', payload)
        return(respuesta.data)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: 'Ha ocurrido un error'
            }
        })
    }
}
export const crearRepuestos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/repuesto/crear', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarRepuestos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/repuesto/actualizar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarRepuestos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/repuesto/eliminar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}