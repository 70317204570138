import React, { useState, Fragment } from 'react';
import { Form, Button, Icon, Modal, Select, Table, Checkbox } from 'semantic-ui-react';
import { listaContenidoVersion } from '../../estadosIniciales/listas/contenido-lista';
import { EVENTO_ACTUALIZAR, EVENTO_CREAR } from '../../enum/eventos/tipo-evento';

const FormularioVersiones = ({
    actualizar,
    evento,
    modalActivo,
    setModalActivo,
    version,
    setVersion,
    campo,
    setCampo,
    crear
}) => {
    
    const [ clave, setClave ] = useState({ 
        nombre:'', 
        valor:'' 
    })
    const onChangeVersion = e => {
        setVersion({
            ...version,
            [e.target.name]: e.target.value
        })
    }

    const onChangeCampo = ( e, { name, value }) => {
        setCampo({
            ...campo,
            [name]: value
        })
    }

    const agregarCampo = () => {

        const buscarContenido = version.campos.find( i => {
            if ( i.contenido === campo.contenido) {
                return campo
            }
        })

        if ( buscarContenido || !campo.contenido ) {
            return 
        }
        setVersion({
            ...version,
            campos:[
                ...version.campos,
                campo
            ]
        })
        setCampo( Object.assign({}, campo ))
    }

    const eliminarCampo = ( campo ) => {
        const campos = version.campos
        const index = campos.indexOf( campo)

        campos.splice(index, 1)
        
        setVersion({
            ...version,
            campos: campos
        })
    }


    const onChangeClave = e => {
        setClave({
            ...clave,
            [e.target.name]: e.target.value
        })
    }

    const agregarClave = ( elemento ) => {

        let arrayAEditar = version[elemento]

        if ( !(clave.valor && clave.nombre ) ) {
            return
        }

        arrayAEditar.push( clave )
        
        setVersion({
            ...version,
            [elemento]: arrayAEditar
        })

        setClave({
            nombre:'', 
            valor:'' 
        })
        
    }

    const eliminarClave = (elemento, index ) => {

        let arrayAEditar = version[elemento]
        arrayAEditar.splice( index, 1 )
        setVersion({
            ...version,
            [elemento]: arrayAEditar
        })

        setClave({
            nombre:'', 
            valor:'' 
        })
    }
    
    return (
        <Fragment>
            <Modal open={modalActivo} >
                <Modal.Header>
                    <Icon name="edit" color="red"/> Formulario Versiones
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <h3>Control</h3>
                        <Form.Group>
                            <Form.Input
                                value={version.versionControl}
                                icon="barcode"
                                placeholder="Requerido"
                                label="Version control"
                                name="versionControl"
                                onChange={onChangeVersion}
                            />
                            <Form.Field>
                                <label>Estatus</label>
                                <Checkbox
                                    label="Estatus de version"
                                    checked={version.activo}
                                    toggle
                                    onChange={()=> setVersion({...version, activo: !version.activo})}
                                />
                            </Form.Field>
                        </Form.Group>
                        <h3>Campos</h3>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Contenido</label>
                                <Select
                                    name="contenido"
                                    placeholder="Contenido"
                                    onChange={onChangeCampo}
                                    options={listaContenidoVersion} />
                            </Form.Field>
                            <Form.Input 
                                label="Cantidad caracteres"
                                type="number" 
                                name="caracteres"
                                placeholder="Requerido"
                                onChange={onChangeCampo} />
                            <Form.Input 
                                label="Posicion"
                                type="number" 
                                name="posicion"
                                placeholder="Requerido"
                                onChange={onChangeCampo} />
                            <Form.Field>
                                <label>Agregar</label>
                                <Button 
                                    onClick={()=> agregarCampo() }
                                    icon="plus"
                                    content="AGREGAR CAMPO"
                                />
                            </Form.Field>
                        </Form.Group>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Posicion</Table.HeaderCell>
                                    <Table.HeaderCell>Contenido</Table.HeaderCell>
                                    <Table.HeaderCell>Caracteres</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { version.campos.map( (i)=> {
                                    return (
                                        <Table.Row key={i.contenido}>
                                            <Table.Cell>{i.posicion}</Table.Cell>
                                            <Table.Cell>{i.contenido}</Table.Cell>
                                            <Table.Cell>{i.caracteres}</Table.Cell>
                                            <Table.Cell>
                                                <Button 
                                                    onClick={()=> eliminarCampo(i)}
                                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                                    icon="trash" />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                } )}
                            </Table.Body>
                        </Table>
                    </Form>

                    <h4>Codigo Modelo</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('codigoModelo')} />
                        </Form.Group>

                        {version.codigoModelo.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('codigoModelo', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Aire Acondicionado</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('aireAcondicionado')} />
                        </Form.Group>

                        {version.aireAcondicionado.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('aireAcondicionado', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Codigo de Equipamiento</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('codigoEquipamiento')} />
                        </Form.Group>

                        {version.codigoEquipamiento.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('codigoEquipamiento', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Transmision</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('transmision')} />
                        </Form.Group>

                        {version.transmision.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('transmision', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Espacio libre</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('espacioLibre')} />
                        </Form.Group>

                        {version.espacioLibre.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('espacioLibre', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Generacion vehiculo</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('generacionVehiculo')} />
                        </Form.Group>

                        {version.generacionVehiculo.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('generacionVehiculo', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Motor</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('motor')} />
                        </Form.Group>

                        {version.motor.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('motor', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Numero Puertas</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('numeroPuertas')} />
                        </Form.Group>

                        {version.numeroPuertas.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('numeroPuertas', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Prefico fabrica</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('prefijoFabrica')} />
                        </Form.Group>

                        {version.prefijoFabrica.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('prefijoFabrica', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Traccion</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('traccion')} />
                        </Form.Group>

                        {version.traccion.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('traccion', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Uso</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('uso')} />
                        </Form.Group>

                        {version.uso.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('uso', index )} />
                            </Form.Group>
                        } )}

                    </Form>

                    <h4>Control de cambio</h4>
                    <Form>
                        <Form.Group widths="16">
                            <Form.Input 
                                name="nombre"
                                 
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Nombre" />
                            <Form.Input 
                                name="valor" 
                                
                                onChange={onChangeClave} 
                                width="6" 
                                placeholder="Valor" />
                            <Button content="AGREGAR" onClick={() => agregarClave('versionCodigoEquipamiento')} />
                        </Form.Group>

                        {version.versionCodigoEquipamiento.map( (i, index) => {
                            return <Form.Group widths="16" key={index}>
                                <Form.Input value={i.nombre} width="6" placeholder="Nombre" />
                                <Form.Input value={i.valor} width="6" placeholder="Valor" />
                                <Button 
                                    icon="trash"
                                    content="ELIMINAR" 
                                    onClick={() => eliminarClave('versionCodigoEquipamiento', index )} />
                            </Form.Group>
                        } )}

                    </Form>
                    
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => setModalActivo(false)}
                    />
                    { evento === EVENTO_CREAR ? <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR" 
                        icon="save"
                        onClick={()=> crear()}
                    />: null }
                    { evento === EVENTO_ACTUALIZAR ? <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR" 
                        icon="save"
                        onClick={()=> actualizar()}
                    />: null }
                </Modal.Actions>
            </Modal>
        </Fragment>
    )
}

export default FormularioVersiones