import React, {useState, useEffect} from 'react';
import {Grid, Container, Header, Button, Table, Loader, Modal, Form, Checkbox } from 'semantic-ui-react';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento';
import FormularioVersiones from '../../components/formularios/formulario.versiones';
import { estadoInicialVersiones, estadoInicialCampos, estadoInicialClaves } from '../../estadosIniciales/versiones/estadoInicial.versiones';
import { actualizarVersion, crearVersion, consultaVersion, eliminarVersion } from '../../servicios/versiones/versiones.srv';
import ItemVersion from '../../components/itemsListados/item-versiones';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const VersionesIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Variable',
        mensaje: '¿Esta seguro que quiere eliminar esta variable?'
    }
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ evento, setEvento ] = useState('')
    const [ version, setVersion ] = useState( Object.assign( {}, estadoInicialVersiones ))
    const [ versiones, setVersiones ] = useState([])
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ cargando, setCargando ] = useState(false)
    const [ versionEliminar, setVersionEliminar ] = useState('')
    const [ campo, setCampo ] = useState( Object.assign( {}, estadoInicialCampos ))
    // eslint-disable-next-line
    const [ clave, setClave ] = useState( Object.assign( {}, estadoInicialClaves ))
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ filtro, setFiltro ] = useState({
        activo: true
    })

    useEffect( ()=>{
        if ( filtro ) {
            consulta()
        }
        
    }, [filtro] )

    const actualizar = async () => {
        const respuesta = await actualizarVersion( version )

        if ( respuesta.estatus ) {
            setModalActivo(false)
            setVersion( Object.assign( {}, estadoInicialVersiones ) )
            consulta()
            setEvento( '' )
        }
        setModalMensajeEstatus({
            activo: true,
            mensaje: respuesta.resultadoOperacion
        })

    }

    const crear = async () => {
        const respuesta = await crearVersion( version )
        if ( respuesta.estatus ) {
            setModalActivo(false)
            setVersion( Object.assign( {}, estadoInicialVersiones ) )
            consulta()
            setEvento( '' )
        }
        setModalMensajeEstatus({
            activo: true,
            mensaje: respuesta.resultadoOperacion
        })
    }

    const consulta = async  () => {
        const respuesta = await consultaVersion( filtro )
        if ( respuesta.estatus ) {
            setVersiones( respuesta.data.versiones )
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setCargandoLoader(false)
        }
    }

    const eliminar = async ( id ) => {
        const respuesta = await eliminarVersion({id})
        if(respuesta.estatus){
            setTimeout(() => {
                setModalActivo(false)
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consulta()
        } else{
            setTimeout(() => {
                setModalActivo(false)
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consulta()
        }
    }

    const buttonActivo = () => {
        setFiltro({
            activo: !filtro.activo
        })
    }

    if(cargandoLoader){
        return <Preloader/>
    }

    return(
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Versiones
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setVersion( Object.assign( {}, estadoInicialVersiones )); setEvento(EVENTO_CREAR); setModalActivo(true)}} 
                        content="Nueva version"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        floated='right'
                    />
                    </span>
                </Header>
                <h3>Filtrar por</h3>
                <Form.Group>
                    <Form.Field>
                        <Checkbox
                            label="Versiones activas"
                            checked={filtro.activo}
                            toggle
                            onChange={buttonActivo}
                        />
                    </Form.Field>
                </Form.Group>
                <FormularioVersiones
                    evento={evento}
                    version={version}
                    setVersion={setVersion}
                    campo={campo}
                    setCampo={setCampo}
                    clave={clave}
                    modalActivo={modalActivo}
                    setModalActivo={setModalActivo}
                    crear={crear}
                    actualizar={actualizar} />
                <Table singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ESTADO</Table.HeaderCell>
                            <Table.HeaderCell>VERSION CONTROL</Table.HeaderCell>
                            <Table.HeaderCell>CAMPOS [Caracteres]</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {versiones.map( i => (
                            <ItemVersion
                                setModalEliminar={setModalEliminar}
                                setEvento={setEvento}
                                setVersion={setVersion}
                                setObjetoEliminar={setVersionEliminar}
                                setModalActivo={setModalActivo}
                                item={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>
                </Table>
                { versiones.length < 1 ? <NoItems /> : null }
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={versionEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => eliminar(value)}
                    setObjetoEliminar={setVersionEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            { cargando? 
                <Modal open>
                    <Loader size="large" inverted active/>
                </Modal>
                    : 
            null }
        </Grid>
     )
}

export default VersionesIC