import S3 from 'react-aws-s3';

export const cargarArchivoS3 = async ( file, fileName ) => {
    const config = {
        bucketName: 'mbaas-kia',
        dirName: '',
        region: 'us-east-1',
        accessKeyId: 'AKIA4SOYQTLPIJ55H77W',
        secretAccessKey: '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',
        s3Url: 'https://mbaas-kia.s3.amazonaws.com'
    }

    const reactS3Client = new S3( config );
    // return reactS3Client.uploadFile( file, fileName )
    // .then( data => {

    //     if ( data.status === 204 ) {
    //         console.log('Success', data );
    //         return data;
    //     } else {
    //         console.log('Fallo');
    //     }
    // }, error => {
    //     console.log('Error:', error );
    //     return error 
    // } )


    let respuesta;
    try {
        respuesta = await reactS3Client.uploadFile( file, fileName );

        return respuesta;
    } catch (error) {

        console.log('Error carga imagen: ', JSON.stringify(error))
        return error
    }
}