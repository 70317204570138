import React, { useState, useEffect } from 'react';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento'
import {Grid, Container, Header, Button, Table, Loader, Modal, Pagination } from 'semantic-ui-react';
import { estadoInicialFiltro } from '../../estadosIniciales/repuestos/estadoInicial-repuestos';
import { consultaRepuestos, crearRepuestos, actualizarRepuestos, eliminarRepuestos } from '../../servicios/repuestos/repuestos.srv';
import FiltroRepuestos from '../../components/filtros/filtro.repuestos';
import FormularioRepuestos from '../../components/formularios/formulario.repuestos';
import FormularioCargaRepuestos from '../../components/formularios/formulario.cargaRepuestos';
import ItemRepuestos from '../../components/itemsListados/item-repuesto';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const RepuestosIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Repuesto',
        mensaje: '¿Esta seguro que quiere eliminar este repuesto?'
    }
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ cargandoPreLoader, setCargandoLoader ] = useState(true)
    const [ evento, setEvento ] = useState('')
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ cargando, setCargando ] = useState(false)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ repuestoEliminar, setRepuestoEliminar ] = useState('')
    const [ repuestos, setRepuestos ] = useState([])
    const [ repuesto, setRepuesto ] = useState({})
    const [ repuestosCargados, setRepuestosCargados ] = useState([])
    const [ modalFormularioCargaRepuestos, setModalFormularioCargaRepuestos ] = useState(false)


    //PETICION A LA LISTA
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltro))
    
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })

    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }
    const consultar = async(filtro) => {
        const respuesta = await consultaRepuestos(filtro)
        if(respuesta.data.estatus){
            setRepuestos(respuesta.data.data.repuestos)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.data.total / 10)
            })
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargandoLoader(false)
        }

    }

    useEffect(() => {
        if(filtro){
            consultar(filtro)
        }
        // eslint-disable-next-line
    }, [filtro])
    //CRUD
    //CREAR
    const CrearRepuesto = async(payload) => {
        const respuesta = await crearRepuestos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ACTUALIZAR 
    const ActualizarRepuestos = async(payload) => {
        const respuesta = await actualizarRepuestos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ELIMINAR
    const EliminarRepuestos = async(id) => {
        const respuesta = await eliminarRepuestos({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }

    if(cargandoPreLoader){
        return <Preloader/>
    }

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Repuestos
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}} 
                        content="Nuevo repuesto"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }} 
                        floated='right'
                    />
                    <Button
                        content="Cargar repuestos csv"
                        onClick={() => setModalFormularioCargaRepuestos(true)} 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny"
                        floated="right"
                    />
                    </span>
                </Header>
                <FiltroRepuestos
                    setFiltro={setFiltro}
                />
                <FormularioRepuestos
                    actualizar={(payload) => ActualizarRepuestos(payload)}
                    guardar={(payload) => CrearRepuesto(payload)}
                    setModalActivo={setModalActivo}
                    setEvento={setEvento}
                    repuesto={repuesto}
                    evento={evento}
                    modalActivo={modalActivo}
                />

                <FormularioCargaRepuestos 
                    modalActivo={modalFormularioCargaRepuestos}
                    setModalActivo={setModalFormularioCargaRepuestos}
                    repuestos={repuestosCargados}
                    setRepuestos={setRepuestosCargados}
                    setModalMensaje={setModalMensajeEstatus}
                />
                
                <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                    <Table textAlign="center" celled structured singleLine compact striped unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan="2">MODELO</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">REFERENCIA</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">PNC</Table.HeaderCell>
                                <Table.HeaderCell colSpan="4">CARACTERISTICAS</Table.HeaderCell>
                                <Table.HeaderCell colSpan="2">PRECIO</Table.HeaderCell>
                                <Table.HeaderCell colSpan="3">PRECIO ANTERIOR</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>AIRE ACOND</Table.HeaderCell>
                                <Table.HeaderCell>CILINDRADA</Table.HeaderCell>
                                <Table.HeaderCell>COMBUSTIBLE</Table.HeaderCell>
                                <Table.HeaderCell>TRANSMISION</Table.HeaderCell>
                                <Table.HeaderCell>PUBLICO</Table.HeaderCell>
                                <Table.HeaderCell>CONCESIONARIO</Table.HeaderCell>
                                <Table.HeaderCell>PUBLICO</Table.HeaderCell>
                                <Table.HeaderCell>CONCESIONARIO</Table.HeaderCell>
                                <Table.HeaderCell>FECHA</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {repuestos !== [] ? repuestos.map( i => (
                                <ItemRepuestos
                                    setModalEliminar={setModalEliminar}
                                    setObjetoEliminar={setRepuestoEliminar}
                                    setEvento={setEvento}
                                    setModalActivo={setModalActivo}
                                    setRepuesto={setRepuesto}
                                    item={i}
                                    key={i._id}
                                />
                            )) : null}
                        </Table.Body>
                    </Table>
                </Container>
                
                { repuestos.length < 1 ? <NoItems /> : null }
                <Pagination 
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={repuestoEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarRepuestos(value)}
                    setObjetoEliminar={setRepuestoEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
    );
}
 
export default RepuestosIC;