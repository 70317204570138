import {clienteAxios} from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const eliminarTerminos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/terminosyCondiciones/eliminar', payload)        
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}
export const actualizarTerminos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/terminosyCondiciones/actualizar', payload)        
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}
export const crearTerminos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/terminosyCondiciones/crear', payload)        
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}
export const consultaTerminos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/terminosyCondiciones/consulta', payload)
        return(respuesta.data)
    } catch (error) {
        return({
            estatus: false,
            resultadoOperacion: "Ha ocurrido un problema"
        })
    }
}