import React from 'react';
import { Grid, Table, Icon, Header, Segment, Label } from 'semantic-ui-react';
import currencyFormatter from 'currency-formatter';
import NoItems from '../../components/utilidades/noItems';
import { VISTA_ACUERDO_SERVICIOS_PREPAGADOS } from '../../enum/eventos/tipo-evento';


const DatosPaquete = ({
    i,
    vista
}) => {


    const renderServicios = () => {
        if ( i.detallesServicios && i.detallesServicios.length>0) {
            return i.detallesServicios.map( (i) => {
                console.log('Servicios: ', i)
                return <Grid.Column style={{marginTop: 10}} key={i._id}> 
                    <Segment>
                        <Header as='h3'>
                            {i.nombre}
                            <Header.Subheader>
                                {i.descripcion} <Label size='tiny'>{i.tipo}</Label>
                            </Header.Subheader>
                        </Header>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Frecuencia</Header>
                                        <Header.Subheader>
                                            <strong>Kilometraje: </strong> {currencyFormatter.format(i.frecuencia.kilometraje, { precision:0, thousand:'.'})} <br></br>
                                            <strong>Meses: </strong> {i.frecuencia.meses} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Vigencia</Header>
                                        <Header.Subheader>
                                            <strong>Kilometraje: </strong> {currencyFormatter.format(i.vigencia.kilometraje, { precision:0, thousand:'.'})} <br></br>
                                            <strong>Meses: </strong> {i.vigencia.meses} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>

                        {VISTA_ACUERDO_SERVICIOS_PREPAGADOS !== vista ? null
                        // <Grid>    
                        //     <Grid.Row columns={2}>
                        //         <Grid.Column>
                        //             <Segment basic>
                        //                 <Header as='h5'>Precio Repuestos</Header>
                        //                 <Header.Subheader>
                        //                     <strong>Publico: </strong> {currencyFormatter.format(i.preciosRepuestos.publico, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                        //                     <strong>Concesionario: </strong> {currencyFormatter.format(i.preciosRepuestos.concesionario, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                        //                     <strong>Descuentos: </strong> {currencyFormatter.format(i.preciosRepuestos.descuento, { precision: 0})} % <br></br>
                        //                     <strong>Publico despues de descuento: </strong> {currencyFormatter.format(i.preciosRepuestos.publicoDespuesDescuento, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                        //                     <strong>Concesionario despues de descuento: </strong> {currencyFormatter.format(i.preciosRepuestos.concesionarioDespuesDescuento, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                        //                 </Header.Subheader>
                        //             </Segment>
                        //         </Grid.Column>
                        //         <Grid.Column>
                        //             <Segment basic>
                        //                 <Header as='h5'>Mano de Obra</Header>
                        //                 <Header.Subheader>
                        //                     <strong>Horas: </strong> {i.preciosManoObra.horas} <br></br>
                        //                     <strong>Valor horas: </strong> {i.preciosManoObra.valorHoras} <br></br>
                        //                     <strong>Valor horas despues de factor: </strong> {i.preciosManoObra.valorHorasDespuesFactor} <br></br>
                        //                 </Header.Subheader>
                        //             </Segment>
                        //         </Grid.Column>
                        //     </Grid.Row>
                        // </Grid> 
                        : null }

                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>Detalle operaciones</Header>
                                    </Segment>
                                    {renderDetalleOperaciones(i.detalleOperaciones)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </Segment>
            </Grid.Column>
            } )
        } else {
            return <NoItems />
        }
    }

    const renderDetalleOperaciones = ( detalleOperaciones ) => {
        if ( detalleOperaciones && detalleOperaciones.length > 0 ) {
            return detalleOperaciones.map( (i) => {
                return <Segment key={i._id} size='mini' basic='basic'>
                    <Header as='h5'>
                        <Icon size='mini' name='check' />
                        <Header.Content>{i.nombre}</Header.Content>
                        {VISTA_ACUERDO_SERVICIOS_PREPAGADOS !== vista ? <Header.Subheader>
                            <Label size='tiny' >Codigo: {i.codigo}</Label>
                            <Label size='tiny' >Horas: {i.horas}</Label>
                        </Header.Subheader> : null }
                    </Header>
                    {VISTA_ACUERDO_SERVICIOS_PREPAGADOS !== vista ? <Table celled size='small' striped structured selectable textAlign='center'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan={2}>Referencia</Table.HeaderCell>
                                <Table.HeaderCell rowSpan={2}>PNC</Table.HeaderCell>
                                <Table.HeaderCell rowSpan={2}>Cantidad</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>Margen</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} >Precio venta</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Publico</Table.HeaderCell>
                                <Table.HeaderCell>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Publico</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {i.repuestos.map( (j, index) => {
                                return <Table.Row key={index}>
                                    <Table.Cell>{j.referencia}</Table.Cell>
                                    <Table.Cell>{j.pnc}</Table.Cell>
                                    <Table.Cell>{j.cantidad}</Table.Cell>
                                    <Table.Cell>{j.margen.precioVentaConcesionario}</Table.Cell>
                                    <Table.Cell>{j.margen.precioVentaPublico}</Table.Cell>
                                    <Table.Cell>{j.precioVenta.concesionario}</Table.Cell>
                                    <Table.Cell>{j.precioVenta.publico}</Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table> : null }
                </Segment>
            } )
        }
    }

    return ( 
        renderServicios()
     );

}

 
export default DatosPaquete;