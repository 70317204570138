import React, {useState, useEffect, Fragment} from 'react';
import {
    consultaConcesionario,
    crearConcesionario,
    actualizarConcesionario, 
    eliminarConcesionario 
} from '../../servicios/concesion/concesiones.srv'
import ModalEliminar from '../../components/modales/modalEliminar';
import ItemConcesionario from '../../components/itemsListados/item-concesionario';
import FiltroConcesiones from '../../components/filtros/filtro.concesiones';
import FormularioConcesiones from '../../components/formularios/formulario.concesiones';
import ModalMensaje from '../../components/modales/modalMensaje';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento'
import {Grid, Container, Header, Button, Table, Modal, Loader, Pagination } from 'semantic-ui-react';
import {estadoInicialFiltrado} from '../../estadosIniciales/concesion/estadoInicial.concesion';


const ConcesionesIC = () => {
    const modalEliminarContenido = {
        titulo: 'Eliminar Variable',
        mensaje: '¿Esta seguro que quiere eliminar esta variable?'
    }
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ cargando, setCargando ] = useState(false)
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ concesionEliminar, setConcesionarioEliminar ] = useState('')
    const [ evento, setEvento ] = useState('')
    const [ concesiones, setConcesiones ] = useState([])
    const [ concesion, setConcesion ] = useState({})
    // OBJETO INICIAL DEL FILTRO
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltrado))
    // PETICION DE LA LISTA

    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })
    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }
    const consultar = async(payload) => {
        const respuesta = await consultaConcesionario(payload)
        if(respuesta.estatus){
            setConcesiones(respuesta.data.concesiones)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.total / 10)
            })
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setCargandoLoader(false)
        }
    }

    useEffect(() => {

        // const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        //     activo: false,
        //     mensaje: ''
        // })

        // const [ paginacion, setPaginacion ] = useState({
        //     paginasTotales: 1,
        //     paginaSeleccionada: 1
        // })

        const consultar = async(payload) => {
            const respuesta = await consultaConcesionario(payload)
            if(respuesta.estatus){
                setConcesiones(respuesta.data.concesiones)
                setPaginacion({
                    ...paginacion,
                    paginasTotales: Math.ceil(respuesta.data.total / 10)
                })
                setCargandoLoader(false)
            } else {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })
                setCargandoLoader(false)
            }
        }

        if(filtro){
            consultar(filtro)    
        }
    }, [filtro])


    // CRUD
    // Creacion
    const CrearConcesiones = async(value) => {
        const respuesta = await crearConcesionario(value)
        if(!respuesta.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    // Actualizar
    const ActualizarConcesiones = async( value) => {
        try {
            const respuesta = await actualizarConcesionario(value)
            if(!respuesta?.data?.estatus){
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            } else {
                setModalActivo(false)
                setEvento('')
                consultar(filtro)
            }
        } catch (error) {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: 'No se pudo actualizar el distribuidor'
            })
        }
        
    }
    //ELIMINAR
    const EliminarConcesiones = async(id) => {
        const respuesta = await eliminarConcesionario({id})
        if(respuesta.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })  
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }
    
    if( cargandoLoader ){
        return <Preloader/>
    }
    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Distribuidores
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}} 
                        content="Nueva concesionario"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        floated='right'
                    />
                    </span>
                </Header>
                <FiltroConcesiones
                    setFiltro={setFiltro}
                /> 
                <FormularioConcesiones
                    evento={evento}
                    concesion={concesion}
                    modalActivo={modalActivo}
                    actualizar={(value)=>ActualizarConcesiones(value)}
                    guardar={(value)=>CrearConcesiones(value)}
                    setModalActivo={setModalActivo}
                    setConcesion={setConcesion}
                    setEvento={setEvento}
                />
                {concesiones !== undefined && concesiones.length > 0 ? 
                <Fragment>
                <Table singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>CL</Table.HeaderCell>
                            <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell>ABREVIATURA</Table.HeaderCell>
                            <Table.HeaderCell>DIRECCION</Table.HeaderCell>
                            <Table.HeaderCell>CIUDAD</Table.HeaderCell>
                            <Table.HeaderCell>GRUPO</Table.HeaderCell>
                            <Table.HeaderCell>ACTIVO</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">ACCIONES</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {concesiones.map(i => (
                            <ItemConcesionario
                                setModalActivo={setModalActivo}
                                setObjetoEliminar={setConcesionarioEliminar}
                                setModalEliminar={setModalEliminar}
                                setEvento={setEvento}
                                setConcesion={setConcesion}
                                concesiones={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>
                </Table>
                    <Pagination 
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        totalPages={paginacion.paginasTotales}
                        activePage={paginacion.paginaSeleccionada}
                        onPageChange={onChangePaginador}
                    />
                    </Fragment> : <NoItems/> }
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={concesionEliminar}
                    contenido={modalEliminarContenido}
                    setObjetoEliminar={setConcesionarioEliminar}
                    setModalEliminar={setModalEliminar}
                    eliminar={(value) => EliminarConcesiones(value)}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
    );
}
 
export default ConcesionesIC;