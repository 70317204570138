import React, { useState, useEffect } from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';

const ModalEliminar = ({
  setCargando,
  setModalEliminar,
  modalEliminarActivo,
  eliminarPaquete,
  contenido
}) => {
  const [estadoModal, setEstadoModal] = useState(false);

  useEffect(() => {
    if (modalEliminarActivo) {
      setEstadoModal(true);
    }
  }, [modalEliminarActivo]);

  const desactivarModal = () => {
    setEstadoModal(false);
    setModalEliminar(false);
  };

  const onClickEliminar = () => {
    eliminarPaquete();
    setCargando(true);
    desactivarModal();
  };

  return (
    <Modal
      size="tiny"
      open={estadoModal}
      style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
    >
      <Modal.Header
        style={{
          backgroundColor: '#FFFFFF',
          border: 'none'
        }}
        as="h5"
      >
        <Header>
          {contenido.titulo}
        </Header>
      </Modal.Header>
      <Modal.Content>
        <p>{contenido.mensaje}</p>
      </Modal.Content>
      <Modal.Actions style={{ backgroundColor: '#FFFFFF', border: 'none', textAlign: 'left' }}>
        <Button
          size="tiny"
          style={{
            padding: 20,
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
            borderColor: '#000000',
            borderStyle: 'solid',
            borderWidth: '1px',
            fontWeight: 'bold',
            color: '#000000'
          }}
          primary
          content="Cancelar"
          onClick={desactivarModal}
        />
        <Button
          size="tiny"
          style={{
            padding: 20,
            borderRadius: 0,
            backgroundColor: '#000000',
            borderColor: '#000000',
            borderStyle: 'solid',
            borderWidth: '1px',
            fontWeight: 'bold',
            color: '#FFFFFF'
          }}
          primary
          content="Eliminar"
          onClick={onClickEliminar}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalEliminar;
