import React, {useState} from 'react'
import { estadoInicialFiltro } from '../../estadosIniciales/repuestos/estadoInicial-repuestos';
import {Grid, Segment, Button, Form} from 'semantic-ui-react'

const FiltroConcesiones = ({setFiltro}) => {
    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))
    
    const onChangeDatosFiltrado = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]: e.target.value
        })
    }
    const buttonActivo = () => {
        setDatosFiltrado({
            ...datosFiltrado,
            activo: !datosFiltrado.activo
        })
    }

    const buscarRepuestos = () => {
        setFiltro(datosFiltrado)
    }
            
    return ( 
        <Grid style={{ marginTop: 5}}>
            <Grid.Column>
                <Segment
                    size="mini"
                >
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Modelo"
                                placeholder="Codigo del modelo"
                                name="modelo"
                                value={datosFiltrado.modelo}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="PNC"
                                placeholder="Codigo de parte"
                                name="pnc"
                                value={datosFiltrado.pnc}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Referencia"
                                placeholder="Referencia"
                                name="referencia"
                                value={datosFiltrado.referencia}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Descripcion"
                                placeholder="Descripcion"
                                name="descripcion"
                                value={datosFiltrado.descripcion}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Field>
                                <label>Estado de repuestos</label>
                                <Form.Checkbox
                                    style={{fontWeight: "bold", marginTop: 10}}
                                    checked={datosFiltrado.activo}
                                    toggle
                                    label={datosFiltrado.activo ? "Activos" : "Inactivos"}
                                    name="activo"
                                    onChange={() => buttonActivo()}
                                />
                            </Form.Field>
                            
                        </Form.Group>
                            <span>
                                <Button
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="MINI"
                                    icon="search"
                                    content="BUSCAR"
                                    onClick={() => buscarRepuestos()}
                                />
                            </span>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
     );
}
 
export default FiltroConcesiones;