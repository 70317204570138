import React, {Fragment, useState, useEffect} from 'react';
import {Grid, Container, Header, Table, Loader, Pagination, Modal, Button, Image, Segment, Divider } from 'semantic-ui-react';
import TerminosyCondiciones from '../../vistas/terminosCondiciones/TerminosCondicionesCliente';
import SignatureCanvas from 'react-signature-canvas'
import {
    consultaSolicitudesId
} from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import { crearActualizarFirma, consultaFirmasAcuerdo} from '../../servicios/firma-cliente/firma-cliente.srv';
import { useParams } from 'react-router-dom';
import { estadoInicialSolicitud} from '../../estadosIniciales/solicitudes/estadoInicial-solicitud'
import { estadoInicialClientes } from '../../estadosIniciales/clientes/estadoInicial-clientes';
import { estadoInicialVehiculo } from '../../estadosIniciales/vehiculos/estadoInicial-vehiculos';
import { estadoInicialFirmaCliente } from '../../estadosIniciales/firma-cliente';
import ModalMensaje from '../../components/modales/modalMensaje';


export const AcuerdoClienteIC = ({ idUrl }) => {

    const [ parametro ] = useState( useParams() )
    const [ id, setId ] = useState('');
    const [ cargando, setCargando ] = useState(false)

    const [ datosPaquete, setDatosPaquete ] = useState([])
    const [ datosCliente, setDatosCliente ] = useState(Object.assign({}, estadoInicialClientes))
    const [ datosVehiculo, setDatosVehiculo ] = useState(Object.assign({}, estadoInicialVehiculo))
    const [ datosSolicitud, setDatosSolicitud ] = useState(Object.assign({}, estadoInicialSolicitud))
    const [ datosFirma, setDatosFirma ] = useState(Object.assign({}, estadoInicialFirmaCliente ));
    const [ datosFirmaConsultados, setDatosFirmaConsultados ] = useState(Object.assign({}, estadoInicialFirmaCliente ));
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    let campoFirma

    useEffect(() => {
        if( parametro ) {
            consultarPorIdUrl(parametro.id);
            consultaFirma(parametro.id);
        }
    }, [parametro])

    const consultaFirma = async ( id ) => {

        try {
            const respuesta = await consultaFirmasAcuerdo({ idSolicitud: id });

            if ( respuesta.estatus ) {
                setDatosFirmaConsultados(respuesta.data.firma)
            } else {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            }
            

            console.log('Consulta Firma Respuesta: ', respuesta )
        } catch (error) {
            console.log('Error', error )
        }
    }



    const consultarPorIdUrl = async(id) => {
        setCargando(true)
        try {
            const respuesta = await consultaSolicitudesId({id})

            console.log( 'Respuesta: ', respuesta );

            if(respuesta.data.estatus){
                setDatosPaquete(respuesta.data.data.datosPaquetes)
                setDatosSolicitud(respuesta.data.data.datosSolicitud) 
                setDatosCliente(respuesta.data.data.datosCliente)
                setDatosVehiculo(respuesta.data.data.datosVehiculo)

                setCargando(false)
            } else {
                setCargando(false)  
            }
        } catch (error) {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: error.data.resultadoOperacion
            })
        }
        
    }

    const firmaTerminada = () => {
        console.log('Firma terminada')
        setDatosFirma({
            solicitud: parametro.id,
            creado: new Date( Date.now() ),
            firmas: {
                cliente: campoFirma.toDataURL(),
                concesionario: ''
            }
        })
    }

    const capturarFirma = async ( ) => {
        
        try {
            const respuesta = await crearActualizarFirma(datosFirma);
            if ( respuesta.estatus) {
                consultaFirma(parametro.id)
            } else {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            }
        } catch (error) {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: error.data.resultadoOperacion
            })
        }

        

        
    }

    const limpiarFirma = () => {
        campoFirma.clear();
    }

    return <Fragment>
        <Grid style={{ marginTop: 80}}>
            <TerminosyCondiciones 
                cliente={datosCliente}
                vehiculo={datosVehiculo}
                solicitud={datosSolicitud}
            />
        </Grid>      
        
        <Grid style={{ marginBottom: 20 }} stackable={true}>
            <Container>
                <Segment content='Firma del cliente'>
                    <Grid columns={2} >
                        <Divider vertical></Divider>

                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column computer={8} mobile={16} textAlign='center' style={{ marginBottom: 10}}>

                                <Header content="Firma del Cliente" />
                                {datosFirmaConsultados?.firmas?.cliente ? 
                                <Image centered={true} src={datosFirmaConsultados.firmas.cliente} />
                                :<SignatureCanvas
                                    ref={(ref) => { campoFirma = ref }}
                                    penColor='black'
                                    backgroundColor='rgba(34,36,38,.15)'
                                    onEnd={()=> firmaTerminada()}
                                    canvasProps={{ 
                                        minWidth: 1,
                                        width: 300, 
                                        height: 200,
                                        className: 'sigCanvas'}} />}
                                <p>
                                    {datosCliente.nombre.nombres} {datosCliente.nombre.apellidos} <br></br>
                                    {datosCliente.identificacion.tipo} {datosCliente.identificacion.numero}
                                </p>

                                {datosFirmaConsultados?.firmas?.cliente ? null : <Button.Group>
                                    <Button onClick={ ()=> limpiarFirma() }>Limpiar</Button>
                                    <Button onClick={ ()=> capturarFirma() }>Guardar</Button>
                                </Button.Group>}

                            </Grid.Column>
                            {/* <Grid.Column computer={8} mobile={16} textAlign='center'>
                                <Header content="Firma del Concesionario" />
                                <SignatureCanvas
                                    ref={(ref) => { campoFirma = ref }}
                                    penColor='black'
                                    backgroundColor='rgba(34,36,38,.15)'
                                    onEnd={( event )=> capturarFirma(event)}
                                    canvasProps={{ 
                                        minWidth: 1,
                                        width: 300,
                                        height: 200,
                                        className: 'sigCanvas'}} />

                                <p>
                                    Concesionario <br></br>
                                    {datosSolicitud.concesionario.nombre}
                                </p>
                            </Grid.Column> */}
                        </Grid.Row>
                    </Grid>
                    
                </Segment>

                <Grid.Row style={{ marginTop:20 }}>
                    <p style={{
                        fontFamily: 'kiaMedium',
                        fontSize: '0.625rem',
                        fontWeight: 'normal',
                        letterSpacing: '0.01em',
                        lineHeight: '14px',
                        textAlign: 'justify'
                    }}>
                        El cliente reconoce que los servicios requeridos en este pedido únicamente serán realizados en la 
                        red de concesionarios autorizados de Kia Motors en Colombia. El cliente y el concesionario 
                        enunciados reconocen y aceptan el contenido de este pedido para que la importadora, 
                        Metrokia S.A., realice la activación de Mantenimiento Prepagado para el vehículo descrito 
                        anteriormente:

                        Poíítica de Uso de datos personales Con la firma del presente documento, dando cumplimiento a lo 
                        establecido en la Ley 1581 de 2012 y el Decreto 1377 de 2013 el CLIENTE AUTORIZA al CONCESIONARIO y 
                        al DISTRIBUIDOR DE LA MARCA EN EL PAÍS, la recolección y manejo de sus datos personales, los cuales 
                        tendrán como fin el almacenamiento, uso, circulación, transmisión, directamente o a través de sus 
                        terceros aliados, con finalidad comercial y para la correcta prestación de los servicios. 
                        No obstante, lo anterior, EL CLIENTE podrá solicitar al CONCESIONARIO y al DISTRIBUIDOR DE LA 
                        MARCA EN EL PAÍS la actualización, rectificación o supresión de sus datos personales del 
                        registro en bases de datos del CONCESIONARIO y al DISTRIBUIDOR DE LA MARCA EN EL PAÍS enviando un 
                        correo electrónico a callcentercrm@kia.com.co PARÁGRAFO: Los datos de carácter personal que sean 
                        proporcionados al CONCESIONARIO y al DISTRIBUIDOR DE LA MARCA EN EL PAÍS, serán recogidos y 
                        conservados en una base de datos y podrán ser objeto de tratamiento por parte del CONCESIONARIO y el 
                        DISTRIBUIDOR DE LA MARCA EN EL PAÍS, terceros aliados o designados por EL CONCESIONARIO. 
                        Dicha base de datos se conserva y administra bajo responsabilidad del CONCESIONARIO y el 
                        DISTRIBUIDOR DE LA MARCA EN EL PAÍS, terceros aliados o designados por EL CONCESIONARIO y el 
                        DISTRIBUIDOR DE LA MARCA EN EL PAÍS. La base de datos cuenta con las medidas de seguridad necesarias 
                        para la conservación adecuada de los datos. Para fines estadísticos y de medición de satisfacción del 
                        cliente se autoriza expresamente la transferencia y/o transmisión Nacional e internacional de los datos 
                        personales bajo niveles adecuados de protección, seguridad y confidencialidad. Para más información 
                        consulte en POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS
                    </p>
                </Grid.Row>
            </Container>
        </Grid>
        
        <ModalMensaje
            activo={modalMensajeEstatus.activo}
            mensaje={modalMensajeEstatus.mensaje}
            // evento={evento}
            // setEvento={setEvento}
            setModalMensajeEstatus={setModalMensajeEstatus}
        />
        
    </Fragment>
}
