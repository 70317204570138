import React from 'react';
import { Dimmer, Image, Loader } from 'semantic-ui-react';

const Preloader = () => {
    return <Dimmer page={true} active={true}  inverted={true}>
        <Image style={{maxWidth:300}} src={require('../../assets/kia_blanco.png')}></Image>
        <Loader style={{ top:'60%'}} inverted></Loader>   
    </Dimmer>
}

export default Preloader
