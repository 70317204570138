import React, {useState, useEffect, Fragment} from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const ModalRedencion = ({ activo, mensaje, servicios, setModalMensajeEstatus, vehiculo }) => {

    const [estado, setEstado] = useState( false );
    useEffect(() => {
        if(activo){
            setEstado(true)
        }
    }, [activo])

    const desactivarModal = () => {
        setEstado( false )
        setModalMensajeEstatus({
            estado: false,
            mensaje: '',
            servicios: false
        })
    }

    const botonCallBack = () => {
        return(
            <Fragment>
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny"
                    content="Cancelar"
                    onClick={() => desactivarModal()}
                />
                { servicios ? <Button 
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    size="tiny"
                    to={`redimir-servicios/${vehiculo.vin}`}
                >
                    <Link 
                        to={`redimir-servicios/${vehiculo.vin}`} 
                        style={{textDecoration:"none", color:'white', fontWeight: "bold"}}
                    >Ir a Redimir</Link>
                </Button> : 
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny"
                    content="Realizar otra búsqueda"
                    onClick={() => desactivarModal()}
                />

                
                }
            </Fragment>
        )
    }

    return(
        <Modal
            size="tiny"
            open={estado}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                    Información 
                </Header>
            </Modal.Header>
            <Modal.Content>
                <p>{mensaje}</p>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                {botonCallBack()}
            </Modal.Actions>
        </Modal>
    )
}

export default ModalRedencion;