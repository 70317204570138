import tokenAuth from '../../config/tokenAuth';
import {clienteAxios} from '../../config/axios';

export const crearConcesionario = async (payload) => {
    usuarioAutenticado();
    try {
        const respuesta  = await clienteAxios.post('/concesion/crear', payload)
        return(respuesta.data)
    } catch (error) {
        return({
            estatus: false,
            resultadoOperacion: "Ha ocurrido un problema"
        })
    }
}   

export const consultaConcesionario = async (payload) => {
    usuarioAutenticado();
    try {
        const respuesta = await clienteAxios.post('/concesion/consulta', payload)
        return(respuesta.data)
    } catch (error) {
        return({
            estatus: false,
            resultadoOperacion: "Ha ocurrido un problema"
        })
    }

}

export const consultaConcesionarioPorGrupo = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/concesion/consultaCLPorGrupo', payload)
        return (respuesta.data);
    } catch (error) {
        return({
            estatus: false,
            resultadOperacion: "Ha ocurrido un problema"
        })
    }

}

export const eliminarConcesionario = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/concesion/eliminar', payload)
        return respuesta.data
    } catch (error) {
        return({
           estatus: false,
           resultadoOperacion: "Ha ocurrido un problema"
        })
    }

}
export const actualizarConcesionario = async (payload) => {
    usuarioAutenticado();
    try{
        const respuesta = await clienteAxios.post('/concesion/actualizar', payload)
        return(respuesta);
    } catch(eror) {
        return({
            estatus: false,
            resultadoOperacion: "Ha ocurrido un problema"
        })
    }
}

const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}