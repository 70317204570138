import React, {useState, Fragment, useEffect} from 'react'
import { Modal, Form, Table, Button, Header, Pagination, Placeholder } from 'semantic-ui-react';
import {estadoInicialFiltro} from '../../estadosIniciales/repuestos/estadoInicial-seleccionRepuestos';
import { tipoAireAcondicionadoOpciones } from '../../estadosIniciales/listas/aireAcondicionado-lista';
import { tipoCombustibleOpciones } from '../../estadosIniciales/listas/combustible-lista';
import { tipoTransmisionOpciones } from '../../estadosIniciales/listas/transmision-lista';
import { consultarRepuestosModal } from '../../servicios/repuestos/repuestos.srv';
import { EVENTO_VER_DETALLES } from '../../enum/eventos/tipo-evento';

const ConsultaModalRepuestos = ({
    evento,
    setDatosOperacion,
    operacion,
    datosOperacion,
    setModalRepuestos,
    modalActivoRepuestos
}) => {

    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ repuestos, setRepuestos ] = useState([])
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })
    
    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setDatosFiltrado({
            ...datosFiltrado,
            salto: (activePage - 1) * 10 
        })
    }

    const desactivarModal = () => {
        setModalRepuestos(false)
    }

    const consultar = async() => {
        const respuesta = await consultarRepuestosModal(datosFiltrado)
        setRepuestos(respuesta.data.repuestos)
        setPaginacion({
            ...paginacion,
            paginasTotales: Math.ceil(respuesta.data.total / 10)
        })
    }

    useEffect(() => {
        consultar(datosFiltrado)
    }, [datosFiltrado])

            
        
    const elegirRepuesto = (i) => {
        let conjunto = datosOperacion.repuestos
        const validarExiste = conjunto.find( j=> {
            return i.referencia === j.referencia
        })
        if ( validarExiste ) {
            i.cantidad += 1 

        } else {
            i.cantidad = 1
            conjunto.push( i )
        }

        setDatosOperacion({
            ...datosOperacion,
            repuestos: conjunto
        })
        setModalRepuestos( false )
        
    }


    const onChangeDatosFiltrado = (e, {name, value}) => {
        if(
            name === 'aireAcondicionado' ||
            name === 'tipoCombustible' ||
            name === 'transmision'
            
        ){
            setDatosFiltrado({
                ...datosFiltrado,
                caracteristicas: {
                    ...datosFiltrado.caracteristicas,
                    [name]: value
                }
            }) 
        }else {
            setDatosFiltrado({
                ...datosFiltrado,
                [name]: value
            })
        }
    }

    return(
        <Modal
            open={modalActivoRepuestos}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}

        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                { evento === EVENTO_VER_DETALLES ? 'Repuestos' : 'Buscar Repuestos' }
                </Header>
            </Modal.Header>
            <Modal.Content>
                {evento !== EVENTO_VER_DETALLES ? <Form>
                    <h3>Filtrar por</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Modelo"
                            name="modelo"
                            value={datosFiltrado.modelo}
                            onChange={onChangeDatosFiltrado}
                        />
                        <Form.Input
                            label="PNC"
                            name="pnc"
                            value={datosFiltrado.pnc}
                            onChange={onChangeDatosFiltrado}
                        />
                        <Form.Input
                            label="Referencia"
                            name="referencia"
                            value={datosFiltrado.referencia}
                            onChange={onChangeDatosFiltrado}
                        />
                        <Form.Input
                            label="Descripcion"
                            name="descripcion"
                            value={datosFiltrado.descripcion}
                            onChange={onChangeDatosFiltrado}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Select
                            clearable
                            label="Aire Acondicionado"
                            name="aireAcondicionado"
                            options={tipoAireAcondicionadoOpciones}
                            value={datosFiltrado.aireAcondicionado}
                            onChange={onChangeDatosFiltrado}
                        />
                        <Form.Select
                            clearable
                            label="Tipo Combustible"
                            name="tipoCombustible"
                            options={tipoCombustibleOpciones}
                            value={datosFiltrado.tipoCombustible}
                            onChange={onChangeDatosFiltrado}
                        />
                        <Form.Select
                            clearable
                            label="Transmision"
                            name="transmision"
                            options={tipoTransmisionOpciones}
                            value={datosFiltrado.transmision}
                            onChange={onChangeDatosFiltrado}
                        />
                    </Form.Group>
                </Form> : null}
                
                {repuestos !== undefined && repuestos.length> 0?
                <Fragment>
                <Table  textAlign="center" size="small">
                   <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> {evento === EVENTO_VER_DETALLES ? 'REFERENCIA' : 'DESCRIPCION'}     </Table.HeaderCell>
                        <Table.HeaderCell>{evento === EVENTO_VER_DETALLES ? 'CANTIDAD' : 'REFERENCIA'}</Table.HeaderCell>
                        <Table.HeaderCell>{evento === EVENTO_VER_DETALLES ? 'PNC' : 'MODELO'}</Table.HeaderCell>
                        <Table.HeaderCell>{evento === EVENTO_VER_DETALLES ? 'PRECIO CONCESIONARIO' : 'PNC'}</Table.HeaderCell>
                        <Table.HeaderCell>{evento === EVENTO_VER_DETALLES ? 'PRECIO PUBLICO' : 'CILINDRADA'}</Table.HeaderCell>
                        {evento !== EVENTO_VER_DETALLES ?<Table.HeaderCell>SELECCIONAR</Table.HeaderCell>: null}
                    </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                        
                            evento === EVENTO_VER_DETALLES ? 
                    
                            operacion.repuestos.map(i =>(
                                <Table.Row key={i._id}>
                                    <Table.Cell> {i.referencia} </Table.Cell>
                                    <Table.Cell> {i.cantidad} </Table.Cell>
                                    <Table.Cell> {i.pnc} </Table.Cell>
                                    <Table.Cell> {i.precioVenta.concesionario} </Table.Cell>
                                    <Table.Cell> {i.precioVenta.publico} </Table.Cell>
                                </Table.Row>
                                )) 
                            :
                            repuestos.map(i =>(
                            <Table.Row key={i._id}>
                                <Table.Cell> {i.descripcion} </Table.Cell>
                                <Table.Cell> {i.referencia} </Table.Cell>
                                <Table.Cell> {i.modelo} </Table.Cell>
                                <Table.Cell> {i.pnc} </Table.Cell>
                                <Table.Cell> {i.caracteristicas.cilindrada} </Table.Cell>
                                <Table.Cell verticalAlign="top">
                                    <Button
                                        icon="check"
                                        onClick={() => elegirRepuesto(i)}
                                        size="tiny"
                                        toggle
                                    />
                                </Table.Cell>
                            </Table.Row>
                            ))
                        
                        }
                    </Table.Body>
                </Table>
                {evento !== EVENTO_VER_DETALLES?<Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />: null}
                </Fragment>
                : 
                null
                }

            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                <Button
                    size='tiny'
                    onClick={desactivarModal}
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    content="Cancelar"
                />
            </Modal.Actions>
        </Modal>
    )
}

export default ConsultaModalRepuestos;