import { TIPO_CONTENIDO_VERSION } from '../../enum/tipo-contenido-version'

export const listaContenidoVersion = [
    {
        value: TIPO_CONTENIDO_VERSION.AIRE_ACONDICIONADO,
        text: TIPO_CONTENIDO_VERSION.AIRE_ACONDICIONADO,
        key:1
    },
    {
        value: TIPO_CONTENIDO_VERSION.CILINDRAJE,
        text: TIPO_CONTENIDO_VERSION.CILINDRAJE,
        key:2
    },
    {
        value: TIPO_CONTENIDO_VERSION.CODIGO_EQUIPAMIENTO,
        text: TIPO_CONTENIDO_VERSION.CODIGO_EQUIPAMIENTO,
        key:3
    },
    {
        value: TIPO_CONTENIDO_VERSION.ESPACIO_LIBRE,
        text: TIPO_CONTENIDO_VERSION.ESPACIO_LIBRE,
        key:4
    },
    {
        value: TIPO_CONTENIDO_VERSION.GENERACION_VEHICULO,
        text: TIPO_CONTENIDO_VERSION.GENERACION_VEHICULO,
        key:5
    },
    {
        value: TIPO_CONTENIDO_VERSION.MODELO_VEHICULO,
        text: TIPO_CONTENIDO_VERSION.MODELO_VEHICULO,
        key:6
    },
    {
        value: TIPO_CONTENIDO_VERSION.MOTOR,
        text: TIPO_CONTENIDO_VERSION.MOTOR,
        key:7
    },
    {
        value: TIPO_CONTENIDO_VERSION.NUMERO_PUERTAS,
        text: TIPO_CONTENIDO_VERSION.NUMERO_PUERTAS,
        key:8
    },
    {
        value: TIPO_CONTENIDO_VERSION.PREFIJO_FABRICA,
        text: TIPO_CONTENIDO_VERSION.PREFIJO_FABRICA,
        key:9
    },
    {
        value: TIPO_CONTENIDO_VERSION.TRACCION_VEHICULO,
        text: TIPO_CONTENIDO_VERSION.TRACCION_VEHICULO,
        key:10
    },
    {
        value: TIPO_CONTENIDO_VERSION.TRANSMISION,
        text: TIPO_CONTENIDO_VERSION.TRANSMISION,
        key:11
    },
    {
        value: TIPO_CONTENIDO_VERSION.USO_VEHICULO,
        text: TIPO_CONTENIDO_VERSION.USO_VEHICULO,
        key:12
    },
    {
        value: TIPO_CONTENIDO_VERSION.VERSION_CODIGO_EQUIPAMIENTO,
        text: TIPO_CONTENIDO_VERSION.VERSION_CODIGO_EQUIPAMIENTO,
        key:13
    }

]