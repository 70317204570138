import {clienteAxios} from '../../config/axios'
import tokenAuth from '../../config/tokenAuth';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const crearVehiculos = async(payload) => {

    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/vehiculos/crear', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaVehiculos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/vehiculos/consulta', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaVehiculosVin = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/vehiculos/validarVehiculoVin', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:({
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            })
        })
    }
}

export const consultaVehiculosId = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/vehiculos/consultaId', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarVehiculos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/vehiculos/actualizar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarVehiculos = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/vehiculos/eliminar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}