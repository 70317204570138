import React, {useState, useEffect, Fragment} from 'react';
import currencyFormatter from 'currency-formatter'
import { Grid, Form, Button, Table, Checkbox, Icon, Container, Header, Popup, Segment, Input, Label } from 'semantic-ui-react';
import { operacionesListado } from '../../estadosIniciales/listas/tipoOperacion-lista';
import { estadoInicialServicio } from '../../estadosIniciales/servicios/estadoInicial-servicio'
import {useParams, Link} from 'react-router-dom'
import {EVENTO_EDITAR_DESDE_DETALLES} from '../../enum/eventos/tipo-evento';
import {consultaServicioId, eliminarOperacionDeServicio} from '../../servicios/servicios/servicios.srv';
import {actualizarServicios} from '../../servicios/servicios/servicios.srv';
import ConsultaModalOperaciones from '../../components/modales/modalSeleccionOperaciones';
import ItemOperaciones from '../../components/itemsListados/item-operaciones';
import ModalMensaje from '../../components/modales/modalMensaje';



const DetallesServicio = () => {

    let id = useParams()

    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ datosServicio, setDatosServicio ] = useState(Object.assign({}, estadoInicialServicio))
    const [ modalOperaciones, setModalOperaciones ] = useState(false)
    const [ evento ] = useState(EVENTO_EDITAR_DESDE_DETALLES)
    const [addVersion, setAddVersion] = useState('')
    
    const consultar = async(id) => {
        const respuesta = await consultaServicioId(id)
        setDatosServicio(respuesta.data.data)
    }
    
    useEffect(() => {
        consultar(id)
    }, [id])
    
    const ActualizarServicio = async(payload) => {
        delete payload.horasManoObra
        delete payload.preciosManoObra
        delete payload.preciosRepuestos

        const respuesta = await actualizarServicios(payload)
        if(respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(id)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
    }

    const eliminarOperacion = async (idServicio, idOperacion ) => {
        try {
            const respuesta = await eliminarOperacionDeServicio({
                idServicio: idServicio,
                idOperacion: idOperacion
            })

            if (respuesta.data.estatus) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                consultar(id)
            } else {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
            }

        } catch (error) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'No se pudo eliminar la operación.'
            })
        }
    }

    const onChangeDatos = (e, {name, value}) => {
        setDatosServicio({
            ...datosServicio,
            [name]: value
        })
    }
    const onChangeAddVersion = (e, {name, value}) => {
        setAddVersion(value)
    }

    const agregarVersion = ( ) => {
        if ( !addVersion ) return;
        const existeVersion = datosServicio.modelo.version.find( (i) => { 
            if (i === addVersion) { 
                return i 
            }

            //  npm run hacer-start-up --puede-darme-dimero?
        })

        if ( existeVersion ) {
            return
        }
        setDatosServicio({
            ...datosServicio,
            modelo: {
                ...datosServicio.modelo,
                version: [...datosServicio.modelo.version, addVersion ]
            }
        })
        const a = ''
        setAddVersion(a)
        return;
    }
    const eliminarVersion = ( version ) => {
        const index = datosServicio.modelo.version.indexOf( version )
        let datosServicioTemporales = Object.assign({}, datosServicio );
        datosServicioTemporales.modelo.version.splice(index, 1 )
        setDatosServicio( datosServicioTemporales )
    }
    const onChageDatosModelo = e => {
        setDatosServicio({
            ...datosServicio,
            modelo: {
                ...datosServicio.modelo,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChageFrecuencia = e => {
        setDatosServicio({
            ...datosServicio,
            frecuencia:{
                ...datosServicio.frecuencia,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeVigencia = e => {
        setDatosServicio({
            ...datosServicio,
            vigencia: {
                ...datosServicio.vigencia,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeAutorizado = () => {
        setDatosServicio({
            ...datosServicio,
            vigencia: {
                ...datosServicio.vigencia,
                autorizadoPorImportadora: !datosServicio.vigencia.autorizadoPorImportadora
            }
        })
    }
    const buttonActivo = () => {
        setDatosServicio({
            ...datosServicio,
            activo: !datosServicio.activo
        })
    }

    const onChangeTarifa = () => {
        setDatosServicio({
            ...datosServicio,
            tarifaManoObraUnificada: !datosServicio.tarifaManoObraUnificada
        })
    }

    console.log(datosServicio);

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
            <Header 
                size="huge" 
                as="h1" 
                style={{ 
                    fontSize:50,
                    spacing:'-0.04em',
                    fontWeight:'400', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:30 }}>
                Edición de {datosServicio.nombre}
                <span>
                <Button 
                    size="mini"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    floated='right'
                > 
                    <Link 
                        style={{
                            textDecoration: 'none',
                            borderColor:'#666666',
                            fontFamily: 'kiaBold',
                            color:'#000000'
                        }}
                        size="tiny"
                        to={'/servicios'}
                        >  
                            <Icon name="chevron left" />
                            Volver 
                    </Link>
            </Button>
                </span>
            </Header>
            <Segment basic>
            <Form>
                    <h3>Datos del Servicio</h3>
                    <Form.Group>
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosServicio.nombre}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Descripcion"
                            name="descripcion"
                            value={datosServicio.descripcion}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Cantidad"
                            type="Number"
                            name="cantidad"
                            value={datosServicio.cantidad}
                            onChange={onChangeDatos}
                        />
                        <Form.Select
                            label="Tipo"
                            options={operacionesListado}
                            name="tipo"
                            value={datosServicio.tipo}
                            onChange={onChangeDatos}
                        />
                        <Form.Field>
                            <label> Tarifa Mano de Obra</label>
                        <Form.Checkbox
                            toggle
                            style={{marginTop: 10}}
                            label={ 
                                <Popup 
                                    content={ datosServicio.tarifaManoObraUnificada ? 'Tarifa mano de obra Prepagado' : 'Tarifa mano de obra Garantia'} 
                                    trigger={ 
                                        <label>
                                            <Icon name="info circle"/>
                                        </label>
                                     } />
                            }
                            checked={datosServicio.tarifaManoObraUnificada}
                            onChange={onChangeTarifa}

                        />
                        </Form.Field>
                    </Form.Group>
                    <Grid columns={1}>
                        <Grid.Column>
                            <h3>Modelo al que Aplica</h3>
                            <Form.Group>
                                <Form.Input
                                    label="Codigo del Modelo"
                                    name="codigo"
                                    value={datosServicio.modelo.codigo}
                                    onChange={onChageDatosModelo}
                                />
                                <Form.Input
                                    label="Descripcion"
                                    name="descripcion"
                                    value={datosServicio.modelo.descripcion}
                                    onChange={onChageDatosModelo}
                                />
                                <Form.Field>
                                    <label>Agregar version</label>
                                    <Input
                                        label={<Button icon="plus" onClick={ ()=> agregarVersion() } />}
                                        labelPosition='right'
                                        placeholder='Version'
                                        value={addVersion}
                                        onChange={onChangeAddVersion}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Grid.Column>
                        
                        {datosServicio.modelo.version.length>0?<Grid.Column>
                            <h3>Versiones</h3>
                            {datosServicio.modelo.version? datosServicio.modelo.version.map( (i, index) => {
                             return<Fragment key={i}>
                                <Label style={{ marginTop:5 }} key={index} onClick={ ()=> eliminarVersion( i ) }>
                                    {i}
                                    <Icon name="delete"/>
                                </Label>
                                </Fragment>
                            } ): null }
                        </Grid.Column>: null}
                        <Grid.Column>
                        <h3>Vigencia </h3>
                        <Form.Group>
                            <Form.Input
                                label="Kilometraje"
                                type="Number"
                                name="kilometraje"
                                value={datosServicio.vigencia.kilometraje}
                                onChange={onChangeVigencia}
                            />
                            <Form.Input
                                label="Meses"
                                type="Number"
                                name="meses"
                                value={datosServicio.vigencia.meses}
                                onChange={onChangeVigencia}
                            />
                            <Form.Field>
                            <label>Autorización</label>
                                <Checkbox
                                    toggle
                                    label={
                                        <Popup
                                            content="Si esta activada, las vigencias no serán tenidas en cuenta"
                                            trigger={
                                                <label>
                                                    <Icon name="info circle"/>
                                                </label>
                                            }
                                        />
                                    }
                                    style={{marginTop: 10}}
                                    checked={datosServicio.vigencia.autorizadoPorImportadora}
                                    onChange={onChangeAutorizado}
                                />
                            </Form.Field>
                        </Form.Group>
                        </Grid.Column>
                    </Grid>
                    <Grid
                        columns={1}
                    >
                        <Grid.Column>
                        <h3>Frecuencia</h3>
                            <Form.Group>
                                <Form.Input
                                    label="Kilometraje"
                                    type="Number"
                                    name="kilometraje"
                                    value={datosServicio.frecuencia.kilometraje}
                                    onChange={onChageFrecuencia}
                                />
                                <Form.Input
                                    label="Meses"
                                    type="Number"
                                    name="meses"
                                    value={datosServicio.frecuencia.meses}
                                    onChange={onChageFrecuencia}
                                />
                                <Form.Field>
                                <label>Estado del Servicio</label>
                                <Form.Checkbox
                                    toggle
                                    checked={datosServicio.activo}
                                    label={
                                        <Popup
                                            content="Si el servicio no está activo, no estará disponible en la oferta comercial"
                                            trigger={
                                                <label>
                                                    <Icon name="info circle"/>
                                                </label>
                                            }
                                        />
                                    }
                                    style={{marginTop: 10}}
                                    onChange={buttonActivo}
                                />
                            </Form.Field>
                            </Form.Group>
                        </Grid.Column>
                        </Grid>
                        <Grid columns={2} stretched>
                        <Grid.Column>
                            {datosServicio.preciosRepuestos && datosServicio.preciosManoObra ?
                            <Segment>
                                <h4>Precio Repuestos</h4>
                                <p>Concesionario: {currencyFormatter.format(datosServicio.preciosRepuestos.concesionario, {code: 'COP', precision: 0})} </p>
                                <p>Concesionario Despues de Descuento:  {currencyFormatter.format(datosServicio.preciosRepuestos.concesionarioDespuesDescuento, {code: 'COP', precision: 0})} </p>
                                <p>Descuento: {datosServicio.preciosRepuestos.descuento} % </p>
                                <p>Precio Publico: {currencyFormatter.format(datosServicio.preciosRepuestos.publico, {code: 'COP', precision: 0})}  </p>
                                <p>Publico Despues de Descuento: {currencyFormatter.format(datosServicio.preciosRepuestos.publicoDespuesDescuento, {code: 'COP', precision: 0})}  </p>
                            </Segment>: null}
                        </Grid.Column>
                        <Grid.Column>
                            {datosServicio.preciosRepuestos && datosServicio.preciosManoObra ? 
                            <Segment>
                                <h4>Precios Mano de Obra</h4>
                                <p>Horas: {datosServicio.preciosManoObra.horas} </p>
                                <p>Valor de las Horas: {currencyFormatter.format(datosServicio.preciosManoObra.valorHoras, {code: 'COP', precision: 0})} </p>
                                <p>Valor de las Horas Despues del Factor: {currencyFormatter.format(datosServicio.preciosManoObra.valorHorasDespuesFactor, {code: 'COP', precision: 0})}  </p>
                            </Segment>: null}
                        </Grid.Column>
                        </Grid>
                </Form>
                <Grid.Column style={{marginTop: 15}}>
                    <Button
                        content="Agregar operacion"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        size="tiny"
                        onClick={() => setModalOperaciones(true)}
                    />
                    <Button 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny" 
                        content="Actualizar"
                        onClick={() => ActualizarServicio(datosServicio)}
                    />
                    </Grid.Column>
                </Segment>
                
                {datosServicio.detalleOperaciones !== undefined && datosServicio.detalleOperaciones.length>0?
                <Container fluid style={{ overflowX: "scroll", marginTop:20, marginBottom:20 }}>
                    <Table fixed={true} size="small"  celled textAlign="center">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">CODIGO</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">HORAS</Table.HeaderCell>
                                <Table.HeaderCell colSpan="4">APLICA PARA</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>AIRE</Table.HeaderCell>
                                <Table.HeaderCell>COMBUSTIBLE</Table.HeaderCell>
                                <Table.HeaderCell>TRANSMISION</Table.HeaderCell>
                                <Table.HeaderCell>CILINDRADA</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {datosServicio.detalleOperaciones.map((i, index) => (
                                <ItemOperaciones
                                    eliminarOperacion={() => eliminarOperacion(id.id, i._id )}
                                    index={index}
                                    evento={evento}
                                    key={i.nombre}
                                    item={i}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                </Container>
                    
                : null}
        </Container>
            <ConsultaModalOperaciones
                datosServicio={datosServicio}
                modalOperaciones={modalOperaciones}
                actualizar={(payload) => ActualizarServicio(payload)}
                setModalOperaciones={setModalOperaciones}
                setDatosServicio={setDatosServicio}
            />
            <ModalMensaje
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
        </Grid>
     );
}
 
export default DetallesServicio;