import React, {useState, useEffect} from 'react'
import {Grid, Container, Header, Button, Table, Loader, Modal, Pagination } from 'semantic-ui-react';
import { EVENTO_ACTUALIZAR, EVENTO_CREAR } from '../../enum/eventos/tipo-evento';
import {estadoInicialServicio, estadoInicialFiltro} from '../../estadosIniciales/servicios/estadoInicial-servicio'
import {crearServicios, consultaServicios, eliminarServicios} from '../../servicios/servicios/servicios.srv'
import FormularioServicios from '../../components/formularios/formulario.servicios';
import ModalMensaje from '../../components/modales/modalMensaje';
import ConsultaModalOperaciones from '../../components/modales/modalSeleccionOperaciones';
import FiltroServicio from '../../components/filtros/filtro.servicios';
import ModalEliminar from '../../components/modales/modalEliminar';
import ItemServicio from '../../components/itemsListados/item-servicio';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';




const ServiciosIC = () => {
    const modalEliminarContenido = {
        titulo: 'Eliminar Servicio',
        mensaje: '¿Esta seguro que quiere eliminar este servicio?'
    }
    const [ datosServicio, setDatosServicio ] = useState(Object.assign({}, estadoInicialServicio))
    const [ servicios, setServicios ] = useState([])
    const [ servicio, setServicio ] = useState({})
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ servicioEliminar, setServicioEliminar ] = useState('')
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ cargando, setCargando ] = useState(false)
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ modalOperaciones, setModalOperaciones ] = useState(false)
    const [ evento, setEvento ] = useState('')
    const [ modalActivo, setModalActivo ] = useState(false)


    const abrirModal = () => {
        setEvento(EVENTO_CREAR)
        setModalActivo(true)
    }

    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosServicio(servicio)
        } else {
            setDatosServicio(Object.assign({}, estadoInicialServicio))
        }
    }, [evento])

    //PETICION A LA LISTA
    const [filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })

    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }

    const consultar = async(filtro) => {
        const respuesta = await consultaServicios(filtro)
        if(respuesta.data.estatus){
            setServicios(respuesta.data.data.servicios)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.data.total / 10)
            })
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargandoLoader(false)
        }
    }
    useEffect(() => {
       consultar(filtro)
    }, [filtro])

    
    //CRUD
    //CREAR
    const CrearServicio = async(payload) => {
        const respuesta = await crearServicios(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ELIMINAR
    const EliminarServicio = async(id) => {
        const respuesta = await eliminarServicios({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }
    if(cargandoLoader){
        return <Preloader/>
    }
    return(
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid >
            <Header 
                size="huge" 
                as="h1" 
                style={{ 
                    fontSize:50,
                    spacing:'-0.04em',
                    fontWeight:'400', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:30 }}>
                Servicios
                <span>
                <Button 
                    size="mini"
                    onClick={() => abrirModal()}
                    content="Nuevo servicio"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    floated='right'
                />
                </span>
            </Header>
            <FiltroServicio
                setFiltro={setFiltro}
            />
            <FormularioServicios
                evento={evento}
                modalActivo={modalActivo}
                datosServicio={datosServicio}
                guardar={(payload) => CrearServicio(payload)}
                setModalOperaciones={setModalOperaciones}
                setDatosServicio={setDatosServicio}
                setEvento={setEvento}
                setModalActivo={setModalActivo}
            />
            <ConsultaModalOperaciones
                datosServicio={datosServicio}
                modalOperaciones={modalOperaciones}
                setModalOperaciones={setModalOperaciones}
                setDatosServicio={setDatosServicio}
            />
            <Container fluid style={{overflowX: 'scroll'}}>
                <Table textAlign="center" celled structured >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">TIPO</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">MODELO</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">FRECUENCIA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">VIGENCIA</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"> ACCIONES </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell> CODIGO </Table.HeaderCell>
                            <Table.HeaderCell> VERSION </Table.HeaderCell>
                            <Table.HeaderCell> KILOMETRAJE</Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                            <Table.HeaderCell> KILOMETRAJE </Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {servicios !== undefined && servicios.length > 0 ?
                        servicios.map(i => (
                            <ItemServicio
                                setModalEliminar={setModalEliminar}
                                setObjetoEliminar={setServicioEliminar}
                                setModalActivo={setModalActivo}
                                setEvento={setEvento}
                                setServicio={setServicio}
                                key={i._id}
                                item={i}
                            />
                        )): null}
                    </Table.Body>
                </Table>
                </Container>
                { servicios.length < 1 ? <NoItems /> : null }
                <Pagination 
                    style={{marginTop: 10 }}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={servicioEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarServicio(value)}
                    setObjetoEliminar={setServicioEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
    </Grid>
    )
}

export default ServiciosIC;