import React from 'react'
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import { Table, Button } from 'semantic-ui-react';


const ItemModelo = ({
    setModalEliminar,
    setObjetoEliminar,
    setModalActivo,
    setEvento,
    setPrepagado,
    item
}) => {

    const datosInicialPrepagado = {
        descuentos: item.descuentos,
        hora: item.hora,
        rutina: item.rutina,
        _id: item._id
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        })
    }

    const botonActualizar = () => {
       setPrepagado(datosInicialPrepagado)
       setModalActivo(true)
       setEvento(EVENTO_ACTUALIZAR)
    }

    return ( 
        <Table.Row>
            <Table.Cell> {item.descuentos.repuestosImportadora} </Table.Cell>
            <Table.Cell> {item.descuentos.repuestosConcesionario} </Table.Cell>
            <Table.Cell> {item.descuentos.totalDescuento} </Table.Cell>
            <Table.Cell> {item.rutina} km </Table.Cell>
            <Table.Cell> {item.hora} </Table.Cell>
            <Table.Cell>
                <Button
                    content="EDITAR"
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                    size="tiny"
                    onClick={botonActualizar}
                />
                <Button
                    content="ELIMINAR"
                    icon="trash"
                    style={{borderRadius:25}}
                    size="tiny"
                    onClick={() => botonEliminar(item._id)}
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemModelo;