import React, { Fragment } from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import TerminosyCondiciones from '../../vistas/terminosCondiciones/TerminosCondicionesCliente';

class ModalAcuerdoPrepagado extends React.Component {

    render() {

        return (
            <Fragment
                >
                <Modal.Content>
                    <Image 
                    style={{
                        marginLeft:60, 
                        marginRight:60,
                        marginBottom: 30
                    }} 
                    src={require('../../../src/assets/img/logos/logo-kia-blanco-fondo-oscuro.png')} 
                    size="tiny" />
                    <TerminosyCondiciones 
                        cliente={this.props.cliente}
                        vehiculo={this.props.vehiculo}
                        solicitud={this.props.solicitud}
                    />
                </Modal.Content>
                
            </Fragment>
        )

    }
    
}

class ImprimirAcuerdo extends React.Component {
    render () {
        return (
            <Modal open={ this.props.activo }
            style={{ 
                borderRadius: 0, 
                paddingTop: 20, 
                paddingBottom: 20 }}>
                <ModalAcuerdoPrepagado 
                    activo={this.props.activo}
                    cliente={this.props.cliente}
                    vehiculo={this.props.vehiculo}
                    solicitud={this.props.solicitud}
                    setStatusModal={this.props.setStatusModal}
                ref={el => (this.componentRef = el)} />
                <Modal.Actions 
                    style={{ 
                        marginLeft:50, 
                        marginRight:50,
                        backgroundColor:'#FFFFFF', 
                        border:'none', 
                        textAlign:'left'}}>
                    <Button
                        onClick={() => this.props.setStatusModal(false)}
                        content="Cancelar"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        size="tiny"
                    />
                    <ReactToPrint
                        pageStyle="@page { size: auto;  margin-top: 25.4mm; margin-bottom: 20mm; margin-right:10mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
                        trigger={() => {
                            return <Button
                                content="Imprimir"
                                style={{
                                    padding: 20,
                                    borderRadius: 0, 
                                    backgroundColor:'#000000',
                                    borderColor: '#000000',
                                    borderStyle:'solid',
                                    borderWidth:'1px',
                                    fontWeight: 'bold',
                                    color:'#FFFFFF'
                                }}
                                primary
                            />
                        }}
                        content={() => this.componentRef}
                    />
                </Modal.Actions>
                
            </Modal>
        )
    }
}

export default ImprimirAcuerdo