import {ADQUIRIR_PAQUETE_PREPAGADO, ELIMINAR_PAQUETE_CARRITO, OBTENER_PAQUETE_ELIMINAR, LIMPIAR_CARRITO} from '../tipos/carrito'



export const adquirirPaquete = (paquete) => ({
    type: ADQUIRIR_PAQUETE_PREPAGADO,
    payload: paquete
})
export const eliminarPaquete = () => ({
    type: ELIMINAR_PAQUETE_CARRITO
})
export const obtenerPaqueteEliminar = (paqueteEliminar) => ({
    type: OBTENER_PAQUETE_ELIMINAR,
    payload: paqueteEliminar
})
export const limpiarCarrito = () => ({
    type: LIMPIAR_CARRITO
})