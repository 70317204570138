export const csvAJson = ( csv ) => {
    const lineas = csv.split('\n')
    const result = []

    const headers = lineas[0].split(',');

    for ( let i = 1; i < lineas.length; i ++ ) {
        const objeto = {}
        const lineaActual = lineas[i].split(',')

        for ( let j = 0; j < headers.length; j ++ ) {
            objeto[headers[j].trim()] = lineaActual[j]
        }

        result.push(objeto)
    }

    return JSON.stringify(result)
}