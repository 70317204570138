import React, {useState, useEffect, Fragment} from 'react';
import { Grid, Header, Form, Button, Container, Popup, Icon, Item, Input, Segment } from 'semantic-ui-react';
import { TIPO_ROL } from '../../enum/tipo-rol';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import Precio from '../textos/precio';
import moment from 'moment';

const FormularioSolicitudes = ({
    actualizar,
    evento,
    grupos,
    concesionarios,
    datosSolicitud,
    setDatosSolicitud,
    setCargando,
    consultaConcesiones,
    setModalPaquetes,
    consultaVehiculoVin
}) => {


    const [ opciones, setOpciones ] = useState([])
    const [ concesionesSeleccionada, setConcesionesSeleccionada] = useState([])
    const [ vin, setVin ] = useState({
        vin:''
    })
    const usuario = localStorage.getItem('usuario')
    const rol = localStorage.getItem('rol')

    useEffect(() => {
        const datosUsuario = JSON.parse(usuario)
        let opcionesConcesiones = []
        if(usuario){
           datosUsuario.concesiones.map(i => {
                opcionesConcesiones.push({
                    value: i,
                    text: i,
                    key: i
                })
            })
            setOpciones(opcionesConcesiones)
        } 
    }, [usuario])

    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            consultaConcesiones()
        }
    }, [evento])

    const onChangeVin = e => {
        setVin({
            ...vin,
            [e.target.name]:e.target.value
        })
    }
    const onChangeGrupo = (evento, data) => {
        let tempConcesionarios = []
        concesionarios.map( i => {
            if (data.value === i.grupo ) {
                tempConcesionarios.push({
                    key: i.key,
                    text: i.text,
                    value: i.value
                })
            }
        } )
        setConcesionesSeleccionada( tempConcesionarios )
    }

    const onChangeConcesionario = (e, {name, value}) => {
        setDatosSolicitud({
            ...datosSolicitud,
            solicitud:{
                ...datosSolicitud.solicitud,
                [name]:value
            }
        })
    }

    const consultarVin = () => {
        consultaVehiculoVin(vin)
        setCargando(true)
    }


    const seleccionarConcesionario = () => {
        if ( rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO ) {
            return(
                <Form.Select
                    label="Concesionario"
                    name="concesionario"
                    value={datosSolicitud.solicitud.concesionario}
                    onChange={onChangeConcesionario}
                    options={opciones}
                />)
        } else if ( rol === TIPO_ROL.ADMINISTRADOR_SISTEMA ) {
            return (
                    <Fragment>
                        <Form.Select
                            label={
                                <Popup
                                    content="Filtrar concesionario por grupo"
                                    style={{marginLeft: 2}}
                                    trigger={
                                        <label>
                                            Grupo 
                                            <Icon name="info circle"/>
                                        </label>
                                    }
                                />
                            }
                            options={grupos}
                            onChange={onChangeGrupo}
                        />
                        <Form.Select
                            label="Concesionario"
                            name="concesionario"
                            value={datosSolicitud.solicitud.concesionario}
                            options={concesionesSeleccionada}
                            onChange={onChangeConcesionario}
                        />
                    </Fragment>)
        } else if ( rol === TIPO_ROL.IMPORTADORA ) {
            return (
                    <Fragment>
                        <Form.Select
                            label={
                                <Popup
                                    content="Filtrar concesionario por grupo"
                                    style={{marginLeft: 2}}
                                    trigger={
                                        <label>
                                            Grupo
                                            <Icon name="info circle"/>
                                        </label>
                                    }
                                />
                            }
                            options={grupos}
                            onChange={onChangeGrupo}
                        />
                        <Form.Select
                            label="Concesionario"
                            name="concesionario"
                            value={datosSolicitud.solicitud.concesionario}
                            options={concesionesSeleccionada}
                            onChange={onChangeConcesionario}

                        />
                    </Fragment>)
        } else {
            return (
                <Form.Select
                    label="Concesiones"
                    name="concesionario"
                    value={datosSolicitud.solicitud.concesionario}
                    onChange={onChangeConcesionario}
                    options={opciones}
                />)
        }
        
    }


    return ( 
        <Grid style={{marginTop: 40}} container>
            <Container>
                <Grid.Column>
                    <Segment basic>
                        <Header as="h3">Datos de la Solicitud</Header>


                        <Grid  columns={4} stackable>
                            <Grid.Column>
                                <Item.Content>
                                    <small>Grupo</small>
                                    <Item.Meta>
                                    <label style={{fontWeight: "bold"}}>  
                                        {datosSolicitud.concesionario.grupo}
                                    </label>
                                    </Item.Meta>
                                </Item.Content>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Content>
                                    <small>Código CL</small>
                                    <Item.Meta>
                                    <label style={{fontWeight: "bold"}}>  
                                        {datosSolicitud.concesionario.cl}
                                    </label>
                                    </Item.Meta>
                                </Item.Content>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Content>
                                    <small>Concesionario</small>
                                    <Item.Meta>
                                    <label style={{fontWeight: "bold"}}>  
                                        {datosSolicitud.concesionario.nombre}
                                    </label>
                                    </Item.Meta>
                                </Item.Content>
                            </Grid.Column>
                            <Grid.Column>
                                <Item.Content>
                                    <small>Fecha Creación</small>
                                    <Item.Meta>
                                    <label style={{fontWeight: "bold"}}>  
                                        {moment(datosSolicitud.creado).format('DD-MM-yyyy hh:mm a')}
                                    </label>
                                    </Item.Meta>
                                </Item.Content>
                            </Grid.Column>

                            <Grid.Column>
                                <Item.Content>
                                    <small>Última actualización</small>
                                    <Item.Meta>
                                    <label style={{fontWeight: "bold"}}>  
                                        {moment(datosSolicitud.actualizado).format('DD-MM-yyyy hh:mm a')}
                                    </label>
                                    </Item.Meta>
                                </Item.Content>
                            </Grid.Column>

                            
                        </Grid>
                        <Grid columns={2} stackable>
                            <Grid.Column>
                                <Form widths="equal">
                                    <Form.Group>
                                        <Form.Field>
                                            <label>Vin</label>
                                            <Input
                                                label={<Button icon="search" onClick={consultarVin} />}
                                                labelPosition="right"
                                                value={vin.vin}
                                                name="vin"
                                                onChange={onChangeVin}
                                                placeholder="Validar"
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
                <Form>
                    {/* <h3>Datos de la Solicitud</h3> */}
                    {/* <Form.Group widths="equal">
                        <Form.Field>
                            <label>Grupo</label>
                            <Input
                                value={datosSolicitud.concesionario.grupo}
                                placeholder="Grupo"
                                disabled={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Concesionario</label>
                            <Input
                                value={datosSolicitud.concesionario.nombre, datosSolicitud.concesionario.nombre}
                                placeholder="Concesionario"
                                disabled={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Vin</label>
                            <Input
                                label={<Button icon="search" onClick={consultarVin} />}
                                labelPosition="right"
                                value={vin.vin}
                                name="vin"
                                onChange={onChangeVin}
                                placeholder="Validar"
                            />
                        </Form.Field>
                    </Form.Group> */}

                    {<Precio 
                        precio={datosSolicitud.valor.valorTotal}
                        iva={datosSolicitud.valor.iva} />}
                </Form>

                <Grid.Row style={{ marginTop: 40 }}>
                    <Button
                        content="Agregar paquete"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        size="tiny"
                        onClick={() => setModalPaquetes(true)}
                    />
                    <Button
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny"
                        onClick={() => actualizar(datosSolicitud)}
                        content="Guardar cambios"
                    />
                </Grid.Row>
            </Container>
        </Grid>
    );
}
 
export default FormularioSolicitudes;