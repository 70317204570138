import React from 'react';
import { 
    VISTA_ACTIVA_PAQUETES,
} from '../../enum/eventos/tipo-evento'
import {Table, Button, Icon} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const ItemPaquete = ({
    setPaquete,
    vistaActiva,
    setMostrarDetalles,
    setObjetoEliminar,
    setModalEliminar,
    item,
    eliminarPaqueteDeLaSolicitud
}) => {

    
    const botonEliminar = () => {
        setObjetoEliminar(item._id)
        setModalEliminar({
            activo: true
        })
    }

    console.log('Paquete: ', item)

    const mostrarPaquete = () => {
        setMostrarDetalles(true)
        setPaquete(item)
    }

    

    return ( 
        <Table.Row>
        <Table.Cell> {item.nombre} </Table.Cell>
        <Table.Cell> {item.descripcion} </Table.Cell>
        <Table.Cell> {item.codigoPaquete} </Table.Cell>
        <Table.Cell> {item.tipoPaquete}</Table.Cell>
        <Table.Cell> {item.activo? 'ACTIVO':'INACTIVO'}</Table.Cell>
        <Table.Cell> {item.modelo.codigo} </Table.Cell>
        <Table.Cell> {item.modelo.version} </Table.Cell>
        <Table.Cell> {item.modelo.descripcion} </Table.Cell>
        {vistaActiva === VISTA_ACTIVA_PAQUETES?
        <Table.Cell>
            <Button
                icon="eye"
                style={{ color:'black'}}
                onClick={mostrarPaquete}
            />
            <Button
                icon="trash"
                content="Eliminar de la solicitud"
                style={{ color:'black'}}
                onClick={() => eliminarPaqueteDeLaSolicitud(item)}
            />
        </Table.Cell>
        :
        <Table.Cell>
            <Button
                style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                size="tiny"
            >
                <Icon name="edit"/>
                <Link 
                    to={`/detallesPaquete/${item._id}`}
                    style={{textDecoration:"none", color:'#F6F6F6', fontWeight: "bold"}}
                >EDITAR</Link>
            </Button>
            <Button
                icon="trash"
                onClick={botonEliminar}
                style={{borderRadius:25}}
                size="tiny"
            />
            </Table.Cell>
        }

    </Table.Row>
     );
}
 
export default ItemPaquete;