import {ADQUIRIR_PAQUETE_PREPAGADO, ELIMINAR_PAQUETE_CARRITO, OBTENER_PAQUETE_ELIMINAR, LIMPIAR_CARRITO} from '../tipos/carrito'

const initialState = {
    carritoDeCompra: [],
    paqueteEliminar: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADQUIRIR_PAQUETE_PREPAGADO:
            return {
                ...state,
                carritoDeCompra: [...state.carritoDeCompra, action.payload]
            }  
        case OBTENER_PAQUETE_ELIMINAR: 
            return {
                ...state,
                paqueteEliminar: action.payload
            }
        case ELIMINAR_PAQUETE_CARRITO: 
            return {
                ...state,
                carritoDeCompra: state.carritoDeCompra.filter( paquete => paquete._id !== state.paqueteEliminar),
                paqueteEliminar: null
            }
        case LIMPIAR_CARRITO:
            return {
                ...state,
                carritoDeCompra: []
            }
        default:
            return state
    }
}