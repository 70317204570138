import React from 'react';
import {Provider} from 'react-redux';
import './App.css';
import Main from './rutas/main/main';


import configureStore from './configureStore';

let store = configureStore();

function App() {

  return (
      <Provider store={store}>
          <Main/>
      </Provider>
   );
}

export default App;