import React, { Fragment } from 'react';
import currencyFormatter from 'currency-formatter';
import { Header, Segment } from 'semantic-ui-react';

const Precio = ({
    precio,
    iva,
    texto
}) => {
    return (
        <Fragment>
            <Segment basic>
                <Header>
                    <Header.Subheader>
                        IVA {currencyFormatter.format( iva, {code: 'COP', precision: 0})}
                    </Header.Subheader>
                    <Header.Content style={{
                        fontFamily: 'kiaBold',
                        fontSize: '1.875rem',
                        fontWeight: 'normal',
                        letterSpacing: '-0.04em',
                        lineHeight: '32px'
                    }}>
                        Valor Total: {currencyFormatter.format( precio, { code:'COP', precision:0 })}
                    </Header.Content>
                </Header>
            </Segment>
        </Fragment>
    )
}

export default Precio