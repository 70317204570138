import React, {useState} from 'react';
import { estadoInicialFiltro } from '../../estadosIniciales/paquetes/estadoInicial-paquetes';
import {Grid, Segment, Button, Form} from 'semantic-ui-react'

const FiltroPaquetes = ({
    setFiltro
}) => {

    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))

    const buttonActivo = () => {
        setDatosFiltrado({
            ...datosFiltrado,
            activo: !datosFiltrado.activo
        })
    }

    const onChangeDatosFiltrado = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]:e.target.value
        })
    }

    const onChangeDatosFiltradoModelo = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            modelo: {
                ...datosFiltrado.modelo,
                [e.target.name]:e.target.value
            }
        })
    }

    const buscarPaquetes = () => {
        setFiltro(datosFiltrado)
    }

    return(
        <Grid style={{ marginTop: 5}}>
            <Grid.Column>
                <Segment
                    size="mini"
                >
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Codigo"
                                placeholder="Codigo del Paquete"
                                name="codigoPaquete"
                                value={datosFiltrado.codigoPaquete}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Nombre"
                                placeholder="Nombre del Paquete"
                                name="nombre"
                                value={datosFiltrado.nombre}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Descripcion"
                                placeholder="Descripcion del Paquete"
                                name="descripcion"
                                value={datosFiltrado.descripcion}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Codigo del Modelo"
                                placeholder="Codigo del Modelo"
                                name="codigo"
                                value={datosFiltrado.modelo.codigo}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                        </Form.Group>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Descripcion del Modelo"
                                placeholder="Descripcion del Modelo"
                                name="descripcion"
                                value={datosFiltrado.modelo.descripcion}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                            <Form.Input
                                label="Version del Modelo"
                                placeholder="Version"
                                name="version"
                                value={datosFiltrado.modelo.version}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                            <Form.Field>
                                <label>Estado de paquetes</label>
                                <Form.Checkbox
                                    style={{fontWeight: "bold", marginTop: 10}}
                                    checked={datosFiltrado.activo}
                                    toggle
                                    label={datosFiltrado.activo ? "Activos" : "Inactivos"}
                                    name="activo"
                                    onChange={() => buttonActivo()}
                                />
                            </Form.Field>
                        </Form.Group>
                            <span>
                                <Button
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="mini"
                                    icon="search"
                                    content="BUSCAR"
                                    onClick={() => buscarPaquetes()}
                                />
                            </span>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}


export default FiltroPaquetes;