import React from 'react';
import { Message } from 'semantic-ui-react';

const MensajeCamposObligatorios = () => {
    return ( 
        <Message 
            color="red"
        >
            Todos los campos son obligatorios
        </Message>
     );
}
 
export default MensajeCamposObligatorios;