export const estadoInicialRedencion = {
    idServicio: '',
    nombres: {
        nombres: '',
        apellidos: ''
    },
    identificacion:{
        tipo: '',
        numero: ''
    },
    linkInspeccion: '',
    usuarioQueRedime: {
        nombreCompleto: '',
        identificacion: '',
        email: ''
    },
    concesionario: {
        nombre: '',
        grupo: '',
        cl: '',
        tarifaManoObra: ''
    },
    fechaRedencion: '',
    kilometrajeActual: '',
    numeroOrdenTrabajo: '',
    fechaEntregaVehiculo: '',
    alertarImportadora: false,
    comentarios: ''
}