import React, {useEffect, useState} from 'react'
import {Grid, Container, Header, Button, Table, Loader, Modal } from 'semantic-ui-react';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento'
import { consultaPrepagado, crearPrepagado, actualizarPrepagado, eliminarPrepagado } from '../../servicios/prepagado/prepagado.srv';
import FormularioPrepagado from '../../components/formularios/formulario.prepagado';
import ItemPrepagado from '../../components/itemsListados/item-prepagado';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import Preloader from '../../components/utilidades/preloader';
import NoItems from '../../components/utilidades/noItems';


const TempariosPregadoIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Rutina',
        mensaje: '¿Esta seguro que quiere eliminar esta rutina?'
    }
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ prepagadoEliminar, setPrepagadoEliminar ] = useState('')
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ evento, setEvento ] = useState('')
    const [ cargando, setCargando ] = useState(false)
    const [ modalActivo, setModalActivo] = useState(false)
    const [ prepagado, setPrepagado ] = useState({})
    const [ prepagados, setPrepagados ] = useState([])
    //PETICION A LA LISTA
    // eslint-disable-next-line
    const [ filtro, setFiltro ] = useState({})

    const consultar = async(filtro) => {
        const respuesta = await consultaPrepagado(filtro)
        if(!respuesta.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setCargandoLoader(false)
        } else {
            setPrepagados(respuesta.data)
            setCargandoLoader(false)
        }
    }

    useEffect(() => {
        if(filtro){
            consultar(filtro)
        }
    }, [filtro])

    //CRUD
    //CREAR
    const CrearPrepagado = async(payload) => {
        const respuesta = await crearPrepagado(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ACTUALIZAR
    const ActualizarPrepagado = async(payload) => {
        const respuesta = await actualizarPrepagado(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ELIMINAR
    const EliminarPrepagado = async(id) => {
        const respuesta = await eliminarPrepagado({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }

    if(cargandoLoader){
        return <Preloader/>
    }
    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Temparios prepagado
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}} 
                        content="Nueva rutina"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        floated='right'
                    />
                    </span>
                </Header>
                <FormularioPrepagado
                    evento={evento}
                    prepagado={prepagado}
                    modalActivo={modalActivo}
                    guardar={(payload) => CrearPrepagado(payload)}
                    actualizar={(payload) => ActualizarPrepagado(payload)}
                    setEvento={setEvento}
                    setModalActivo={setModalActivo}
                />
                {prepagados !== undefined && prepagados.length > 0 ? 
               <Table textAlign="center" structured celled singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="3">DESCUENTOS</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">RUTINA</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">HORAS</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>

                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell> Repuestos Importadora </Table.HeaderCell>
                            <Table.HeaderCell> Repuestos Concesionario </Table.HeaderCell>
                            <Table.HeaderCell> Total Descuento </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {prepagados.map(i => (
                            <ItemPrepagado
                                setModalEliminar={setModalEliminar}
                                setObjetoEliminar={setPrepagadoEliminar}
                                setEvento={setEvento}
                                setPrepagado={setPrepagado}
                                setModalActivo={setModalActivo}
                                item={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>
                </Table> : <NoItems/>}
                    <ModalMensaje
                        activo={modalMensajeEstatus.activo}
                        mensaje={modalMensajeEstatus.mensaje}
                        setModalMensajeEstatus={setModalMensajeEstatus}
                    />
                    <ModalEliminar
                        modalEliminarActivo={modalEliminar}
                        objetoEliminar={prepagadoEliminar}
                        contenido={modalEliminarContenido}
                        eliminar={(value) => EliminarPrepagado(value)}
                        setObjetoEliminar={setPrepagadoEliminar}
                        setModalEliminar={setModalEliminar}
                        setCargando={setCargando}
                    />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
     );
}
 
export default TempariosPregadoIC;