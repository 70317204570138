import React, {useState} from 'react'
import {estadoInicialFiltro} from '../../estadosIniciales/operaciones/estadoInicial.operacion';
import { tipoAireAcondicionadoOpciones } from '../../estadosIniciales/listas/aireAcondicionado-lista';
import { tipoCombustibleOpciones } from '../../estadosIniciales/listas/combustible-lista';
import { tipoTransmisionOpciones } from '../../estadosIniciales/listas/transmision-lista';
import {Grid, Segment, Button, Form} from 'semantic-ui-react'

const FiltroOperaciones = ({
    setFiltro
}) => {

    const [datosFiltrado, setDatosFiltrado] = useState(Object.assign({}, estadoInicialFiltro))

    const onChangeDatos = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]:e.target.value
        })
    }
    const onChangeAplicaPara = (e, {name, value}) => {
        const valorNumerico = parseFloat(value)
        if(name==="cilindrada"){
            setDatosFiltrado({
                ...datosFiltrado,
                aplicaPara: {
                    ...datosFiltrado.aplicaPara,
                    [name]:valorNumerico
                }
            })
        } else {
            setDatosFiltrado({
                ...datosFiltrado,
                aplicaPara: {
                    ...datosFiltrado.aplicaPara,
                    [name]:value
                }
            })
        }
    }

    const buttonActivo = () => {
        setDatosFiltrado({
            ...datosFiltrado,
            activo: !datosFiltrado.activo
        })
    }
    return(
        <Grid style={{marginTop:5}}>
            <Grid.Column>
                <Segment>
                    <Form>
                        <h3>Filtrar por</h3>
                        <Form.Group 
                            widths="equal"
                        >
                            <Form.Input
                                label="Codigo"
                                width="10"
                                value={datosFiltrado.codigo}
                                name="codigo"
                                onChange={onChangeDatos}
                            />
                            <Form.Input
                                label="Nombre"
                                value={datosFiltrado.nombre}
                                name="nombre"
                                onChange={onChangeDatos}
                            />
                            <Form.Input
                                type="Number"
                                label="Cilindrada"
                                name="cilindrada"
                                value={datosFiltrado.aplicaPara.cilindrada}
                                onChange={onChangeAplicaPara}

                            />
                            <Form.Select
                                clearable
                                label="Aire Acondicionado"
                                name="aireAcondicionado"
                                options={tipoAireAcondicionadoOpciones}
                                value={datosFiltrado.aplicaPara.aireAcondicionado}
                                onChange={onChangeAplicaPara}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Select
                                clearable
                                label="Tipo Combustible"
                                name="tipoCombustible"
                                options={tipoCombustibleOpciones}
                                value={datosFiltrado.aplicaPara.tipoCombustible}
                                onChange={onChangeAplicaPara}
                            />
                            <Form.Select
                                clearable
                                label="Transmision"
                                name="transmision"
                                options={tipoTransmisionOpciones}
                                value={datosFiltrado.aplicaPara.transmision}
                                onChange={onChangeAplicaPara}
                            />
                            <Form.Field>
                                <label>Estado</label>
                            <Form.Checkbox
                                toggle
                                label={datosFiltrado.activo ? "Activos" : "Inactivos"}
                                onChange={buttonActivo}
                                style={{marginTop: 10}}
                                checked={datosFiltrado.activo}
                            />
                            </Form.Field>
                        </Form.Group>
                        <Button
                            content="BUSCAR"
                            icon="search"
                            style={{backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                            onClick={() => setFiltro(datosFiltrado)}
                        />
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default FiltroOperaciones;