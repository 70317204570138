import React, {useState, useEffect}  from 'react';
import { eliminarVariable, consultaVariables, crearVariables, actualizarVariables } from '../../servicios/variablesCalculo/variablesCalculo.srv';
import {Grid, Container, Header, Button, Table, Loader, Modal } from 'semantic-ui-react';
import ItemVariable from '../../components/itemsListados/item-variable';
import FormularioVariablesCalculo from '../../components/formularios/formulario.variablesCaculo';
import ModalMensaje from '../../components/modales/modalMensaje';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento';
import ModalEliminar from '../../components/modales/modalEliminar';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const VariablesIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Variable',
        mensaje: '¿Esta seguro que quiere eliminar esta variable?'
    }
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ cargando, setCargando ] = useState(false)
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ variableEliminar, setVariableEliminar ] = useState('')
    const [ variablesCalculo, setVariablesCalculo ] = useState([])
    const [ variable, setVariable ] = useState({})
    const [ evento, setEvento ] = useState('')

    //PETICION A LA LISTA
    const consultar = async() => {
        const respuesta = await consultaVariables({})
        if(respuesta.estatus){
            setVariablesCalculo(respuesta.data)
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargandoLoader(false)
        }
    }

    useEffect(() => {
        consultar()
    }, [])

    //CRUD
    //CREAR
    const CrearVariables = async (payload) => {
        const respuesta = await crearVariables(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar()
        } else {
            setModalActivo(false)
            setEvento('')
            consultar()
        }
    }
    //ACTUALIZAR
    const ActualizarVariables = async(payload) => {
        const respuesta = await actualizarVariables(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar()
        } else{
            setModalActivo(false)
            setEvento('')
            consultar()
        }
    }
    //ELIMINAR
    const EliminarVariables = async(id) => {
        const respuesta = await eliminarVariable({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar()
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar()
        }
    }

    if(cargandoLoader){
        return <Preloader/>
    }

    return(
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Variables de cálculo
                    <span>
                    <Button 
                        size="mini"
                        onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}}
                        content="Nueva variable"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }} 
                        floated='right'
                    />
                    </span>
                </Header>
                <FormularioVariablesCalculo
                    modalActivo={modalActivo}
                    variable={variable}
                    evento={evento}
                    guardar={(payload)=> CrearVariables(payload)}
                    actualizar={(payload) => ActualizarVariables(payload)}
                    setEvento={setEvento}
                    setModalActivo={setModalActivo}
                />
                {variablesCalculo !== undefined && variablesCalculo.length>0?
                <Table singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>VARIABLE</Table.HeaderCell>
                            <Table.HeaderCell>DESCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell>VALOR</Table.HeaderCell>
                            <Table.HeaderCell>TIPO</Table.HeaderCell>
                            <Table.HeaderCell>APLICA PARA</Table.HeaderCell>
                            <Table.HeaderCell>ACTIVO</Table.HeaderCell>
                            <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {variablesCalculo.map( i => (
                            <ItemVariable
                                setModalEliminar={setModalEliminar}
                                setEvento={setEvento}
                                setVariable={setVariable}
                                setObjetoEliminar={setVariableEliminar}
                                setModalActivo={setModalActivo}
                                item={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>
                </Table>: <NoItems/>}
                
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={variableEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarVariables(value)}
                    setObjetoEliminar={setVariableEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
     )
}

export default VariablesIC