import React from 'react';
import { Grid, Segment, Header, Icon, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


const SeleccionVersion = ({
    version
}) => {
    return (
        <Grid.Column stretched >
            <Segment.Group style={{ borderRadius:0 }}>
                <Segment compact style={{ backgroundColor:'#05141F', color:'#F2F2F2', borderRadius:0, paddingTop:45, paddingBottom:45, paddingLeft:45, paddingRight:45 }} >
                    <Header>
                        <Header.Content as="h2" style={{ color:'#F2F2F2' }}>
                            {version.descripcion}
                        </Header.Content>
                        <Header.Subheader style={{ color:'#F2F2F2' }}>
                            {version.codigo}
                        </Header.Subheader>
                    </Header>
                </Segment>
                <Segment style={{ paddingTop:45, paddingBottom:45, paddingLeft:45, paddingRight:45 }}>
                    <Header >
                        <Header.Content>
                            Caracteristicas
                        </Header.Content>
                    </Header>
                    <List>
                        <List.Item style={{ fontFamily:'kiaMedium'}}>
                            Cilindraje {version.caracteristicas.cilindraje.valor}
                        </List.Item>
                        <List.Item style={{ fontFamily:'kiaMedium'}}>
                            Traccion {version.caracteristicas.traccion.valor}
                        </List.Item>
                        <List.Item style={{ fontFamily:'kiaMedium'}}>
                            {version.caracteristicas.numeroPuertas.valor}
                        </List.Item>
                        <List.Item style={{ fontFamily:'kiaMedium'}}>
                            Transmision {version.caracteristicas.transmision.valor}
                        </List.Item>
                        {/* <List.Item></List.Item> */}
                    </List>
                </Segment>
                <Segment style={{ paddingLeft:45, paddingRight:45, borderTop:'1px solid', borderColor:'#1F1F1F' }}>
                    <Link
                        style={{ 
                            backgroundColor:'transparent', 
                            padding:0, 
                            fontWeight:'bold', 
                            fontFamily:'kiaBold',
                            marginRight: 20,
                            color:'#000000', 
                            hover:{ borderBottom: 'solid', borderColor:'red' } }}
                        to={{pathname:`/vistaPaquetes/${version.codigo}`}}> 
                        <strong>Ver</strong><Icon name="chevron right" />
                    </Link>
                </Segment>
            </Segment.Group>
        </Grid.Column>
    )
}

export default SeleccionVersion