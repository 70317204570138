import React, { useState, Fragment } from 'react'
import { Dropdown, Grid, Segment, Button, Form} from 'semantic-ui-react'

import ModalMensaje from '../modales/modalMensaje';
import { estadoInicialFiltroUsuarios } from '../../estadosIniciales/usuarios/estadoInicial.usuario';

const FiltroConcesiones = ({ listaConcesionarios, consultarUsuarios}) => {
    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltroUsuarios))
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    const agregarConcesionario = ( event, data) => {
        setDatosFiltrado({
            ...datosFiltrado,
            concesiones: data.value
        })
    }
    const onChangeEmail = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]: e.target.value
        })
    }

    const onChangeNombre = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            nombre: {
                ...datosFiltrado.nombre,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeIdentificacion = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            identificacion:{
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeEstatus = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            activo: !datosFiltrado.activo
        })
    }
            
    return (
        <Fragment>
            <Grid style={{ marginTop: 5}}>
                <Grid.Column>
                    <Segment
                        size="mini"
                    >
                        <Form>
                            <h3>Filtrar Por</h3>
                            <Form.Group
                                widths="equal"
                            >
                                <Form.Input
                                    label="Correo Electronico"
                                    name="email"
                                    value={datosFiltrado.email}
                                    onChange={onChangeEmail}
                                />
                                <Form.Input
                                    label="Nombre"
                                    name="nombres"
                                    value={datosFiltrado.nombre.nombres}
                                    onChange={onChangeNombre}
                                />
                                <Form.Input
                                    label="Apellidos"
                                    name="apellidos"
                                    value={datosFiltrado.nombre.apellidos}
                                    onChange={onChangeNombre}
                                />
                                <Form.Input
                                    label="Numero De Identificacion"
                                    name="numero"
                                    value={datosFiltrado.identificacion.numero}
                                    onChange={onChangeIdentificacion}
                                />
                                
                            </Form.Group>
                            <Form.Group widths="3">
                                <Form.Field>
                                    <Dropdown
                                        onChange={(event, data)=>agregarConcesionario(event, data)}
                                        placeholder='Concesionarios' 
                                        fluid 
                                        multiple 
                                        selection 
                                        options={listaConcesionarios} />
                                </Form.Field>
                                    <Form.Checkbox
                                        style={{fontWeight: "bold", marginTop:10}}
                                        defaultChecked
                                        toggle
                                        label={datosFiltrado.activo ? 'Activos': 'Inactivos'}
                                        name="activo"
                                        value={datosFiltrado.activo}
                                        onChange={onChangeEstatus}
                                    />
                                <Form.Field>
                                    <Button
                                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                        size="mini"
                                        icon="search"
                                        content="BUSCAR"
                                        onClick={() => consultarUsuarios(datosFiltrado)}
                                    />
                                </Form.Field>
                            </Form.Group>  
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid>
            <ModalMensaje
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
        </Fragment> 
        
     );
}
 
export default FiltroConcesiones;