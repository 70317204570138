export const estadoInicialConcesion = {
    cl: '',
    nombre: '',
    abreviatura: '',
    grupo: '',
    tipo: '',
    email: '',
    activo: false,
    direccion: {
        callePrincipal: '',
        calleSecundaria: '',
        numero: '',
        otros: '',
        barrio: '',
        ciudad: '',
    },
    telefonos: {
        indicativoCiudad: '',
        numero: '',
    },
    manoObra: {
        ordinaria: '',
        tmog: ''
    }
}

export const estadoInicialFiltrado = {
    nombre: '',
    abreviatura: '',
    grupo: '',
    tipo: '',
    cls: [],
    salto: 0,
    limite: 10,
    activo: true
}