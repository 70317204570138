export const estadoInicialPaquete = {
    codigoPaquete: '',
    tipoPaquete: '',
    modelo: {
        codigo: '',
        descripcion: '',
        version: ''
    },
    nombre: '',
    descripcion: '',
    valorPresente: 0,
    valorFuturo: 0,
    servicios: [],
    detallesServicios: [],
    condiciones: {
        estatus: false,
        vin: [],
        versiones: [],
        fechaInicio: '',
        fechaFinal: ''
    },
    estatus: {
        nombre: '',
        fecha: ''
    },
    consolidadoEstatus: []
}

export const estadoInicialFiltro = {
    activo: true,
    codigoPaquete: '',
    modelo: {
        codigo: '',
        descripcion: '',
        version: '',
    },
    nombre: '',
    descripcion: '',
    salto: 0,
    limite: 10
}