import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {Grid, Header, Container } from 'semantic-ui-react';
import { estadoInicialModelos } from '../../estadosIniciales/modelos/estadoInicial-modelos';
import { consultaModeloId } from '../../servicios/modelos/modelo.srv';
import ModalMensaje from '../../components/modales/modalMensaje';
import SeleccionVersion from '../../components/tarjetas/presentacionModelos/seleccionVersion';
import Preloader from '../../components/utilidades/preloader';

const VistaPresentacionModelosIC = () => {

    
    const [ datosModelo, setDatosModelo ] = useState( Object.assign({}, estadoInicialModelos ) )
    // eslint-disable-next-line
    const [ parametros, setParametro ] = useState(useParams())
    const [ datosModal, setDatosModal ] = useState({
        activo: false,
        mensaje: ''
    }) 
    const [cargando, setCargando] = useState(true)

    useEffect( () => {
        if ( parametros.id) {
            consultaModelo()
        }
    }, [parametros.id] )

    const consultaModelo = async () => {
        const respuesta = await consultaModeloId( parametros )
        const data = respuesta.data
        
        if ( data.estatus ) {
            setDatosModelo( data.data.modelo )
            setCargando( false )
        } else {
            setDatosModal({
                activo: true,
                mensaje: 'Error al obtener las versiones'
            })
            setCargando( false )
        }
        
    }

    if ( cargando ) {
        return <Preloader/>
    }

    return(
        <Fragment>
            <Grid centered style={{marginTop:100, marginBottom: 0, marginLeft:50, marginRight:50  }}>
                <ModalMensaje
                    activo={datosModal.activo}
                    mensaje={datosModal.mensaje}
                    setModalMensajeEstatus={setDatosModal}
                />

                <Container>
                    <Header 
                        size="huge" 
                        as="h1"
                        style={{ 
                            fontSize:50,
                            spacing:'-0.04em',
                            fontWeight:'bold', 
                            fontFamily:'kiaBold', 
                            marginBottom:20, 
                            marginTop:30 }}>
                        
                        <Header.Content>
                            {datosModelo.modelo.nombre ? datosModelo.modelo.nombre : 'Modelo no disponible'}
                        </Header.Content>
                        <Header.Subheader>
                            Selecciona una de las {datosModelo.versiones.length} versiones disponibles
                        </Header.Subheader>
                    </Header>
                </Container>
            </Grid>
            <Grid columns={3} stackable style={{ marginLeft:50, marginRight:50, marginBottom:50, minHeight:'70vh'  }}>
                { datosModelo.versiones.map( (i, index) => {
                    return <SeleccionVersion version={i} key={index} />
                } )}
        </Grid>
    </Fragment>
    )
}

export default VistaPresentacionModelosIC;