import React, {useState, useEffect} from 'react';
import { Modal, Form, Button, Header, Message } from 'semantic-ui-react';
import { estadoInicialVehiculo } from '../../estadosIniciales/vehiculos/estadoInicial-vehiculos';
import Mensaje from '../alertas/alertas.camposObligatorios';




const ModalEdicionVehiculo = ({
    vehiculo,
    editarVehiculo,
    agregarVehiculo,
    actualizarVehiculo,
    setEditarVehiculo
}) => {

    const [ datosVehiculos, setDatosVehiculos ] = useState(Object.assign({}, estadoInicialVehiculo))
    const [ mensaje ] = useState(false)

    useEffect(() => {
        if(vehiculo.modelo){
            setDatosVehiculos(vehiculo)
        }
    }, [vehiculo])


    const onChangeModelo = e => {
        setDatosVehiculos({
            ...datosVehiculos,
            modelo: {
                ...datosVehiculos.modelo,
                [e.target.name] : e.target.value
            }
            
        })
    }
    const onChangeDatos = e => {
        setDatosVehiculos({
            ...datosVehiculos,
            [e.target.name] : e.target.value
        })
    }


    return(
        <Modal
            open={editarVehiculo}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
        <Modal.Header 
            style={{ 
                backgroundColor:'#FFFFFF', 
                border:'none'}} > 
                <Header>
                    Formulario Vehiculos 
                </Header>
        </Modal.Header>
        <Modal.Content>
        <Form>
                    <h3>Datos</h3>  
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Vin"
                            placeholder="Ej. U5YPX81DBPL082165"
                            name="vin"
                            value={datosVehiculos.vin}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Versión"
                            name="version"
                            placeholder="Ej. NQ1A70__25G2000"
                            value={datosVehiculos.version}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Placa"
                            name="placa"
                            placeholder="Ej. ABC123"
                            value={datosVehiculos.placa}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Descripción De Versión"
                            name="descripcionVersion"
                            placeholder="Ej. Vibrant"
                            value={datosVehiculos.descripcionVersion}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <h3>Modelo</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            placeholder="Ej. Sportage NQ"
                            value={datosVehiculos.modelo.nombre}
                            onChange={onChangeModelo}
                        />
                        <Form.Input
                            label="Codigo"
                            name="codigo"
                            placeholder="Ej. NQ"
                            value={datosVehiculos.modelo.codigo}
                            onChange={onChangeModelo}
                        />
                        <Form.Input
                            label="Origen"
                            name="origen"
                            placeholder="Ej. Eslovaquia"
                            value={datosVehiculos.modelo.origen}
                            onChange={onChangeModelo}
                        />
                        <Form.Input
                            label="Color"
                            placeholder="Ej. BLANCO"
                            name="color"
                            value={datosVehiculos.modelo.color}
                            onChange={onChangeModelo}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            width="4"
                            placeholder="Ej. 2023"
                            label="Año Del Modelo"
                            name="anoModelo"
                            value={datosVehiculos.modelo.anoModelo}
                            onChange={onChangeModelo}
                        />
                    </Form.Group>
                </Form>

                <Message
                    header='Instrucciones'>
                    <Message.Content>
                        <strong>VIN: </strong> Tenga en cuenta que el vin debe contener 17 caracteres, todos en mayúsculas. <br></br>
                        <strong>VERSION: </strong> Coloque el código de equipamento, Ej. NQ1A70__25G2000 todos en mayúsculas.<br></br>
                        <strong>PLACA: </strong> Escriba la placa sin espacios ni separadores Ej. ABC123 todos en mayúsculas.<br></br>
                        <strong>DESCRIPCION DE LA VERSION: </strong> Escriba el nombre de la versión Ej. Vibrant, Emotion todos en mayúsculas.<br></br>
                        <strong>MODELO NOMBRE: </strong> Escriba el nombre del modelo Ej. Sportage NQ todos en mayúsculas.<br></br>
                        <strong>MODELO CODIGO: </strong> Escriba el Código modelo posventa, Ej. NQ. Normalmente son los primeros 2 caracteres de la versión, todo en mayúscula<br></br>
                        <strong>MODELO ORIGEN: </strong> Escriba el nombre del país del cual proviene Ej. Eslovaquia<br></br>
                        <strong>MODELO COLOR: </strong> Escriba el color del vehículo, Ej. BLANCO <br></br>
                        <strong>MODELO AÑO: </strong> Escriba el año modelo del vehículo Ej. 2023 <br></br>
                    </Message.Content>
                </Message>
            { mensaje ? <Mensaje /> : null}
        </Modal.Content>
        <Modal.Actions
            style={{ 
                backgroundColor:'#FFFFFF', 
                border:'none', 
                textAlign:'left'}}
        >
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    size="tiny"
                    primary 
                    onClick={() => setEditarVehiculo(false)}
                    content="Cancelar"
                />
                {vehiculo._id 
                ? 
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    primary
                    size="tiny" 
                    onClick={() => actualizarVehiculo(datosVehiculos)}
                    content="Actualizar vehiculo"
                />
                :
                <Button
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#000000',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#FFFFFF'
                    }}
                    primary
                    size="tiny" 
                    onClick={() => agregarVehiculo(datosVehiculos)}
                    content="Agregar vehiculo"
                />
                }

        </Modal.Actions>
        </Modal>
    )
}

export default ModalEdicionVehiculo;