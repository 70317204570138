export const estadoInicialServicio = {
    nombre: '',
    descripcion: '',
    tipo: '',
    cantidad: '',
    tarifaManoObraUnificada: false,
    activo: true,
    modelo: {
        codigo: '',
        descripcion: '',
        version: []
    },
    frecuencia: {
        kilometraje: '',
        meses: ''
    },
    vigencia: {
        autorizadoPorImportadora: false,
        kilometraje: '',
        meses: ''
    },
    operaciones: [],
    detalleOperaciones: [],
    // preciosRepuestos: {
    //     publico: 0,
    //     concesionario: 0,
    //     descuento: 0,
    //     publicoDespuesDescuento: 0,
    //     concesionarioDespuesDescuento: 0
    // },
    // preciosManoObra: {
    //     horas: 0,
    //     valorHoras: 0,
    //     valorHorasDespuesFactor: 0
    // },
    // redencion: {
    //     fechaRedencion: '',
    //     kilometrajeActual: '',
    //     numeroOrdenTrabajo: '',
    //     fechaEntregaVehiculo: '',
    //     alertarImportadora: true,
    //     linkInspeccion: '',
    //     nombres:{
    //         nombres: '',
    //         apellidos: ''
    //     },
    //     identificacion: {
    //         tipo: '',
    //         numero: ''
    //     },
    //     usuarioQueRedime: {
    //         nombreCompleto: '',
    //         identificacion: '',
    //         email: ''
    //     },
    //     concesionario:{
    //         nombre: '',
    //         grupo: '',
    //         cl: '',
    //         tarifaManoObra: ''
    //     }
    // }
    estatus: {
        nombre: 'CREADO',
        fecha: ''
    },
    consolidadoEstatus: []
}

export const estadoInicialFiltro = {
    activo: true,
    modelo: {
        codigo: "",
        version: ""
    },
    nombre: "",
    descripcion: "",
    tipo: "",
    salto:0,
    limite:10
}