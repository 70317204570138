import moment from 'moment-timezone';
import React, {useState, useEffect, Fragment} from 'react';
import {Grid, Table, Button, Loader, Modal , Container, Header, Form, Segment, Select } from 'semantic-ui-react';
import { consultaConcesionarioPorGrupo } from '../../servicios/concesion/concesiones.srv';
import ModalMensaje from '../../components/modales/modalMensaje';
import { estadoSolicitudesLista } from '../../estadosIniciales/listas/estadoSolicitud-lista';
import { variables } from '../../config/variables'

const ReportesIC = () => {

    const eifiltroSolicitudes = {
        desde: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD'),
        asesor_cedula: '',
        concesionario_cl: '',
        concesionario_grupo: 'TODOS',
        estado: 'TODOS'
    }

    const eifiltroServicios = {
        desde: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD'),
        // asesor_cedula: '',
        // concesionario_cl: '',
        concesionario_grupo: 'TODOS',
        estado: 'TODOS'
    }

    const listaEstadoRedencion = [
        { key:0, text: 'Todos', value: 'TODOS' },
        { key:1, text: 'Redimido', value: 'Redimido' },
        { key:3, text: 'Pendiente', value: 'Pendiente', }
    ]

    const [ filtroSolicitudes, setFiltroSolicitudes ] = useState( Object.assign({}, eifiltroSolicitudes ));
    const [ filtroServicios, setFiltroServicios ] = useState( Object.assign({}, eifiltroServicios ));
    const [ listaGrupos, setListaGrupos ] = useState([]);
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    });
    // const [ listaEstadoRedencion2, setListaEstadoRedencion2 ] = setState( Object.assign({}, listaEstadoRedencion))

    useEffect(() => {
        consultaConcesiones();
    }, [])

    const consultaConcesiones = async () => {
        try {
            const concesiones = await consultaConcesionarioPorGrupo({})

            if ( concesiones.estatus ) {
                const prepararLista = []
                const prepararGrupos = [{
                    key: 0,
                    text: 'Todos',
                    value: 'TODOS'
                }]
                concesiones.data.map( i => {

                    prepararGrupos.push({
                        key: i.grupo,
                        text: i.grupo,
                        value: i.grupo
                    })

                    return i.concesiones.map( j => {
                       
                        return prepararLista.push({
                            grupo: i.grupo,
                            key: `${i.grupo} ${j.nombre} ${j.cl}`,
                            text: `${j.nombre} ${j.cl}`,
                            value: j.cl
                        })
                    })
                } )

                // setListaConcesionarios( prepararLista )
                setListaGrupos( prepararGrupos )
            } else {

                setModalMensajeEstatus({
                    activo: true,
                    mensaje: concesiones.resultadoOperacion
                })
            }
        } catch ( error ) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'Error al consultar concesiones'
            })
        }
        
    }

    const descargarReporteServicios = async () => {


        const url = { _arg: `${variables.backend}api/v1/reportes/reporte-servicios?` };
        url._arg += `desde=${filtroServicios.desde}&`;
        url._arg += `hasta=${filtroServicios.hasta}&`;
        url._arg += `concesionario_grupo=${filtroServicios.concesionario_grupo}&`;
        url._arg += `estado=${filtroServicios.estado}`;
        console.log('URL: ', url._arg)
        window.open(url._arg)
    }

    const descargarReporteSolicitudes = () => {

        const url = { _arg: `${variables.backend}api/v1/reportes/reporte-solicitudes?` };
        url._arg += `desde=${filtroSolicitudes.desde}&`;
        url._arg += `hasta=${filtroSolicitudes.hasta}&`;
        url._arg += `asesor_cedula=${filtroSolicitudes.asesor_cedula}&`;
        url._arg += `concesionario_cl=${filtroSolicitudes.concesionario_cl}&`;
        url._arg += `concesionario_grupo=${filtroSolicitudes.concesionario_grupo}&`;
        url._arg += `estado=${filtroSolicitudes.estado}`;
        console.log('URL: ', url._arg)
        window.open(url._arg)

        // window.open('http://localhost:5000/api/v1/reportes/reporte-solicitudes')
    }

    const onChangeFiltro = ( e ) => {
        setFiltroSolicitudes({
            ...filtroSolicitudes,
            [e.target.name]: e.target.value
        })
    }

    const onChangeFiltroServicio = ( e ) => {
        setFiltroServicios({
            ...filtroServicios,
            [e.target.name]: e.target.value
        })
    }

    const onChangeSelect = ( e, { name, value }) => {
        setFiltroSolicitudes({
            ...filtroSolicitudes,
            [name]: value
        })
    }

    const onChangeSelectServicio = ( e, { name, value }) => {
        setFiltroServicios({
            ...filtroServicios,
            [name]: value
        })
    }

    console.log('filtroServicios: ', filtroServicios)
    // console.log('Filtro: ', listaConcesionarios)
    // console.log('Filtro: ', listaGrupos)

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
            <Container fluid={true}>
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    Reportes
                </Header>

                <Segment color='red'>
                    <Header>
                        Solicitudes
                    </Header>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={onChangeFiltro} label="Desde" type="date" value={filtroSolicitudes.desde} name="desde" />
                            <Form.Input onChange={onChangeFiltro} label="Hasta" type="date" value={filtroSolicitudes.hasta} name="hasta" />
                        </Form.Group>
                    </Form>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={onChangeFiltro} label="CL Concesionario" type="text" value={filtroSolicitudes.concesionario_cl} name="concesionario_cl" />
                            <Form.Input onChange={onChangeFiltro} label="Cédula asesor" type="text" value={filtroSolicitudes.asesor_cedula} name="asesor_cedula" />
                        </Form.Group>
                    </Form>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Select onChange={onChangeSelect} options={listaGrupos} label="Grupo" type="text" value={filtroSolicitudes.concesionario_grupo} name="concesionario_grupo" />
                            <Form.Select onChange={onChangeSelect} options={estadoSolicitudesLista} label="Estado" type="text" value={filtroSolicitudes.estado} name="estado" />
                        </Form.Group>
                    </Form>
                    
                    <Button onClick={() => descargarReporteSolicitudes()} content="Descargar" />
                </Segment>

                <Segment color='red'>
                    <Header>
                        Servicios
                    </Header>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input onChange={onChangeFiltroServicio} label="Desde" type="date" value={filtroServicios.desde} name="desde" />
                            <Form.Input onChange={onChangeFiltroServicio} label="Hasta" type="date" value={filtroServicios.hasta} name="hasta" />
                        </Form.Group>
                    </Form>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Select onChange={onChangeSelectServicio} options={listaGrupos} label="Grupo" type="text" value={filtroServicios.concesionario_grupo} name="concesionario_grupo" />
                            <Form.Select onChange={onChangeSelectServicio} options={listaEstadoRedencion} label="Estado" type="text" value={filtroServicios.estado} name="estado" />
                        </Form.Group>
                    </Form>
                    <Button onClick={()=> descargarReporteServicios()} content="Descargar" />
                </Segment>
            </Container>
            <ModalMensaje
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
        </Grid>
     );
}
 
export default ReportesIC;