export const estadoInicialUsuario = {
    contrasena: '',
    concesiones: [],
    activo: true,
    rol: '',
    cargo: '',
    grupo: '',
    email: '',
    nombre: {
        nombres: '',
        apellidos: ''
    },
    identificacion: {
        tipo: '',
        numero: ''
    },
    permisos: {
        lectura: true,
        escritura: true,
        eliminar: true
    }

}

export const estadoInicialFiltroUsuarios = {
    email: '',
    nombre: {
        nombres: '',
        apellidos: ''
    },
    identificacion: {
        numero: ''  
    },
    concesiones: [],
    activo: true,
    salto: 0,
    limite: 10
}