import React, { Fragment, useEffect, useState } from 'react';
import { Button, Form, Grid, Table, Icon, Header, Segment, Label, TextArea, Message, Popup } from 'semantic-ui-react';
import { tipoIdentificacionOpciones } from '../../estadosIniciales/listas/identificacion-lista';
import moment from 'moment-timezone'
import currencyFormatter from 'currency-formatter'
import NoItems from '../../components/utilidades/noItems';
import { VISTA_SERVICIOS_REDIMIR } from '../../enum/eventos/tipo-evento';
import { TIPO_ROL } from '../../enum/tipo-rol';



const DatosServicio = ({
    redimir,
    grupos,
    evento,
    concesionarios,
    consultaConcesiones,
    redencion, 
    setRedencion,
    i
}) => {

    const [ mostrarAlerta, setMostrarAlerta] = useState(false)
    const [ concesionesSeleccionada, setConcesionesSeleccionada ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const [ rol ] = useState(localStorage.getItem('rol'))
    const [ usuario ] = useState(localStorage.getItem('usuario'))

    const llamarRedencion = () => { 
        if (
            redencion.nombres.nombres.trim() === '' ||
            redencion.nombres.apellidos.trim() === '' || 
            redencion.identificacion.tipo.trim() === '' ||
            redencion.identificacion.numero.trim() === '' ||
            redencion.linkInspeccion.trim() === '' ||
            redencion.kilometrajeActual === '' ||
            redencion.numeroOrdenTrabajo.trim() === '' ||
            redencion.fechaEntregaVehiculo.trim() === '' 
        ) {
            setMostrarAlerta(true)
        } else {
            redimir()
            setMostrarAlerta(false)
        }
    }

    useEffect(() => {
        const datosUsuario = JSON.parse(usuario)
        let opcionesConcesiones = []
        if(usuario){
           datosUsuario.concesiones.map(i => {
                opcionesConcesiones.push({
                    value: i,
                    text: i,
                    key: i
                })
            })
            setOpciones(opcionesConcesiones)
        } 
    }, [usuario])

    useEffect(() => {
        if(evento === VISTA_SERVICIOS_REDIMIR) {
            consultaConcesiones()
        }
    },  [evento])

    const onChangeGrupo = (e, data) => {
        let tempConcesionarios = []
        let grupo = data.value
        concesionarios.map( i => {
            if (grupo === i.grupo ) {
                tempConcesionarios.push({
                    key: i.key,
                    text: i.text,
                    value: i.value
                })
                setRedencion({
                    ...redencion,
                    concesionario: {
                        ...redencion.concesionario,
                        grupo: grupo
                    } 
                })
            }
        } )
        setConcesionesSeleccionada( tempConcesionarios )
    }

    const onChageDatosRedencion = e => {
        setRedencion({
            ...redencion,
            [e.target.name]:e.target.value
        })
    }

    const onChangeNombresRedencion = e => {
        setRedencion({
            ...redencion,
            nombres: {
                ...redencion.nombres,
                [e.target.name]:e.target.value
            }
        })
    }

    const onChangeIdentificacionRedencion = (e, {name, value}) => {
        setRedencion({
            ...redencion,
            identificacion: {
                ...redencion.identificacion,
                [name]:value
            }
        })
    }
    const onChangeIdentificacionN = (e) => {
        setRedencion({
            ...redencion,
            identificacion: {
                ...redencion.identificacion,
                [e.target.name]:e.target.value
            }
        })
    }

    const onChangeConcesionario = (e, data) => {
        concesionarios.map( i => {
            if( data.value === i.value ) {
                setRedencion({
                    ...redencion,
                    concesionario: {
                        ...redencion.concesionario,
                        cl: i.cl,
                        nombre: data.value
                    }
                })
            } 
        })
    } 

    const onChangeConcesionarioAC = (e, {name, value}) => {
        setRedencion({
            ...redencion,
            concesionario:{
                ...redencion.concesionario,
                nombre:value
            }
        })
    }

    const renderSelectConcesiones = () => {
        if (rol === TIPO_ROL.ADMINISTRADOR_SISTEMA) {
            return ( 
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Group>
                                <Form.Select
                                    label={
                                        <Popup
                                            content="Filtrar concesionario por grupo"
                                            style={{marginLeft: 2}}
                                            trigger={
                                                <label>
                                                    Grupo 
                                                    <Icon name="info circle"/>
                                                </label>
                                            }
                                        />
                                    }
                                    options={grupos}
                                    onChange={onChangeGrupo}
                                />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Group>
                                <Form.Select
                                    label="Concesionario"
                                    name="concesionario"
                                    options={concesionesSeleccionada}
                                    value={redencion.concesionario.nombre}
                                    onChange={onChangeConcesionario}
                                />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        } else if (rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO) {
            return (
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Form.Group>
                                <label> Concesionario </label>
                                <Form.Select
                                    name="concesionario"
                                    options={opciones}
                                    value={redencion.concesionario.nombre}
                                    onChange={onChangeConcesionarioAC}
                                />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        } else {
            return (
                <Fragment>
                    <strong>Nombre: </strong> {i.redencion.concesionario.nombre} <br></br>
                    <strong>Grupo: </strong> {i.redencion.concesionario.grupo} <br></br>
                    <strong>Cl: </strong> {i.redencion.concesionario.cl} <br></br>
                    <strong>Tarifa mano de obra: </strong> {currencyFormatter.format(i.redencion.concesionario.tarifaManoObra, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                </Fragment>
            )
        }

    }

    const renderServicios = () => {
        if ( i ) {
            console.log('Servicio: ', i )
            return (
                <Grid.Column style={{marginTop: 10}} key={i._id}> 
                    <Segment>
                        <Header as='h1'>
                            Detalles del servicio {!i.redencion.estatus ? <Button floated='right' content="Redimir" onClick={() => llamarRedencion()} />: null}
                            <Header.Subheader>
                                {i.descripcion} <Label size='tiny'>{i.tipo}</Label>
                            </Header.Subheader>
                        </Header>
                        {mostrarAlerta ?
                                <Message 
                                    negative
                                    size="mini"
                                >
                                    <Message.Header>Por favor diligencie todos los campos</Message.Header>
                        </Message>:  null}
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Frecuencia</Header>
                                        <Header.Subheader>
                                            <strong>Kilometraje: </strong> {currencyFormatter.format(i.frecuencia.kilometraje, { precision:0, thousand:'.'})} <br></br>
                                            <strong>Meses: </strong> {i.frecuencia.meses} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Vigencia</Header>
                                        <Header.Subheader>
                                            <strong>Kilometraje: </strong> {currencyFormatter.format(i.vigencia.kilometraje, { precision:0, thousand:'.'})} <br></br>
                                            <strong>Meses: </strong> {i.vigencia.meses} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {/* <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>Precio Repuestos</Header>
                                        <Header.Subheader>
                                            <strong>Publico: </strong> {currencyFormatter.format(i.preciosRepuestos.publico, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Concesionario: </strong> {currencyFormatter.format(i.preciosRepuestos.concesionario, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Descuentos: </strong> {currencyFormatter.format(i.preciosRepuestos.descuento, { precision: 0})} % <br></br>
                                            <strong>Publico despues de descuento: </strong> {currencyFormatter.format(i.preciosRepuestos.publicoDespuesDescuento, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Concesionario despues de descuento: </strong> {currencyFormatter.format(i.preciosRepuestos.concesionarioDespuesDescuento, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>Mano de Obra</Header>
                                        <Header.Subheader>
                                            <strong>Horas: </strong> {i.preciosManoObra.horas} <br></br>
                                            <strong>Valor horas: </strong> {i.preciosManoObra.valorHoras} <br></br>
                                            <strong>Valor horas despues de factor: </strong> {i.preciosManoObra.valorHorasDespuesFactor} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid> */}
                        <Grid>
                            <Grid>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h4'>Redención</Header>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Nombre</Header>
                                        {i.redencion.estatus ? <Header.Subheader>
                                            <strong>Nombres: </strong> {i.redencion.nombres.nombres} <br></br>
                                            <strong>Apellidos: </strong> {i.redencion.nombres.apellidos} <br></br>
                                        </Header.Subheader>
                                        :
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input 
                                                            label='Nombres' 
                                                            onChange={onChangeNombresRedencion}
                                                            value={redencion.nombres.nombres}
                                                            name="nombres" 
                                                            type='text' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input 
                                                            label='Apellidos' 
                                                            onChange={onChangeNombresRedencion}
                                                            value={redencion.nombres.apellidos}
                                                            name="apellidos"
                                                            type='text' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Identificación</Header>
                                        {i.redencion.estatus?
                                        <Header.Subheader>
                                            <strong>Tipo: </strong> {i.redencion.identificacion.tipo} <br></br>
                                            <strong>Número: </strong> {i.redencion.identificacion.numero} <br></br>
                                        </Header.Subheader>
                                        :
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Select
                                                            size="small"
                                                            label="Tipo de identificacion"
                                                            width="4"
                                                            options={tipoIdentificacionOpciones}
                                                            name="tipo"
                                                            value={redencion.identificacion.tipo}
                                                            onChange={onChangeIdentificacionRedencion}
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input 
                                                            label='Numero'
                                                            value={redencion.identificacion.numero}
                                                            onChange={onChangeIdentificacionN}
                                                            name="numero"
                                                            type='text' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>}
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Usuario que redime</Header>
                                        <Header.Subheader>
                                            <strong>Nombre: </strong> {i.redencion.usuarioQueRedime.nombreCompleto} <br></br>
                                            <strong>identificación: </strong> {i.redencion.usuarioQueRedime.identificacion} <br></br>
                                            <strong>Correo electrónico: </strong> {i.redencion.usuarioQueRedime.email} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Concesionario</Header>
                                        {i.redencion.estatus 
                                        ? <Header.Subheader>
                                            <strong>Nombre: </strong> {i.redencion.concesionario.nombre} <br></br>
                                            <strong>Grupo: </strong> {i.redencion.concesionario.grupo} <br></br>
                                            <strong>Cl: </strong> {i.redencion.concesionario.cl} <br></br>
                                            <strong>Tarifa mano de obra: </strong> {currencyFormatter.format(i.redencion.concesionario.tarifaManoObra, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                        </Header.Subheader> 
                                        :
                                        renderSelectConcesiones()
                                        }
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Otros datos</Header>
                                        {
                                            i.redencion.estatus
                                        ?
                                        <Header.Subheader>
                                            <strong>Link de la inspección: </strong> {i.redencion.linkInspeccion} <br></br>
                                            <strong>fecha de redencion: </strong>  {moment(i.redencion.fechaRedencion).isValid()
                                                                                    ? moment(i.redencion.fechaRedencion).format("DD/MM/YYYY hh:mm a")
                                                                                    : 'N/A'} <br></br>
                                            <strong>fecha de entrega del vehiculo: </strong> {moment(i.redencion.fechaEntregaVehiculo).isValid()
                                                                                    ? moment(i.redencion.fechaEntregaVehiculo).format("DD/MM/YYYY hh:mm a")
                                                                                    : 'N/A'} <br></br>
                                            <strong>comentarios: </strong> {i.redencion.comentarios} <br></br>
                                            <strong>Numero de orden de trabajo: </strong> {i.redencion.numeroOrdenTrabajo} <br></br>
                                            <strong>kilometraje actual: </strong> {i.redencion.kilometrajeActual} <br></br>
                                            <strong>alertar importadora: </strong> {i.redencion.alertarImportadora ? 'Si' : 'No'} <br></br>
                                            <strong>Estado: </strong> {i.redencion.estatus ? 'Redimido' : 'No redimido'} <br></br>
                                        </Header.Subheader>
                                        :
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input 
                                                            label='Link de la inspeccion'
                                                            onChange={onChageDatosRedencion}
                                                            value={redencion.linkInspeccion}
                                                            name="linkInspeccion"
                                                            type='text' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input 
                                                            label='Numero Orden'
                                                            onChange={onChageDatosRedencion}
                                                            value={redencion.numeroOrdenTrabajo}
                                                            name="numeroOrdenTrabajo"
                                                            type='text' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <Form>
                                                        <TextArea
                                                            rows={4}
                                                            placeholder='Comentarios...' 
                                                            onChange={onChageDatosRedencion}
                                                            value={redencion.comentarios}
                                                            name="comentarios"
                                                            type='text'
                                                        />
                                                    </Form>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>}
                                    </Segment>
                                </Grid.Column>
                                {!i.redencion.estatus?
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>-</Header>
                                        <Grid>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <Form.Group widths='equal'>
                                                        <Form.Input
                                                            size="small"
                                                            label='Fecha Entrega Vehiculo'
                                                            onChange={onChageDatosRedencion}
                                                            value={redencion.fechaEntregaVehiculo}
                                                            name='fechaEntregaVehiculo'
                                                            type='date' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Form.Group>
                                                        <Form.Input
                                                            label='Kilometraje' 
                                                            onChange={onChageDatosRedencion}
                                                            value={redencion.kilometrajeActual}
                                                            name="kilometrajeActual"
                                                            type='text' 
                                                            max={5} 
                                                        />
                                                    </Form.Group>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>: null}
                            </Grid.Row>
                            <Grid>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h4'>Precios</Header>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Prepagado</Header>
                                        <Header.Subheader>
                                            <strong>Insumos: </strong> {currencyFormatter.format(i.precios.prepagado.valoresFuturos[0].insumos, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>mano de obra: </strong> {currencyFormatter.format(i.precios.prepagado.valoresFuturos[0].manoObra, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Repuestos: </strong> {currencyFormatter.format(i.precios.prepagado.valoresFuturos[0].repuestos, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Comisión: </strong> {currencyFormatter.format(i.precios.prepagado.valoresFuturos[0].comision, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Iva: </strong> {currencyFormatter.format(i.precios.prepagado.valoresFuturos[0].iva, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Total: </strong> {currencyFormatter.format(i.precios.prepagado.valoresFuturos[0].valor, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                {/* <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Ordinario</Header>
                                        <Header.Subheader>
                                            <strong>Insumos: </strong> {currencyFormatter.format(i.precios.ordinario.insumos, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>mano de obra: </strong> {currencyFormatter.format(i.precios.ordinario.manoObra, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Repuestos: </strong> {currencyFormatter.format(i.precios.ordinario.repuestos, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Iva: </strong> {currencyFormatter.format(i.precios.ordinario.iva, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Total: </strong> {currencyFormatter.format(i.precios.ordinario.total, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column> */}
                            </Grid.Row>
                        </Grid>

                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h4'>Detalle operaciones</Header>
                                    </Segment>
                                    {renderDetalleOperaciones(i.detalleOperaciones)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </Segment>
            </Grid.Column>
            )
        } else {
            return <NoItems />
        }
    }

    const renderDetalleOperaciones = ( detalleOperaciones ) => {
        if ( detalleOperaciones && detalleOperaciones.length > 0 ) {
            return detalleOperaciones.map( (i) => {
                return <Segment key={i._id}>
                    <Header as='h5'>
                        <Icon size='tiny' name='tag' />
                        <Header.Content>{i.nombre}</Header.Content>
                        <Header.Subheader>
                            <Label size='tiny' >Codigo: {i.codigo}</Label>
                            <Label size='tiny' >Horas: {i.horas}</Label>
                        </Header.Subheader>
                    </Header>
                    {i.repuestos.length > 0 ? <Table celled size='small' striped structured selectable textAlign='center'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan={2}>Referencia</Table.HeaderCell>
                                <Table.HeaderCell rowSpan={2}>PNC</Table.HeaderCell>
                                <Table.HeaderCell rowSpan={2}>Cantidad</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>Margen</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} >Precio venta</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Publico</Table.HeaderCell>
                                <Table.HeaderCell>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Publico</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {i.repuestos.map( (j) => {
                                return (
                                <Table.Row key={j.referencia}>
                                    <Table.Cell>{j.referencia}</Table.Cell>
                                    <Table.Cell>{j.pnc}</Table.Cell>
                                    <Table.Cell>{j.cantidad}</Table.Cell>
                                    <Table.Cell>{j.margen.precioVentaConcesionario}</Table.Cell>
                                    <Table.Cell>{j.margen.precioVentaPublico}</Table.Cell>
                                    <Table.Cell>{j.precioVenta.concesionario}</Table.Cell>
                                    <Table.Cell>{j.precioVenta.publico}</Table.Cell>
                                </Table.Row>
                                )

                            })}
                        </Table.Body>
                    </Table>: null}
                </Segment>
            } )
        }
    }

    return ( 
        <Fragment>
            {renderServicios()}
        </Fragment>
    );

}

 
export default DatosServicio;