import { CERRAR_SESION } from '../../tipos/usuarios';
import { clienteAxios } from '../../config/axios';

export const iniciarSesion = async(payload) => {
    try {
        const respuesta = await clienteAxios.post('/autenticar/iniciarSesion', payload)
        const {token, usuario, estatus, resultadoOperacion} = respuesta.data
        return {token, usuario, estatus, resultadoOperacion}
    } catch (error) {
        console.log(error)
    }
}

export function cerrarSesion() {  
    return async(dispatch) => {
        localStorage.removeItem('token')
        localStorage.removeItem('rol')
        localStorage.removeItem('nombreUsuario')
        dispatch(removerToken())
    }
}

const removerToken = () => ({
    type: CERRAR_SESION
})

