import React, {useState, useEffect} from 'react'
import { estadoInicialFiltro } from '../../estadosIniciales/tempario/estadoInicial-tempario';
import {Grid, Segment, Button, Form} from 'semantic-ui-react'
import { TIPO_ROL } from '../../enum/tipo-rol';


const FiltroConcesiones = ({setFiltro}) => {

    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ opciones, setOpciones ] = useState([])
    const usuario = localStorage.getItem('usuario')
    const rol = localStorage.getItem('rol')

    useEffect(() => {
        const datosUsuario = JSON.parse(usuario)
        let opcionesConcesiones = []
        if(usuario){
           datosUsuario.concesiones.map(i => {
                opcionesConcesiones.push({
                    value: i,
                    text: i,
                    key: i
                })
            })
            setOpciones(opcionesConcesiones)
        } 
    }, [usuario])
    
    const onChangeDatosFiltrado = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]: e.target.value
        })
    }

    const onChangeDatosFiltradoCl = (e, {name, value}) => {
        setDatosFiltrado({
            ...datosFiltrado,
            cl: [value]
        })
    }

    const onChangeClInput = e => {
        if(e){
            setDatosFiltrado({
                ...datosFiltrado,
                cl: e.target.value.split(',')
            })
        }
    }

    const buscarTemparios = () => {
        if(datosFiltrado.cl[0] === ""){
            setDatosFiltrado({
                ...datosFiltrado,
                cl: []
            })

        } else {
            setFiltro(datosFiltrado)
        }
        return
    }
            

            
    return ( 
        <Grid style={{ marginTop: 5}}>
            <Grid.Column>
                <Segment
                    size="mini"
                >
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Codigo del Modelo"
                                name="codigoModelo"
                                value={datosFiltrado.codigoModelo}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Nombre del Tempario"
                                name="nombre"
                                value={datosFiltrado.nombre}
                                onChange={onChangeDatosFiltrado}
                            />
                            {rol === TIPO_ROL.ADMINISTRADOR_SISTEMA || rol === TIPO_ROL.ADMINISTRADOR_SISTEMA 
                            ? 
                            <Form.Input
                                label="Concesiones (cl)"
                                name="cl"
                                value={datosFiltrado.cl}
                                onChange={onChangeClInput}
                            />
                            :
                            <Form.Select
                                clearable
                                label="Concesiones (cl)"
                                name="cl"
                                options={opciones}
                                value={datosFiltrado.cl[0]}
                                onChange={onChangeDatosFiltradoCl}
                            />
                            }

                        </Form.Group>
                            <span>
                                <Button
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="mini"
                                    icon="search"
                                    content="BUSCAR"
                                    onClick={() => buscarTemparios()}
                                />
                            </span>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
     );
}
 
export default FiltroConcesiones;