import { clienteAxios } from '../../config/axios';
import tokenAuth from '../../config/tokenAuth';

const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const consultaServicios = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('servicio/consulta', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaServicioId = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('servicio/consultaId', payload)
        return(respuesta)
    } catch( error ) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const crearServicios = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/servicio/crear', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarServicios = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/servicio/eliminar', payload)
        return(respuesta) 
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarServicios = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/servicio/actualizar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const eliminarOperacionDeServicio = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/servicio/eliminar-operacion', payload)
        return(respuesta)
    } catch (error) {

        console.log('Error: ', JSON.stringify(error))
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}