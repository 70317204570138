import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import imgBackGround from '../../assets/img/bg/backgroundhome.jpg';
import {Link} from 'react-router-dom';
import PresentacionModeloIC from '../../vistas/presentacionModelos/PresentacionModeloIC';


const PrincipalUsuariosIC = () => {

    return(
        <Grid style={{backgroundColor:"light"}}>
            <Grid.Row 
                columns={2}
                style={{ 
                    minHeight:"70vh", 
                    backgroundImage:`url(${imgBackGround})`, 
                    backgroundSize: 'cover', 
                    backgroundRepeat: 'no-repeat', 
                    backgroundPosition: 'center'}}>
                <Grid.Column verticalAlign="bottom">
                    <Segment basic style={{
                        marginLeft:40
                    }}>
                        <Header 
                            size="huge" 
                            as="h1" 
                            style={{ 
                                fontSize:50,
                                spacing:'-0.04em',
                                fontWeight:'400', 
                                fontFamily:'kiaBold', 
                                marginBottom:20, 
                                color: '#FFFFFF',
                                marginTop:30 }}>
                            
                            <Header.Subheader style={{ 
                                fontSize:30,
                                spacing:'-0.04em',
                                fontWeight:'400', 
                                fontFamily:'kiaBold', 
                                marginBottom:20, 
                                color: '#FFFFFF'}}>
                                Mantenimiento Prepagado
                            </Header.Subheader>
                            <Header.Content>
                                Beneficios para ti <br></br>
                                y tu vehículo
                            </Header.Content>
                        </Header>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1} style={{ marginLeft: 40, marginRight: 40, marginTop: 40 }}>
                <Grid.Column verticalAlign="middle" textAlign="right">
                    <Grid.Row textAlign="right" verticalAlign="middle">

                        {/* <a style={{
                            textDecoration: 'none',
                            borderColor:'#666666',
                            borderBottomStyle:'solid',
                            fontFamily: 'kiaBold',
                            color:'#000000',
                            marginRight: 40
                        }}>Mas información</a> */}

                        <Link 
                            primary='true'
                            style={{
                                padding: 20,
                                borderRadius: 0, 
                                backgroundColor:'#000000',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#FFFFFF' }}
                            to={'/quotation'}
                            content="Mas informacion"  >
                                Mas información
                            </Link>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={1}>
                {PresentacionModeloIC()}
            </Grid.Row>
            
        </Grid>
    )
}

export default PrincipalUsuariosIC;