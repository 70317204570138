import React from 'react';
import { Modal, Button, Header, Grid, Item, Segment } from 'semantic-ui-react';


const ModalSeleccionarVin = ({
    seleccionarVehiculo,
    vehiculos,
    agregarVehiculoSolicitud,
    setSeleccionarVehiculo
}) => {
    
    const selectVehiculo = (item) => {
        agregarVehiculoSolicitud({
            placa: item.des_placa,
            vin: item.vin_completo,            
            version: item.cod_version,
            descripcionVersion: item.des_version,
            modelo: {
                origen: item.des_pais_origen,
                color: item.des_color,
                anoModelo: item.des_ano_modelo.toString(),
                codigo: item.cod_modelo_pos,
                nombre: item.des_modelo_imp,
            }
        })
    }

    console.log('vehiculo', vehiculos);
    
    return(
        
        <Modal
            onClose={() => setSeleccionarVehiculo(false)}
            open={seleccionarVehiculo}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                Seleccione un vehiculo
                </Header>
            </Modal.Header>
            <Modal.Content>
                <Grid columns={3} stretched>
                {vehiculos.length>0?
                    
                    vehiculos.map((i, index) => (
                        
                        <Grid.Column key={i.vin_completo+index}>
                        <Segment>
                        <Grid.Column>
                            <Item.Content>
                                <small>Nombre</small>
                                <Item.Meta> 
                                <label style={{fontWeight: "bold", textTransform: 'uppercase'}}> {i.des_modelo_imp} </label> 
                                </Item.Meta>
                            </Item.Content>
                        </Grid.Column>
                        <Grid.Column>
                            <Item.Content>
                                <small>Vin</small>
                                <Item.Meta> 
                                <label style={{fontWeight: "bold", textTransform: 'uppercase'}}> {i.vin_completo} </label> 
                                </Item.Meta>
                            </Item.Content>
                        </Grid.Column>
                        <Grid.Column>
                            <Item.Content>
                                <small>Código Versión</small>
                                <Item.Meta> 
                                <label style={{fontWeight: "bold", textTransform: 'uppercase'}}> {i.cod_version} </label> 
                                </Item.Meta>
                            </Item.Content>
                        </Grid.Column>
                        <Grid.Column>
                            <Item.Content>
                                <small>Descripcion</small>
                                <Item.Meta> 
                                <label style={{fontWeight: "bold", textTransform: 'uppercase'}}> {i.des_version} </label> 
                                </Item.Meta>
                            </Item.Content>
                        </Grid.Column>
                        <Grid.Column>
                            <Item.Content>
                                <small>Color</small>
                                <Item.Meta> 
                                <label style={{fontWeight: "bold", textTransform: 'uppercase'}}> {i.des_color} </label> 
                                </Item.Meta>
                            </Item.Content>
                        </Grid.Column>
                        <Button
                            icon="check"
                            circular
                            style={{
                                padding: 10,
                                backgroundColor:'#FFFFFF',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#000000'
                            }}
                            size="mini"
                            floated="right"
                            primary
                            onClick={() => selectVehiculo(i)}
                        />
                        </Segment>
                        </Grid.Column>
                    ))
                    :
                'No hay ningun vehiculo asociado a este vin'
                }
                </Grid>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                <Button
                    size="tiny"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    onClick={() => setSeleccionarVehiculo(false)}
                    content="Cerrar"
                />
            </Modal.Actions>
        </Modal>
    )
}

export default ModalSeleccionarVin;