import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Header, Container } from 'semantic-ui-react';
import { consultaPaquetePorVersion } from '../../servicios/paquetes/paquetes.srv';
import SeleccionPaquete from '../../components/tarjetas/presentacionModelos/seleccionPaquete';
import Preloader from '../../components/utilidades/preloader';

const VistaPresentacionPaquetes = () => {
    // eslint-disable-next-line
    const [parametros, setParametros] = useState(useParams())
    const [paquetes, setPaquetes] = useState([])
    // eslint-disable-next-line
    const [imagenes, setImagenes] = useState({})
    // eslint-disable-next-line
    const [modelo, setModelo] = useState([])
    const [cargando, setCargando] = useState( true )

    useEffect( ()=> {
        if ( parametros.version) {
            consultaPaquetes()
        }
    }, [parametros.version])

    const consultaPaquetes = async () => {
        const respuesta = await consultaPaquetePorVersion( parametros )
        const data = respuesta.data

        if ( data.estatus ) {
            setPaquetes(data.data.paquetes)
            setModelo(data.data.modelo)
            setImagenes(data.data.imagenes)
            setCargando(false)
        } else {
            setCargando(false)
        }
    }

    console.log(paquetes)

    if (cargando) {
        return <Preloader />
    }

    return (
        <Grid stackable centered style={{marginTop:100, marginBottom: 0, marginLeft:50, marginRight:50  }}>
            <Container fluid>
                <Header 
                    as="h1"
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'300', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                        <Header.Content>
                            Paquetes prepagados
                        </Header.Content>
                        <Header.Subheader>
                            Version seleccionada: {parametros.version}
                        </Header.Subheader>
                </Header>

                {/* <Form.Field>
                    
                </Form.Field> */}
                {/* <Select options={operacionesListado} style={{ borderRadius:0, padding:15 }} placeholder="Tipo de paquete"></Select> */}
            </Container>

            <Grid.Row columns={3} style={{ marginTop:40, marginBottom:40}}>
                { paquetes.map( (i, index) => (
                    <SeleccionPaquete paquete={i} key={index} />
                ) )}
            </Grid.Row>
        </Grid>
    )
}

export default VistaPresentacionPaquetes