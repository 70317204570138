import React, {useState, useEffect} from 'react';
import {actualizarPaquete, consultaPaqueteId} from '../../servicios/paquetes/paquetes.srv';
import {EVENTO_EDITAR_DESDE_DETALLES} from '../../enum/eventos/tipo-evento';
import { Grid, Form, Button, Table, Icon, Container, Header, Segment, Label } from 'semantic-ui-react';
import { useParams, Link } from 'react-router-dom';
import {tipoPaquete} from '../../estadosIniciales/listas/paquete-listas';
import {estadoInicialPaquete} from '../../estadosIniciales/paquetes/estadoInicial-paquetes';
import {estadoSolicitudesLista, paqueteActivo} from '../../estadosIniciales/listas/estadoSolicitud-lista';
import moment from 'moment-timezone'
import ModalMensaje from '../../components/modales/modalMensaje';
import ConsultaModalServicios from '../../components/modales/modalSeleccionServicios';
import ItemServicio from '../../components/itemsListados/item-servicio';
import NoItems from '../../components/utilidades/noItems';
import currencyFormatter from 'currency-formatter'


const DetallesPaquetes = () => {
    
    let id = useParams()
    const [ datosPaquete, setDatosPaquete ] = useState(Object.assign({}, estadoInicialPaquete))
    const [ datosServicios, setDatosServicios] = useState([])
    // eslint-disable-next-line
    const [ evento, setEvento ] = useState(EVENTO_EDITAR_DESDE_DETALLES)
    const [ modalServicios, setModalServicios ] = useState(false)
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    const consultar = async(id) => {
        const respuesta = await consultaPaqueteId(id)
        if(respuesta.data.estatus){
            setDatosPaquete(respuesta.data.data.paquete)
            setDatosServicios(respuesta.data.data.servicios)
        } else {
            setModalMensajeEstatus({
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
        
    }
    
    useEffect(() => {
        consultar(id)
    }, [id])

    const eliminarServicio = (index) => {
        let servicio = datosPaquete.servicios
        let detalleSer = datosPaquete.detallesServicios
        detalleSer.splice(index, 1)
        servicio.splice(index, 1)
        setDatosPaquete({
            ...datosPaquete,
            servicios:servicio
        })
        setDatosPaquete({
            ...datosPaquete,
            detallesServicios:detalleSer
        })
    }

    const ActualizarPaquete = async(payload) => {

        const respuesta = await actualizarPaquete(payload)
        if(respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(id)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
    }

    const onChangeDatosPaquete = (e, {name, value}) => {
        setDatosPaquete({
            ...datosPaquete,
            [name]:value
        })
    }
    const onChangeDatosModelo = e => {
        setDatosPaquete({
            ...datosPaquete,
            modelo:{
                ...datosPaquete.modelo,
                [e.target.name]:e.target.value
            }
        })
    }

    const onChangeDatosEstatus = (e, {name, value}) => {
        setDatosPaquete({
            ...datosPaquete,
            estatus:{
                ...datosPaquete.estatus,
                fecha: moment().format(),
                nombre: value
            }
        })
    }

    const renderServicios = () => {
        if ( datosServicios && datosServicios.length > 0) {
            return datosServicios.map( (i) => {
                return <Grid.Column key={i._id}> 
                    <Segment>
                        <Header as='h1'>
                            {i.nombre}
                            <Header.Subheader>
                                {i.descripcion} <Label size='tiny'>{i.tipo}</Label>
                            </Header.Subheader>
                        </Header>

                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Frecuencia</Header>
                                        <Header.Subheader>
                                            <strong>Kilometraje: </strong> {currencyFormatter.format(i.frecuencia.kilometraje, { precision:0, thousand:'.'})} <br></br>
                                            <strong>Meses: </strong> {i.frecuencia.meses} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                
                                <Grid.Column>
                                    <Segment basic >
                                        <Header as='h5'>Vigencia</Header>
                                        <Header.Subheader>
                                            <strong>Kilometraje: </strong> {currencyFormatter.format(i.vigencia.kilometraje, { precision:0, thousand:'.'})} <br></br>
                                            <strong>Meses: </strong> {i.vigencia.meses} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>

                        <Grid>
                            
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>Precio Repuestos</Header>
                                        <Header.Subheader>
                                            <strong>Publico: </strong> {currencyFormatter.format(i.preciosRepuestos.publico, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Concesionario: </strong> {currencyFormatter.format(i.preciosRepuestos.concesionario, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Descuentos: </strong> {currencyFormatter.format(i.preciosRepuestos.descuento, { precision: 0})} % <br></br>
                                            <strong>Publico despues de descuento: </strong> {currencyFormatter.format(i.preciosRepuestos.publicoDespuesDescuento, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                            <strong>Concesionario despues de descuento: </strong> {currencyFormatter.format(i.preciosRepuestos.concesionarioDespuesDescuento, { precision:0, thousand:'.', symbol:'$ '})} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>Mano de Obra</Header>
                                        <Header.Subheader>
                                            <strong>Horas: </strong> {i.preciosManoObra.horas} <br></br>
                                            <strong>Valor horas: </strong> {i.preciosManoObra.valorHoras} <br></br>
                                            <strong>Valor horas despues de factor: </strong> {i.preciosManoObra.valorHorasDespuesFactor} <br></br>
                                        </Header.Subheader>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Segment basic>
                                        <Header as='h5'>Detalle operaciones</Header>
                                    </Segment>
                                    {renderDetalleOperaciones(i.detalleOperaciones)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </Segment>
            </Grid.Column>
            } )
        } else {
            return <NoItems />
        }
    }

    const renderDetalleOperaciones = ( detalleOperaciones ) => {
        if ( detalleOperaciones && detalleOperaciones.length > 0 ) {
            return detalleOperaciones.map( (i) => {
                return <Segment key={i._id}>
                    <Header as='h5'>
                        <Icon size='tiny' name='tag' />
                        <Header.Content>{i.nombre}</Header.Content>
                        <Header.Subheader>
                            <Label size='tiny' >Codigo: {i.codigo}</Label>
                            <Label size='tiny' >Horas: {i.horas}</Label>
                        </Header.Subheader>
                    </Header>
                    {i.repuestos.length>0? <Table celled size='small' striped structured selectable textAlign='center'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan={2}>Referencia</Table.HeaderCell>
                                <Table.HeaderCell rowSpan={2}>PNC</Table.HeaderCell>
                                <Table.HeaderCell rowSpan={2}>Cantidad</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2}>Margen</Table.HeaderCell>
                                <Table.HeaderCell colSpan={2} >Precio venta</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Publico</Table.HeaderCell>
                                <Table.HeaderCell>Concesionario</Table.HeaderCell>
                                <Table.HeaderCell>Publico</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {i.repuestos.map( (j) => {
                                return <Table.Row key={j.referencia}>
                                    <Table.Cell>{j.referencia}</Table.Cell>
                                    <Table.Cell>{j.pnc}</Table.Cell>
                                    <Table.Cell>{j.cantidad}</Table.Cell>
                                    <Table.Cell>{j.margen.precioVentaConcesionario}</Table.Cell>
                                    <Table.Cell>{j.margen.precioVentaPublico}</Table.Cell>
                                    <Table.Cell>{j.precioVenta.concesionario}</Table.Cell>
                                    <Table.Cell>{j.precioVenta.publico}</Table.Cell>
                                </Table.Row>
                            })}
                        </Table.Body>
                    </Table>: null}
                </Segment>
            } )
        }
    }

    return(
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid>
            <Header 
                size="huge" 
                as="h1" 
                style={{ 
                    fontSize:50,
                    spacing:'-0.04em',
                    fontWeight:'400', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:30 }}>
                Edición de {datosPaquete.nombre}
                <span>
                <Button 
                    size="mini"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    floated='right'
                > 
                    <Link 
                        style={{
                            textDecoration: 'none',
                            borderColor:'#666666',
                            fontFamily: 'kiaBold',
                            color:'#000000'
                        }}
                        primary
                        size="tiny"
                        to={'/paquetes'}
                        >  
                            <Icon name="chevron left" />
                            Volver 
                    </Link>
            </Button>
                </span>
            </Header>
        <Segment 
            basic
        >
            <Form>
                <h3>Información Del Paquete</h3>
                <Form.Group widths="equal">
                    <Form.Input
                        label="Nombre"
                        name="nombre"
                        value={datosPaquete.nombre}
                        onChange={onChangeDatosPaquete}
                    />
                    <Form.Input
                        label="Descripcion"
                        name="descripcion"
                        value={datosPaquete.descripcion}
                        onChange={onChangeDatosPaquete}
                    />
                    <Form.Input
                        label="Codigo"
                        name="codigoPaquete"
                        value={datosPaquete.codigoPaquete}
                        onChange={onChangeDatosPaquete}
                    />
                    <Form.Select
                        label="tipo"
                        name="tipoPaquete"
                        options={tipoPaquete}
                        value={datosPaquete.tipoPaquete}
                        onChange={onChangeDatosPaquete}
                    />
                </Form.Group>
                <h3>Modelo Al Que Aplica</h3>
                <Form.Group widths="equal">
                    <Form.Input
                        label="Codigo"
                        value={datosPaquete.modelo.codigo}
                        name="codigo"
                        width="7"
                        onChange={onChangeDatosModelo}
                    />
                    <Form.Input
                        label="Descripcion"
                        value={datosPaquete.modelo.descripcion}
                        name="descripcion"
                        onChange={onChangeDatosModelo}
                    />
                    <Form.Input
                        name="version"
                        value={datosPaquete.modelo.version}
                        label="Versión"
                        onChange={onChangeDatosModelo}
                    />
                </Form.Group>
                <h3>Estado</h3>
                <Form.Group>
                    <Form.Select
                        label="Estado del Paquete"
                        value={datosPaquete.estatus.nombre}
                        name="nombre"
                        options={estadoSolicitudesLista}
                        width="4"
                        onChange={onChangeDatosEstatus}
                    />
                    <Form.Select
                        label="Activación"
                        value={datosPaquete.activo}
                        name="activo"
                        options={paqueteActivo}
                        width="4"
                        onChange={onChangeDatosPaquete}
                    />
                    
                </Form.Group>
            </Form>
            </Segment>
            {datosPaquete.detallesServicios !== undefined && datosPaquete.detallesServicios.length>0?
                    <Table size="small" singleLine celled textAlign="center">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">CODIGO</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">HORAS</Table.HeaderCell>
                                <Table.HeaderCell colSpan="4">APLICA PARA</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>AIRE</Table.HeaderCell>
                                <Table.HeaderCell>COMBUSTIBLE</Table.HeaderCell>
                                <Table.HeaderCell>TRANSMISION</Table.HeaderCell>
                                <Table.HeaderCell>CILINDRADA</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {datosPaquete.detallesServicios.map((i, index) => (
                                <ItemServicio
                                    eliminarServicio={(index) => eliminarServicio(index)}
                                    index={index}
                                    evento={evento}
                                    key={i.nombre}
                                    item={i}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                : null}
        
        <Grid.Row columns={1}>
            <Grid.Column>
                <Segment basic>
                    <Button
                        content="Agregar servicio"
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#FFFFFF',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#000000'
                        }}
                        primary
                        size="tiny"
                        onClick={() => setModalServicios(true)}
                    />
                    <Button 
                        style={{
                            padding: 20,
                            borderRadius: 0, 
                            backgroundColor:'#000000',
                            borderColor: '#000000',
                            borderStyle:'solid',
                            borderWidth:'1px',
                            fontWeight: 'bold',
                            color:'#FFFFFF'
                        }}
                        primary
                        size="tiny" 
                        content="Actualizar"
                        onClick={() => ActualizarPaquete(datosPaquete)}
                    />
                </Segment>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
            {renderServicios()}
        </Grid.Row>
    </Container>
        <ModalMensaje
            activo={modalMensajeEstatus.activo}
            mensaje={modalMensajeEstatus.mensaje}
            setModalMensajeEstatus={setModalMensajeEstatus}
        />
        <ConsultaModalServicios
            datosPaquete={datosPaquete}
            modalServicios={modalServicios}
            actualizar={(payload) => ActualizarPaquete(payload)}
            setModalServicios={setModalServicios}
            setDatosPaquete={setDatosPaquete}
        />
    </Grid>
    )
}

export default DetallesPaquetes;