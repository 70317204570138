import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Button } from 'semantic-ui-react';

const ItemUsuario = ({
    usuario,
    setModalActivo,
    setEvento,
    setModalEliminar,
    setObjetoEliminar,
    setUsuarioSeleccionado
}) => {
    const datosInicialUsuario = {
        _id: usuario._id,
        contrasena: usuario.contrasena,
        concesiones: usuario.concesiones,
        activo: usuario.activo,
        rol: usuario.rol,
        cargo: usuario.cargo,
        grupo: usuario.grupo,
        email: usuario.email,
        nombre: usuario.nombre,
        identificacion: usuario.identificacion,
        permisos: usuario.permisos
    }

    
    const seleccionarUsuario = () => {
        setEvento(EVENTO_ACTUALIZAR)
        setUsuarioSeleccionado( datosInicialUsuario )
        setModalActivo(true)
        return;
    }

    const botonEliminar = (id) => {
        setModalEliminar({
            activo: true
        })
        setObjetoEliminar(id)
    }

    return ( 
        <Table.Row>
            <Table.Cell> {usuario.nombre.nombres}{' '}{usuario.nombre.apellidos} </Table.Cell>
            <Table.Cell> {usuario.email} </Table.Cell>
            <Table.Cell> {usuario.cargo} </Table.Cell>
            <Table.Cell> {usuario.rol} </Table.Cell>
            <Table.Cell> { usuario.concesiones.length === 0 ? <p>Todos</p> : usuario.concesiones.length}</Table.Cell>
            <Table.Cell>
                <Button
                    content="EDITAR"
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                    size="tiny"
                    onClick={()=> seleccionarUsuario() }
                /> 
                <Button
                content="ELIMINAR"
                icon="trash"
                style={{borderRadius:25}}
                size="tiny"
                onClick={() => botonEliminar(usuario._id) }
                /> 
            </Table.Cell>
        </Table.Row>
     );
}

export default ItemUsuario;