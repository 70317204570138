import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Icon } from 'semantic-ui-react';
import { estadoInicialPrepagado } from '../../estadosIniciales/prepagado/estadoInicial-prepagado';
import Mensaje from '../../components/alertas/alertas.camposObligatorios';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';


const FormularioPrepagado = ({
    actualizar,
    guardar,
    setModalActivo,
    setEvento,
    prepagado,
    evento,
    modalActivo
}) => {

    const [ datosPrepagado, setDatosPrepagado ] = useState(Object.assign({}, estadoInicialPrepagado))
    const [ mensaje, setMensaje ] = useState(false)


    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosPrepagado(prepagado)
        } else {
            setDatosPrepagado(Object.assign({}, estadoInicialPrepagado))
        }
    }, [evento])
    const onChangeDatos = e => {
        const value = parseFloat(e.target.value)
        setDatosPrepagado({
            ...datosPrepagado,
            [e.target.name]: value
        })
    }
    const onChangeDescuentos = e => {
        const value = parseFloat(e.target.value)
        setDatosPrepagado({
            ...datosPrepagado,
            descuentos: {
                ...datosPrepagado.descuentos,
                [e.target.name]:value
            }
        })
    }

    const guardarPrepagado = (payload) => {
        if(
            datosPrepagado.hora === ''||
            datosPrepagado.rutina === ''||
            datosPrepagado.descuentos.repuestosConcesionario === ''||
            datosPrepagado.descuentos.repuestosImportadora === ''||
            datosPrepagado.descuentos.totalDescuento === ''
        ){
            setMensaje(true)
            return
        }
        guardar(payload)
    }

    const actualizarPrepagado = (payload) => {
        if(
            datosPrepagado.hora === ''||
            datosPrepagado.rutina === ''||
            datosPrepagado.descuentos.repuestosConcesionario === ''||
            datosPrepagado.descuentos.repuestosImportadora === ''||
            datosPrepagado.descuentos.totalDescuento === ''
        ){
            setMensaje(true)
            return
        }
        actualizar(payload)
    }

    const desactivarModal = () => {
        setMensaje(false)
        setModalActivo(false)
        setEvento('')
    }

    return ( 
        <Modal 
            open={modalActivo}
        >
            <Modal.Header> <Icon name="edit" color="red"/> Formulario Prepagado </Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Datos De Rutina</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Rutina"
                            type="number"
                            placeholder="Numero de la rutina Ej. 5000"
                            name="rutina"
                            value={datosPrepagado.rutina}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Horas"
                            type="number"
                            name="hora"
                            value={datosPrepagado.hora}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <h3>Descuentos</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Repuestos De Importadora"
                            type="number"
                            name="repuestosImportadora"
                            value={datosPrepagado.descuentos.repuestosImportadora}
                            onChange={onChangeDescuentos}
                        />
                        <Form.Input
                            label="Repuestos De Concesionario"
                            type="number"
                            name="repuestosConcesionario"
                            value={datosPrepagado.descuentos.repuestosConcesionario}
                            onChange={onChangeDescuentos}

                        />
                        <Form.Input
                            label="Total Descuento"
                            type="number"
                            name="totalDescuento"
                            value={datosPrepagado.descuentos.totalDescuento}
                            onChange={onChangeDescuentos}
                        />
                    </Form.Group>
                </Form>
                {mensaje ? <Mensaje/> : null}
            </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={desactivarModal}
                    />
                    {evento === EVENTO_ACTUALIZAR ?
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={() =>  actualizarPrepagado(datosPrepagado)}
                        icon="save"
                    />
                    :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR" 
                        onClick={() => guardarPrepagado(datosPrepagado)}
                        icon="save"
                    />
                    }

                </Modal.Actions>
        </Modal>
     );
}
 
export default FormularioPrepagado;