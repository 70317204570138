export const estadoInicialTempario = {
    cl: '',
    codigoModelo: '',
    nombre: '',
    insumos: '',
    manoObra: '',
    rutinaMantenimiento: {
        kilometros: '',
        horas: ''
    }

}

export const estadoInicialFiltro = {
    cl: [],
    codigoModelo: "",
    nombre: "",
    salto: 0,
    limite: 10
}