import React, {useState, useEffect} from 'react';
import { EVENTO_ACTUALIZAR }  from '../../enum/eventos/tipo-evento'
import {estadoInicialVariableCalculo} from '../../estadosIniciales/variableCalculo/estadoInicial.variableCalculo';
import { Form, Button, Icon, Modal, Checkbox } from 'semantic-ui-react';
import { tipoValorOpciones } from '../../estadosIniciales/listas/valor-lista';
import { tipoAplicaParaOpciones } from '../../estadosIniciales/listas/aplicaPara-lista';
import Mensaje from '../alertas/alertas.camposObligatorios';




const FormularioVariablesCalculo = ({
    actualizar,
    guardar,
    setEvento,
    setModalActivo,
    modalActivo,
    variable,
    evento
}) => {
    
    const [ datosVariablesCalculo, setDatosVariablesCalculo ] = useState(Object.assign({}, estadoInicialVariableCalculo))
    const [ mensaje, setMensaje ] = useState(false)

    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosVariablesCalculo(variable)
        } else {
            setDatosVariablesCalculo(Object.assign({}, estadoInicialVariableCalculo))
        }
    }, [evento])

    const onChangeDatos = (e, {name,value}) => {
        setDatosVariablesCalculo({
            ...datosVariablesCalculo,
            [name]:value
        })
    }
    const onChangePrecedencia = e => {
        const value = parseFloat(e.target.value)
        setDatosVariablesCalculo({
            ...datosVariablesCalculo,
            [e.target.name]:value
        })
    }
    const onChangeValorN = e => {
        setDatosVariablesCalculo({
            ...datosVariablesCalculo,
            valor: {
                ...datosVariablesCalculo.valor,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeValorT = (e, {name, value}) => {
        setDatosVariablesCalculo({
            ...datosVariablesCalculo,
            valor: {
                ...datosVariablesCalculo.valor,
                [name]:value
            }
        })
    }

    const buttonActivo = () => {
        setDatosVariablesCalculo({
            ...datosVariablesCalculo,
            activo: !datosVariablesCalculo.activo
        })
    }

    const desactivarModal = () => {
        setModalActivo(false)
        setEvento('')
        setMensaje(false)
    }

    const crearVariables = (payload) => {
        if(
            datosVariablesCalculo.nombre.trim() === '' ||
            datosVariablesCalculo.descripcion.trim() === '' ||
            datosVariablesCalculo.precedencia === '' ||
            datosVariablesCalculo.aplicaPara.trim() === '' ||
            datosVariablesCalculo.valor.tipo.trim() === '' ||
            datosVariablesCalculo.valor.numero === '' 
        ) {
            setMensaje(true)
            return;
        }
        guardar(payload)
    }

    return (    
        <Modal
            open={modalActivo}
        >
            <Modal.Header> <Icon name="edit" color="red"/> Formulario Variables De Calculo </Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Descripcion</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosVariablesCalculo.nombre}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            label="Descripcion"
                            name="descripcion"
                            value={datosVariablesCalculo.descripcion}
                            onChange={onChangeDatos}
                        />
                    </Form.Group>
                    <h3>Datos</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            type="number"
                            label="Valor"
                            name="numero"
                            value={datosVariablesCalculo.valor.numero}
                            onChange={onChangeValorN}
                        />
                        <Form.Select
                            label="Tipo"
                            name="tipo"
                            options={tipoValorOpciones}
                            value={datosVariablesCalculo.valor.tipo}
                            onChange={onChangeValorT}
                        />
                        <Form.Select
                            label="Aplica Para"
                            options={tipoAplicaParaOpciones}
                            name="aplicaPara"
                            value={datosVariablesCalculo.aplicaPara}
                            onChange={onChangeDatos}
                        />
                        <Form.Input
                            type="number"
                            label="Precedencia"
                            name="precedencia"
                            value={datosVariablesCalculo.precedencia}
                            onChange={onChangePrecedencia}
                        />
                    </Form.Group>
                    <h3>Activacion De Variable</h3>
                    <Checkbox 
                        label="Si la variable esta desactivada, no estará disponible en la oferta comercial"
                        onChange={() => buttonActivo()}
                        checked={datosVariablesCalculo.activo}
                        toggle 
                    />
                </Form>
                { mensaje ? <Mensaje /> : null}
            </Modal.Content>
                <Modal.Actions> 
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => desactivarModal()}
                    />

                    {evento === EVENTO_ACTUALIZAR 
                    ? 
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={() => actualizar(datosVariablesCalculo)}
                        icon="save"
                    /> 
                    : 
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR" 
                        onClick={() => crearVariables(datosVariablesCalculo)}
                        icon="save"
                    />
                    }
                </Modal.Actions>
        </Modal>
     );
}
 
export default FormularioVariablesCalculo;