import {iniciarSesion} from '../servicios/usuarios/autenticacionUsuario.srv';
import { LOGIN_EXITOSO, LOGIN_ERROR, LOGIN_ERROR_ACEPTAR } from '../tipos/usuarios';



export function iniciarSesionAction(datos) {
    return async(dispatch) => {
        try {
            const respuesta = await iniciarSesion(datos)
            if(!respuesta) return;
            if(respuesta.estatus){
                const nombres = respuesta.usuario.nombre.nombres
                const apellidos = respuesta.usuario.nombre.apellidos
                const nombreUsuario = `${nombres} ${apellidos}`
                localStorage.setItem('token', respuesta.token)
                localStorage.setItem('rol', respuesta.usuario.rol)
                localStorage.setItem('nombreUsuario', nombreUsuario)
                localStorage.setItem('usuario', JSON.stringify(respuesta.usuario))
                dispatch(autenticarUsuario(respuesta.usuario))
                // return(respuesta)
            } else {
                dispatch(credencialesInvalidas(respuesta.resultadoOperacion))
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}

export const credencialesInvalidasAceptar = () => ({
    type: LOGIN_ERROR_ACEPTAR
})


const autenticarUsuario = (usuario) => ({
    type: LOGIN_EXITOSO,
    payload: usuario
})

const credencialesInvalidas = (mensaje) => ({
    type: LOGIN_ERROR,
    payload: mensaje
})


