import React from 'react';
import { Grid, Segment, Header, Button, Item } from 'semantic-ui-react';
import { VISTA_ACUERDO_SERVICIOS_PREPAGADOS } from '../../enum/eventos/tipo-evento';

const DatosCliente = ({
    datosCliente,
    setEditarCliente,
    evento
}) => {
    if ( !datosCliente ) {
        return null
    }
    return ( 
        <Grid.Column>
            <Segment basic>
                <Header as="h3">Datos del Cliente</Header>
                <Grid  columns={2} stackable>
                    <Grid.Column>
                        <Item.Content>
                            <small>Nombre</small>
                            <Item.Meta>
                              <label style={{fontWeight: "bold"}}>  {datosCliente?.nombre?.nombres}  {datosCliente?.nombre?.apellidos} </label>
                            </Item.Meta>
                                
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Dirección</small>
                            <Item.Meta> 
                               <label style={{fontWeight: "bold"}}>
                                   {datosCliente.direccion.callePrincipal} {''}
                                   {datosCliente.direccion.calleSecundaria} {''}
                                   {datosCliente.direccion.numero} {''}
                                   {datosCliente.direccion.departamento} {''} 
                                   {datosCliente.direccion.ciudad}
                                </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small> Email </small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosCliente.email} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Celular</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosCliente.telefonos.celular} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Fijo</small>
                            <Item.Meta>
                                <label style={{fontWeight: "bold"}}> {datosCliente.telefonos.fijo} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Otros</small>
                            <Item.Meta>
                                <label style={{fontWeight: "bold"}}> {datosCliente.telefonos.otros} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Tipo de Documento</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}} > {datosCliente.identificacion.tipo} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        <Item.Content>
                            <small>Número de Documento</small>
                            <Item.Meta>
                               <label style={{fontWeight: "bold"}}> {datosCliente.identificacion.numero} </label> 
                            </Item.Meta>
                        </Item.Content>
                    </Grid.Column>
                    <Grid.Column>
                        {evento !== VISTA_ACUERDO_SERVICIOS_PREPAGADOS ? <Button
                            content="Editar cliente"
                            style={{
                                padding: 20,
                                borderRadius: 0, 
                                backgroundColor:'#FFFFFF',
                                borderColor: '#000000',
                                borderStyle:'solid',
                                borderWidth:'1px',
                                fontWeight: 'bold',
                                color:'#000000'
                            }}
                            primary
                            onClick={() => setEditarCliente(true)}
                            size="tiny"
                        /> : null }
                    </Grid.Column>
                </Grid>
            </Segment>
        </Grid.Column>
     );
}
 
export default DatosCliente;