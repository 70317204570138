import React from 'react';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import { Table, Button, List } from 'semantic-ui-react';

const ItemTerminosCondiciones = ({
    setModalEliminar,
    setObjetoEliminar,
    setEvento,
    setModalActivo,
    setTermino,
    item
}) => {

    const datosInicialTermino = {
        _id: item._id,
        activo: item.activo,
        articulos: item.articulos,
        subtitulo: item.subtitulo,
        titulo: item.titulo,
        version: item.version,
        vigencia: item.vigencia
    }

    const botonActualizar = () => {
        setTermino(datosInicialTermino)
        setModalActivo(true)
        setEvento(EVENTO_ACTUALIZAR)
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        })
    }
    
    return ( 
        <Table.Row>
        <Table.Cell verticalAlign="top"> {item.titulo} </Table.Cell>
        <Table.Cell verticalAlign="top" width="3"> {item.subtitulo} </Table.Cell>
        <Table.Cell verticalAlign="top"> {item.version} </Table.Cell>
        <Table.Cell verticalAlign="top"> {item.vigencia} </Table.Cell>
        <Table.Cell> 
            {item.articulos.map((ar, index) => {
                return(
                <List key={`${ar.titulo}${index}`}>
                    <List.Header as="h5"> {ar.titulo} </List.Header>
                    <List.Header> {ar.subtitulo} </List.Header>
                    <List.Content> {ar.contenido} </List.Content>
                </List>
                )
            })}
        </Table.Cell>
        <Table.Cell>
            <Button
                content="EDITAR"
                onClick={botonActualizar}
                icon="edit"
                style={{marginBottom:4, backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                size="tiny"
            />
            <Button
                content="ELIMINAR"
                onClick={() => botonEliminar(item._id)}
                icon="trash"
                style={{marginTop:4,  borderRadius:25}}
                size="tiny"
            />
        </Table.Cell>
    </Table.Row>
     );
}
 
export default ItemTerminosCondiciones;