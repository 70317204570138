import React, {useState, useEffect, Fragment} from 'react';
import { Form, Button, Icon, Modal, Grid, Image, Segment, Checkbox, Label, Input } from 'semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file';
import { cargarArchivos } from '../../servicios/cargaArchivos/imagenesModelos.srv';
import { estadoInicialModelos, estadoInicialVersion } from '../../estadosIniciales/modelos/estadoInicial-modelos';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento'
import Mensaje from '../alertas/alertas.camposObligatorios';
import { cargarArchivoS3 } from '../../servicios/cargar-s3'


const FormularioModelos = ({
    actualizar,
    guardar,
    modelo,
    evento,
    modalActivo,
    setEvento,
    setModalActivo,
    setModalMensajeEstatus
}) => {


    const [ datosModelos, setDatosModelos ] = useState(Object.assign({}, estadoInicialModelos))
    const [ mensaje, setMensaje ] = useState(false)
    const [ version, setVersion ] = useState(Object.assign({}, estadoInicialVersion))
    const [ origen, setPais ] = useState('')
    const [ cargaImagenes, setCargaImaganes ] = useState({
        imagenSeleccionada: null,
        tamano: ''
    })

    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosModelos(modelo)
        } else {
            setDatosModelos(Object.assign({}, estadoInicialModelos))
        }
    }, [evento])

    const onChangeDatosVersion = e => {
        setVersion({
            ...version,
            [e.target.name]:e.target.value
        })
    }

    const onChangeDatosModelo = e => {
        setDatosModelos({
            ...datosModelos,
            modelo: {
                ...datosModelos.modelo,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangePais = e => {
        setPais(e.target.value)
    }

    const buttonActivo = () => {
        setDatosModelos({
            ...datosModelos,
            activo: !datosModelos.activo
        })
    }

    const onChangeFactor = e => {

        setDatosModelos({
            ...datosModelos,
            [e.target.name] : e.target.value
        })
    }
    
    const subirImagen = async (e) => {
        setCargaImaganes({
            imagenSeleccionada: e.target.files[0],
            tamano: e.target.files[0].size
        })

        const respuesta = await cargarArchivoS3( e.target.files[0], e.target.files[0].name );

    }

    const guardarImagenUno = async(tamano) => {
        if(cargaImagenes.imagenSeleccionada) {
            const formData = new FormData()
            formData.append('modelos', cargaImagenes.imagenSeleccionada)
            try {
                // const respuesta = await cargarArchivos(formData)
                const respuesta = await cargarArchivoS3( 
                    cargaImagenes.imagenSeleccionada, 
                    cargaImagenes.imagenSeleccionada.name 
                );

                if ( respuesta.status !== 204) {
                    setModalMensajeEstatus({
                        activo: true,
                        mensaje: 'No se pudo cargar el archivo'
                    })
                }

                setDatosModelos({
                    ...datosModelos,
                    imagenes: {
                        ...datosModelos.imagenes,
                        primera: {
                            url: respuesta.location,
                            relacionAspecto: 'sin relación',
                            extension: respuesta.location,
                            nombre: respuesta.key,
                            tamano: tamano
                        }
                    }
                })

            } catch (error) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: 'Ha ocurrido un problema'
                })
            }
        }
    }

    const guardarImagenDos = async(tamano) => {
        if(cargaImagenes.imagenSeleccionada) {
            const formData = new FormData()
            formData.append('modelos', cargaImagenes.imagenSeleccionada)
            try {
                const respuesta = await cargarArchivos(formData)
                setDatosModelos({
                    ...datosModelos,
                    imagenes: {
                        ...datosModelos.imagenes,
                        segunda: {
                            url: respuesta.data.data.url,
                            relacionAspecto: respuesta.data.data.relacionAspecto,
                            extension: respuesta.data.data.extension,
                            nombre: respuesta.data.data.nombre,
                            tamano: tamano
                        }
                    }
                })

            } catch (error) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: 'Ha ocurrido un problema'
                })
            }
        }
    }

    const guardarImagenTres = async(tamano) => {
        if(cargaImagenes.imagenSeleccionada) {
            const formData = new FormData()
            formData.append('modelos', cargaImagenes.imagenSeleccionada)
            try {
                const respuesta = await cargarArchivos(formData)
                setDatosModelos({
                    ...datosModelos,
                    imagenes: {
                        ...datosModelos.imagenes,
                        tercera: {
                            url: respuesta.data.data.url,
                            relacionAspecto: respuesta.data.data.relacionAspecto,
                            extension: respuesta.data.data.extension,
                            nombre: respuesta.data.data.nombre,
                            tamano: tamano
                        }
                    }
                })

            } catch (error) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: 'Ha ocurrido un problema'
                })
            }
        }
    }

    const guardarImagenCuarta = async(tamano) => {
        if(cargaImagenes.imagenSeleccionada) {
            const formData = new FormData()
            formData.append('modelos', cargaImagenes.imagenSeleccionada)
            try {
                const respuesta = await cargarArchivos(formData)
                setDatosModelos({
                    ...datosModelos,
                    imagenes: {
                        ...datosModelos.imagenes,
                        cuarta: {
                            url: respuesta.data.data.url,
                            relacionAspecto: respuesta.data.data.relacionAspecto,
                            extension: respuesta.data.data.extension,
                            nombre: respuesta.data.data.nombre,
                            tamano: tamano
                        }
                    }
                })

            } catch (error) {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: 'Ha ocurrido un problema'
                })
            }
        }
    }


    const guardarPaises = () => {
        let conjuntoPais = datosModelos.modelo.origen
        if (origen.trim() === ''){
            return
        }
        conjuntoPais.push(origen)
        setDatosModelos({
            ...datosModelos,
            modelo:{
                ...datosModelos.modelo,
                origen: conjuntoPais
            }
        })
        setPais('')
    }
    const guardarVersiones = () => {
        if(
            version.codigo.trim() === '' ||
            version.descripcion.trim() === ''
        ) {
            setMensaje(true)
            return
        } 

        setMensaje(false)
        setDatosModelos({
            ...datosModelos,
            versiones: [
                ...datosModelos.versiones,
                {
                    codigo: version.codigo,
                    descripcion: version.descripcion
                }
            ]
        })
        setVersion(Object.assign({}, estadoInicialVersion))
        
    }

    const onChangeVersionesEditar = ( e, index ) => {
        let versiones = datosModelos.versiones;
        versiones[index] = {
            ...versiones[index],
            [e.target.name]: e.target.value
        }
        setDatosModelos({
            ...datosModelos,
            versiones: versiones
        })

    }

    const guardarModelos = (payload) => {
        if(
            datosModelos.modelo.codigo.trim() === '' ||
            datosModelos.modelo.anoModelo.trim() === '' ||
            datosModelos.modelo.nombre.trim() === ''
        ) {
            setMensaje(true)
            return
        }
        guardar(payload)
    }

    const actualizarModelos = (payload) => {
        if(
            datosModelos.modelo.codigo.trim() === '' ||
            datosModelos.modelo.anoModelo.trim() === '' ||
            datosModelos.modelo.nombre.trim() === '' ||
            datosModelos.modelo.origen === []
        ) {
            setMensaje(true)
            return
        }
        actualizar(payload)
    }
    
    const eliminarVersion = (item) => {
        const index = datosModelos.versiones.indexOf(item)
        datosModelos.versiones.splice(index, 1 )
        if(evento === EVENTO_ACTUALIZAR){
            actualizarModelos(datosModelos)
        }
    }

    const eliminarPaises = (index) => {
        datosModelos.modelo.origen.splice(index, 1)
        if(evento === EVENTO_ACTUALIZAR){
            actualizarModelos(datosModelos)
        }
    }

    const desactivarModal = () => {
        setModalActivo(false)
        setMensaje(false)
        setEvento('')
    }

    return(
        <Modal
            open={modalActivo}
        >
            <Modal.Header><Icon color="red" name="edit"/>Formulario Modelos</Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Información del modelo</h3>
                    <Form.Group widths="equal">
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosModelos.modelo.nombre}
                            onChange={onChangeDatosModelo}
                        />
                        <Form.Input 
                            label="Codigo de Modelo"
                            name="codigo"
                            value={datosModelos.modelo.codigo}
                            onChange={onChangeDatosModelo}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input 
                            label="Color"
                            name="color"
                            value={datosModelos.modelo.color}
                            onChange={onChangeDatosModelo}
                        />
                        <Form.Input 
                            label="Año Modelo"
                            name="anoModelo"
                            value={datosModelos.modelo.anoModelo}
                            onChange={onChangeDatosModelo}
                        />
                        <Form.Input 
                            label="Factor ajuste: Mano de Obra"
                            type="number"
                            name="factorAjusteManoObra"
                            value={datosModelos.factorAjusteManoObra}
                            onChange={onChangeFactor}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                        <label>Origen (País)</label>
                        <Input 
                            label={                        
                            <Button
                                onClick={() => guardarPaises()}
                                icon="plus"
                            />
                            }
                            labelPosition='right'
                            name="origen"
                            value={origen}
                            onChange={onChangePais}
                        ></Input>
                        </Form.Field>
                    </Form.Group>
                    {datosModelos.modelo.origen && datosModelos.modelo.origen.length>0? 
                    datosModelos.modelo.origen.map((p, index) => {
                        return(
                            <Fragment key={ index + p } >
                                <Label style={{marginTop: 4}}size="large" key={index + p}> {p}
                                <Button
                                    style={{backgroundColor:"transparent",borderColor:"#F6F6F6 !important"}} 
                                    size="tiny"  
                                    onClick={() => eliminarPaises(index)} 
                                    icon="times"
                                /> 
                                </Label>
                            </Fragment>
                        )
                    })
                    : null}
                    <h3>Versiones</h3>
                    <Form.Group>
                        <Form.Input 
                            label="Codigo de Version"
                            name="codigo"
                            width="6"
                            value={version.codigo}
                            onChange={onChangeDatosVersion}
                        />
                        <Form.Input 
                            label="Descripcion de Version"
                            name="descripcion"
                            width="6"
                            value={version.descripcion}
                            onChange={onChangeDatosVersion}
                        />
                        <Form.Field>
                            <label>Agregar</label>
                        <Button
                            onClick={() => guardarVersiones()}
                            width="4"
                        >
                            <Icon name="plus"/>
                            Agregar Versiones
                        </Button>
                        </Form.Field>
                    </Form.Group>
                   {mensaje ? <Mensaje/> : null}
                    {datosModelos.versiones && datosModelos.versiones.length>0 ?  datosModelos.versiones.map((ver, index) => {
                    return(
                        <Fragment key={ index + ver.codigo } >
                            
                            <Form.Group widths="equal">
                                <Form.Input
                                    key={index + ver.codigo}
                                    label="Codigo"
                                    width="10"
                                    name="codigo"
                                    value={ver.codigo}
                                    onChange={(e) => onChangeVersionesEditar(e, index)}
                                />
                                <Form.Input
                                    key={index}
                                    label="Descripcion"
                                    width="10"
                                    name="descripcion"
                                    value={ver.descripcion}
                                    onChange={(e) => onChangeVersionesEditar(e, index)}
                                />
                            </Form.Group>
                            <Form.Group>
                               {evento === EVENTO_ACTUALIZAR ?
                               <Button
                                    style={{marginTop: 10, backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="tiny"
                                    content="ELIMINAR"
                                    floated="right"
                                    onClick={() => eliminarVersion(ver)}
                                    icon="trash"
                                />
                                :
                                null
                                }   
                            </Form.Group>
                        
                    </Fragment>
                    )
                }) : null }
                    <h3>Presentación Del Modelo</h3>
                        <p>El tamaño de la imagen debe ser de 600 x 800</p>
                        <p>La relacion de aspecto deber ser de 4:3</p>
                            <Grid
                                columns={2} divided
                            >
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                            <h3>Vista Previa</h3>
                                            <h5>Nombre de la Imagen</h5>
                                            {datosModelos.imagenes.primera
                                            ? 
                                            <p> {datosModelos.imagenes.primera.nombre} </p>
                                            :
                                            null
                                            }
                                        </Segment>
                                        <Segment
                                            vertical={false}
                                        >
                                            <InputFile
                                                button={{ label: cargaImagenes.imagenSeleccionada?.name? cargaImagenes.imagenSeleccionada?.name:'Seleccionar imagen 1' }}
                                                input={{
                                                    id:'input-upload-id1',
                                                    onChange: subirImagen
                                                }}
                                            />
                                            <Button 
                                                size="tiny"
                                                icon="check"
                                                content="Cargar imagen"
                                                color="green"
                                                onClick={() => guardarImagenUno(cargaImagenes.tamano)}
                                            />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment textAlign="center">
                                            {datosModelos.imagenes.primera.url
                                            ? 
                                            <Image
                                                src={datosModelos.imagenes.primera.url}
                                                verticalAlign="middle"                  
                                            />
                                            :
                                            <p style={{textAlign:"center", verticalAlign:"middle"}}>Vista Previa de la Imagen</p>
                                            }
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                            <Grid
                                columns={2} divided
                            >
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                        <h3>Vista Previa</h3>
                                            <h5>Nombre de la Imagen</h5>
                                            {datosModelos.imagenes.segunda
                                            ? 
                                            <p> {datosModelos.imagenes.segunda.nombre} </p>
                                            :
                                            null
                                            }
                                        </Segment>
                                        <Segment
                                            vertical={false}
                                        >
                                            <InputFile
                                                button={{label: cargaImagenes.imagenSeleccionada?.name? cargaImagenes.imagenSeleccionada?.name : 'Seleccionar imagen 2' }}
                                                input={{
                                                    id:'input-upload-id2',
                                                    onChange: subirImagen
                                                }}
                                            />
                                            <Button
                                                size="tiny"
                                                icon="check"
                                                color="green"
                                                content="Cargar imagen"
                                                onClick={() => guardarImagenDos(cargaImagenes.tamano)}
                                            />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                        {datosModelos.imagenes.segunda.url
                                        ? 
                                        <Image
                                            src={datosModelos.imagenes.segunda.url}
                                            verticalAlign="middle"                  
                                        />
                                        :
                                        <p style={{textAlign:"center", verticalAlign:"middle"}}>Vista Previa de la Imagen</p>
                                        }
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                            {/* <Grid
                                columns={2} divided
                            >
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                        <h3>Vista Previa</h3>
                                            <h5>Nombre de la Imagen</h5>
                                            {datosModelos.imagenes.tercera
                                            ? 
                                            <p> {datosModelos.imagenes.tercera.nombre} </p>
                                            :
                                            null
                                            }
                                        </Segment>
                                        <Segment
                                            vertical={false}
                                        >
                                            <InputFile
                                                button={{label:'Seleccionar imagen 3'}}
                                                input={{
                                                    id:'input-upload-id3',
                                                    onChange: subirImagen
                                                }}
                                            />
                                            <Button
                                                size="tiny"
                                                icon="check"
                                                color="green"
                                                content="Cargar imagen"
                                                onClick={() => guardarImagenTres(cargaImagenes.tamano)}
                                            />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                        {datosModelos.imagenes.tercera.url
                                        ? 
                                        <Image
                                            src={datosModelos.imagenes.tercera.url}
                                            verticalAlign="middle"                  
                                        />
                                        :
                                        <p style={{textAlign:"center", verticalAlign:"middle"}}>Vista Previa de la Imagen</p>
                                        }
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid> */}
                            {/* <Grid
                                columns={2} divided
                            >
                                <Grid.Row stretched>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                        <h3>Vista Previa</h3>
                                            <h5>Nombre de la Imagen</h5>
                                            {datosModelos.imagenes.cuarta
                                            ? 
                                            <p> {datosModelos.imagenes.cuarta.nombre} </p>
                                            :
                                            null
                                            }
                                        </Segment>
                                        <Segment
                                            vertical={false}
                                        >
                                            <InputFile
                                                button={{label:'Seleccionar imagen 4'}}
                                                input={{
                                                    id:'input-upload-id4',
                                                    onChange: subirImagen
                                                }}
                                            />
                                            <Button
                                                size="tiny"
                                                icon="check"
                                                content="Cargar imagen"
                                                color="green"
                                                onClick={() => guardarImagenCuarta(cargaImagenes.tamano)}
                                            />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment vertical={false}>
                                        {datosModelos.imagenes.cuarta.url
                                        ? 
                                        <Image
                                            src={datosModelos.imagenes.cuarta.url}
                                            verticalAlign="middle"                  
                                        />
                                        :
                                        <p style={{textAlign:"center", verticalAlign:"middle"}}>Vista Previa de la Imagen</p>
                                        }
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid> */}
                </Form>
                <h3>Activacion Del Modelo</h3>
                    <Checkbox 
                        label="Si el modelo esta desactivada, no estará disponible en la oferta comercial"
                        onChange={() => buttonActivo()}
                        checked={datosModelos.activo}
                        toggle 
                    />
            </Modal.Content>
            <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={desactivarModal}
                    />
                    {evento === EVENTO_ACTUALIZAR ?
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={() => actualizarModelos(datosModelos)}
                        icon="save"
                    />
                    :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR"
                        onClick={() => guardarModelos(datosModelos)}
                        icon="save"
                    />
                    }
                    
                </Modal.Actions>
        </Modal>
    )
}

export default FormularioModelos;