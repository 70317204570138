import tokenAuth from '../../config/tokenAuth';
import {clienteAxios} from '../../config/axios';


const usuarioAutenticado = () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
}

export const crearPaquetes = async(payload) => {
    usuarioAutenticado()
    try{
        const respuesta = await clienteAxios.post('/paquetes/crear', payload)
        console.log(respuesta)
        return(respuesta)
    } catch (error){
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const actualizarPaquete = async(payload) => {
    usuarioAutenticado()
    try{
        const respuesta = await clienteAxios.post('/paquetes/actualizar', payload)
        return(respuesta)
    } catch (error){
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaPaquete = async(payload) => {
    usuarioAutenticado()
    try{
        const respuesta = await clienteAxios.post('/paquetes/consulta', payload)
        return(respuesta)
    }catch(error){
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un error"
            }
        })
    }
}

export const consultaPaqueteId = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/paquetes/consultaId', payload)
        return(respuesta)
    } catch (error) {
        return({
            data:{
                estatus: false,
                resultadoOperacion: "Ha ocurrido un error"
            }
        })
    }
}
export const eliminarPaquete = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/paquetes/eliminar', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}

export const consultaPaquetePorVersion = async(payload) => {
    usuarioAutenticado()
    try {
        const respuesta = await clienteAxios.post('/paquetes/consultaPorVersion', payload)
        return(respuesta)
    } catch (error) {
        return({
            data: {
                estatus: false,
                resultadoOperacion: "Ha ocurrido un problema"
            }
        })
    }
}