export const estadoInicialVersiones = {
    activo: true,
    versionControl: '',
    campos: [],
    codigoModelo: [],
    aireAcondicionado: [],
    cilindraje: [],
    codigoEquipamiento: [],
    espacioLibre: [],
    generacionVehiculo: [],
    motor: [],
    numeroPuertas: [],
    prefijoFabrica: [],
    traccion: [],
    transmision: [],
    uso: [],
    versionCodigoEquipamiento: []
}

export const estadoInicialCampos = {
    contenido: '',
    caracteres: 1,
    posicion: 1
}

export const estadoInicialClaves = {
    nombre: '',
    valor: ''
}