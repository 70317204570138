import React, { useState, Fragment, useEffect } from 'react'
import { estadoInicialFiltro } from '../../estadosIniciales/paquetes/estadoInicial-paquetes';
import { consultaPaquete} from '../../servicios/paquetes/paquetes.srv';
import { Modal, Form, Table, Button, Header, Container } from 'semantic-ui-react';


const ConsultaModalPaquetes = ({
    setDatosSolicitud,
    setModalPaquetes,
    datosSolicitud,
    actualizar,
    modalPaquetes
}) => {

    const [ paquetes, setPaquetes ] = useState([])
    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))


    const consultar = async() => {
        const respuesta = await consultaPaquete(datosFiltrado)
        if(respuesta.data.estatus){
            setPaquetes(respuesta.data.data.paquetes)
        }
    }

    useEffect(() => {
        consultar()
    }, [datosFiltrado])

    const onChangeDatosFiltrado = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            [e.target.name]:e.target.value
        })
    }

    const onChangeDatosFiltradoModelo = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            modelo: {
                ...datosFiltrado.modelo,
                [e.target.name]:e.target.value
            }
        })
    }

    const elegirPaquete = (id) => {
        let conjunto = datosSolicitud.paquetesId;
        conjunto.push(id)
        setDatosSolicitud({
            ...datosSolicitud,
            paquetesId:conjunto
        }) 
        actualizar(datosSolicitud)
        setModalPaquetes(false)
    }

    return ( 
        <Modal
            open={modalPaquetes}
            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
        >
            <Modal.Header 
                style={{ 
                    backgroundColor:'#FFFFFF', 
                    border:'none'}} 
                as="h5">
                <Header>
                Elegir paquetes
                </Header>
            </Modal.Header>
            <Modal.Content>
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Codigo"
                                placeholder="Codigo del Paquete"
                                name="codigoPaquete"
                                value={datosFiltrado.codigoPaquete}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Nombre"
                                placeholder="Nombre del Paquete"
                                name="nombre"
                                value={datosFiltrado.nombre}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Descripcion"
                                placeholder="Descripcion del Paquete"
                                name="descripcion"
                                value={datosFiltrado.descripcion}
                                onChange={onChangeDatosFiltrado}
                            />
                            <Form.Input
                                label="Codigo del Modelo"
                                placeholder="Codigo del Modelo"
                                name="codigo"
                                value={datosFiltrado.modelo.codigo}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                        </Form.Group>
                        <Form.Group
                            widths="equal"
                        >
                            <Form.Input
                                label="Descripcion del Modelo"
                                placeholder="Descripcion del Modelo"
                                name="descripcion"
                                value={datosFiltrado.modelo.descripcion}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                            <Form.Input
                                label="Version del Modelo"
                                placeholder="Version"
                                name="version"
                                value={datosFiltrado.modelo.version}
                                onChange={onChangeDatosFiltradoModelo}
                            />
                        </Form.Group>
                    </Form>
                    <Fragment>
                        <Container fluid style={{overflowX: 'scroll'}}>
                        <Table size="small" singleLine celled textAlign="center">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">CODIGO DE PAQUETE</Table.HeaderCell>
                                <Table.HeaderCell colSpan="3">MODELO</Table.HeaderCell>
                                <Table.HeaderCell rowSpan="2">ACCIONES</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell> CODIGO </Table.HeaderCell>
                                <Table.HeaderCell> VERSION </Table.HeaderCell>
                                <Table.HeaderCell> DESCRIPCION</Table.HeaderCell>
                            </Table.Row>
                    </Table.Header>
                            <Table.Body>
                                {paquetes !== undefined && paquetes.length > 0 ? paquetes.map(item => (
                                    <Fragment key={item._id}>
                                        <Table.Row>
                                        <Table.Cell> {item.nombre} </Table.Cell>
                                        <Table.Cell> {item.descripcion} </Table.Cell>
                                        <Table.Cell> {item.codigoPaquete} </Table.Cell>
                                        <Table.Cell> {item.modelo.codigo} </Table.Cell>
                                        <Table.Cell> {item.modelo.version} </Table.Cell>
                                        <Table.Cell> {item.modelo.descripcion} </Table.Cell>
                                            <Table.Cell>
                                                <Button
                                                    icon="check"
                                                    size="tiny"
                                                    onClick={() => elegirPaquete(item._id)}
                                                    toggle
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    </Fragment>
                                )): null}
                            </Table.Body>
                        </Table>
                        </Container>
                    </Fragment>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                <Button
                    content="Cancelar"
                    size="mini"
                    onClick={() => setModalPaquetes(false)}
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                />
            </Modal.Actions>
        </Modal>
     );
}
 
export default ConsultaModalPaquetes;