import React, {useState, useEffect, Fragment} from 'react';
import {Grid, Container, Header, Button, Table, Loader, Modal, Pagination } from 'semantic-ui-react';
import { EVENTO_CREAR } from '../../enum/eventos/tipo-evento';
import {crearPaquetes, eliminarPaquete, consultaPaquete} from '../../servicios/paquetes/paquetes.srv';
import {estadoInicialPaquete, estadoInicialFiltro} from '../../estadosIniciales/paquetes/estadoInicial-paquetes';
import FormularioPaquetes from '../../components/formularios/formulario.paquetes';
import FiltroPaquetes from '../../components/filtros/filtro.paquetes';
import ModalMensaje from '../../components/modales/modalMensaje';
import ItemPaquete from '../../components/itemsListados/item-paquetes';
import ModalEliminar from '../../components/modales/modalEliminar';
import Preloader from '../../components/utilidades/preloader';
import NoItems from '../../components/utilidades/noItems';



const PaquetesIC = () => {
    const modalEliminarContenido = {
        titulo: 'Eliminar Paquete',
        mensaje: '¿Esta seguro que quiere eliminar este paquete?'
    }
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ paqueteEliminar, setPaqueteEliminar ] = useState('')
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ cargando, setCargando ] = useState(false)
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ evento, setEvento ] = useState('')
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ datosPaquete, setDatosPaquete ] = useState(Object.assign({}, estadoInicialPaquete))
    const [ paquetes, setPaquetes ] = useState([])
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const abrirModal = () => {
        setEvento(EVENTO_CREAR)
        setModalActivo(true)
    }
    //CRUD
    //PETICION A LA LISTA
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })

    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }
    const consultar = async() => {
        const respuesta = await consultaPaquete(filtro)
        if(respuesta.data.estatus){
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.data.total / 10)
            })
            setPaquetes(respuesta.data.data.paquetes)
            setCargandoLoader(false)
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargandoLoader(false)
        }
    }

    useEffect(() => {
        const consultar = async() => {
            const respuesta = await consultaPaquete(filtro)
            if(respuesta.data.estatus){
                setPaginacion({
                    ...paginacion,
                    paginasTotales: Math.ceil(respuesta.data.data.total / 10)
                })
                setPaquetes(respuesta.data.data.paquetes)
                setCargandoLoader(false)
            } else {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargandoLoader(false)
            }
        }
        consultar()
    }, [filtro])
    //CREAR
    const CrearPaquete = async(payload) => {
        const respuesta = await crearPaquetes(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ELIMINAR
    const EliminarOperaciones = async(id) => {
        const respuesta = await eliminarPaquete({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }
    if(cargandoLoader){
        return <Preloader/>
    }
    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid >
            <Header 
                size="huge" 
                as="h1" 
                style={{ 
                    fontSize:50,
                    spacing:'-0.04em',
                    fontWeight:'400', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:30 }}>
                Paquetes
                <span>
                <Button 
                    size="mini"
                    onClick={abrirModal}
                    content="Nuevo paquete"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    floated='right'
                />
                </span>
            </Header>
            <FiltroPaquetes
                setFiltro={setFiltro}
            />
            <FormularioPaquetes
                modalActivo={modalActivo}
                datosPaquete={datosPaquete}
                evento={evento}
                guardar={(payload) => CrearPaquete(payload)}
                setEvento={setEvento}
                setDatosPaquete={setDatosPaquete}
                setModalActivo={setModalActivo}
            />
            { paquetes !== undefined && paquetes.length>0 ?
            <Fragment>
            <Container fluid style={{overflowX: 'scroll', marginTop:20, marginBottom:20 }}>
                <Table textAlign="center" celled structured singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">CODIGO DE PAQUETE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">TIPO DE PAQUETE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">ESTATUS</Table.HeaderCell>
                            <Table.HeaderCell colSpan="3">MODELO</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"> ACCIONES </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell> CODIGO </Table.HeaderCell>
                            <Table.HeaderCell> VERSION </Table.HeaderCell>
                            <Table.HeaderCell> DESCRIPCION</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {paquetes !== undefined && paquetes.length>0 ?
                            paquetes.map(i => (
                                <ItemPaquete
                                    key={i._id}
                                    setObjetoEliminar={setPaqueteEliminar}
                                    setModalEliminar={setModalEliminar}
                                    item={i}
                                />
                            ))
                        : null}
                    </Table.Body>
                </Table> 
                </Container>
                <Pagination 
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />
                </Fragment>
                : <NoItems/>}
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={paqueteEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarOperaciones(value)}
                    setObjetoEliminar={setPaqueteEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
    </Grid>
     );
}
 
export default PaquetesIC;