import React, {Fragment, useState, useEffect} from 'react';
import {Grid, Form, Segment, Button, Header, Container, Table, Card, Icon, Modal} from 'semantic-ui-react'
import {tipoIdentificacionOpciones} from '../../estadosIniciales/listas/identificacion-lista'
import { 
    crearSolicitudes, 
    consultaSolicitudesId, 
    actualizarSolicitud, 
    actualizarEstadoSolicitud,
    eliminarPaqueteSolicitud
} from '../../servicios/solicitudAdquisiciones/solicitud.srv';
import {estadoInicialCreacion, estadoInicialSolicitud} from '../../estadosIniciales/solicitudes/estadoInicial-solicitud'
import {EVENTO_NUEVA_SOLICITUD, EVENTO_RETOMAR, EVENTO_ACTUALIZAR} from '../../enum/eventos/tipo-evento';
import {actualizarCliente} from '../../servicios/clientes/clientes.srv';
import { estadoInicialClientes } from '../../estadosIniciales/clientes/estadoInicial-clientes';
import { consultaConcesionarioPorGrupo } from '../../servicios/concesion/concesiones.srv';
import { crearVehiculos, actualizarVehiculos, consultaVehiculosVin } from '../../servicios/vehiculos/vehiculos.srv';
import { estadoInicialVehiculo } from '../../estadosIniciales/vehiculos/estadoInicial-vehiculos';
import {TIPO_ESTADO_SOLICITUDES} from '../../enum/tipo-estadoSolicitudes';
import { 
    VISTA_ACTIVA_ACTIVACION,
    VISTA_ACTIVA_FORMULARIO, 
    VISTA_ACTIVA_PAQUETES, 
    VISTA_ACTIVA_REDENCION,
    VISTA_ACTIVA_SOPORTES,
    EVENTO_REEMPLAZAR_VEHICULO
} from '../../enum/eventos/tipo-evento';
import {useSelector, useDispatch} from 'react-redux';
import { limpiarCarrito } from '../../actions/carrito.action';
import ModalEdicionVehiculo from '../../components/modales/modalEdicionVehiculos';
import ModalDetallesServicio from '../../components/modales/modalDetallesServicios';
import DatosCliente from '../../components/tarjetas/datosCliente';
import NavBarSolicitud from '../../components/navbars/NavBarSolicitud';
import DatosVehiculo from '../../components/tarjetas/datosVehiculo';
import ModalMensaje from '../../components/modales/modalMensaje';
import ConsultaModalPaquetes from '../../components/modales/modalSeleccionPaquetes';
import FormularioSolicitudes from '../../components/formularios/formulario.solicitudes';
import ItemPaquete from '../../components/itemsListados/item-paquetes';
import ModalEdicionCliente from '../../components/modales/modalEdicionCliente'
import ModalAgregarSoporte from '../../components/modales/modalAgregarSoporte'
import ItemSoporte from '../../components/itemsListados/item-soporte';
import DatosPaquete from '../../components/tarjetas/datosPaquetes';
import ModalActivacion from '../../components/modales/modalActivacion';
import Preloader from '../../components/utilidades/preloader';
import ModalSeleccionarVin from '../../components/modales/modalSeleccionarVin';
import ModalAcuerdoPrepagado from '../../components/modales/modalAcuerdoPrepagado';
import ItemServicio from '../../components/itemsListados/item-servicio';
import { TIPO_ROL } from '../../enum/tipo-rol';
import { variables } from '../../config/variables';


const SolicitudesIC = ({ idUrl }) => {
    const dispatch = useDispatch()
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        eventoCallback: '',
        activo: false,
        mensaje: '',
        objeto: ''
    })
    const [ modalPaquetes, setModalPaquetes ] = useState(false)
    const [ id, setId ] = useState('')
    const [ verDetallesServicio, setVerDetallesServicio ] = useState(false)
    const [ detallesServicio, setDetallesServicio ] = useState({})
    const [ listaConcesionarios, setListaConcesionarios ] = useState([])
    const [ listaGrupos, setListaGrupos ] = useState([])
    const [ editarVehiculo, setEditarVehiculo ] = useState(false)
    const [ editarCliente, setEditarCliente ] = useState(false)
    const [ seleccionarVehiculo, setSeleccionarVehiculo ] = useState(false)
    const [ datosSolicitud, setDatosSolicitud ] = useState(Object.assign({}, estadoInicialSolicitud))
    const carritoDeCompra = useSelector( state => state.carrito.carritoDeCompra)
    const [ modalActivacion, setModalActivacion ] = useState(false)
    const [ mostrarDetalles, setMostrarDetalles ] = useState(false)
    const [ datosPaquete, setDatosPaquete ] = useState([])
    const [ datosCliente, setDatosCliente ] = useState(Object.assign({}, estadoInicialClientes))
    const [ vehiculos, setVehiculos ] = useState([])
    const [ datosVehiculo, setDatosVehiculo ] = useState(Object.assign({}, estadoInicialVehiculo))
    const [ peticion, setPeticion ] = useState(Object.assign({}, estadoInicialCreacion))
    const [ vistaActiva, setVistaActiva ] = useState('')
    const [ paquete, setPaquete ] = useState(null)
    const [ evento, setEvento ] = useState('')
    const [ modalAgregarSoporte, setModalAgregarSoporte ] = useState(false)
    const [ cargando, setCargando ] = useState(false)
    const [ activoModalAcuerdoPrepagado, setModalacuerdoPrepagado] = useState(false)
    const [ opciones, setOpciones ] = useState([])

    const usuario = localStorage.getItem('usuario');
    const rol = localStorage.getItem('rol')

    useEffect(() => {

        const consultarPorIdUrl = async(id) => {
            setCargando(true)
            const respuesta = await consultaSolicitudesId({id})
            if(respuesta.data.estatus){
                setEvento(EVENTO_ACTUALIZAR)
                setVistaActiva(VISTA_ACTIVA_FORMULARIO)
                setDatosPaquete(respuesta.data.data.datosPaquetes)
                setDatosSolicitud(respuesta.data.data.datosSolicitud) 
                setDatosCliente(respuesta.data.data.datosCliente)
                setDatosVehiculo(respuesta.data.data.datosVehiculo)
                if(carritoDeCompra.length>0 ){
                    let paquetesId = respuesta.data.data.datosSolicitud.paquetesId
                    carritoDeCompra.map(paquete => {
                        paquetesId.push(paquete._id)
                    })
                    ActualizarSolicitud({
                        ...respuesta.data.data.datosSolicitud,
                        paquetesId:paquetesId
                    })
                }
                setCargando(false)
            } else {
                setCargando(false)  
            }
        }

        if( idUrl ) {
            consultarPorIdUrl(idUrl.id)
        }

        const datosUsuario = JSON.parse(usuario)
        let opcionesConcesiones = []
        if(usuario){
           datosUsuario.concesiones.map(i => {
              return opcionesConcesiones.push({
                    value: i,
                    text: i,
                    key: i
                })
            })
            setOpciones(opcionesConcesiones)
        } 
        
    }, [idUrl, usuario])

    const onChangePeticion = (e, {name, value}) => {
        setPeticion({
            ...peticion,
            identificacion:{
                ...peticion.identificacion,
                [name]: value
            }
        })
    }

    const onChageConcesionario = ( e, {name, value}) => {
        setPeticion({
            ...peticion,
            [name]: value
        })
    }

    const validarCliente = async() => {
        setCargando(true)

        try {
            const respuesta = await crearSolicitudes(peticion)
            if(!respuesta.data.estatus && !respuesta.data.noInternet){
                setEvento(EVENTO_NUEVA_SOLICITUD)
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            } else if (!respuesta.data.estatus && respuesta.data.noInternet) {
                setEvento('')
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: 'Ha ocurrido un problema'
                })
                setCargando(false)
            } else {
                setEvento(EVENTO_RETOMAR)
                setId(respuesta.data.data.idSolicitud)
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }
        } catch (error) {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: error
            })
        }

    }

    const consultaConcesiones = async () => {
        try {
            const concesiones = await consultaConcesionarioPorGrupo({})

            if ( concesiones.estatus ) {
                const prepararLista = []
                const prepararGrupos = []
                concesiones.data.map( i => {

                    prepararGrupos.push({
                        key: i.grupo,
                        text: i.grupo,
                        value: i.grupo
                    })

                    return i.concesiones.map( j => {
                       
                        return prepararLista.push({
                            grupo: i.grupo,
                            key: `${i.grupo} ${j.nombre} ${j.cl}`,
                            text: `${j.nombre} ${j.cl}`,
                            value: j.cl
                        })
                    })
                } )
                setListaConcesionarios( prepararLista )
                setListaGrupos( prepararGrupos )
            } else {
                setModalMensajeEstatus({
                    activo: true,
                    mensaje: concesiones.resultadoOperacion
                })
            }
        } catch ( error ) {
            setModalMensajeEstatus({
                activo: true,
                mensaje: 'Error al consultar concesiones'
            })
        }
        
    }

    // const consultarPorIdUrl = async(id) => {
    //     setCargando(true)
    //     const respuesta = await consultaSolicitudesId({id})
    //     if(respuesta.data.estatus){
    //         setEvento(EVENTO_ACTUALIZAR)
    //         setVistaActiva(VISTA_ACTIVA_FORMULARIO)
    //         setDatosPaquete(respuesta.data.data.datosPaquetes)
    //         setDatosSolicitud(respuesta.data.data.datosSolicitud) 
    //         setDatosCliente(respuesta.data.data.datosCliente)
    //         setDatosVehiculo(respuesta.data.data.datosVehiculo)
    //         if(carritoDeCompra.length>0 ){
    //             let paquetesId = respuesta.data.data.datosSolicitud.paquetesId
    //             carritoDeCompra.map(paquete => {
    //                 paquetesId.push(paquete._id)
    //             })
    //             ActualizarSolicitud({
    //                 ...respuesta.data.data.datosSolicitud,
    //                 paquetesId:paquetesId
    //             })
    //         }
    //         setCargando(false)
    //     } else {
    //         setCargando(false)  
    //     }
    // }

    const consultarPorId = async() => {
        setCargando(true)
        const respuesta = await consultaSolicitudesId({id})
        if(respuesta.data.estatus){
            setEvento(EVENTO_ACTUALIZAR)
            setVistaActiva(VISTA_ACTIVA_FORMULARIO)
            setDatosPaquete(respuesta.data.data.datosPaquetes)
            setDatosSolicitud(respuesta.data.data.datosSolicitud) 
            setDatosCliente(respuesta.data.data.datosCliente)
            setDatosVehiculo(respuesta.data.data.datosVehiculo)
            if(carritoDeCompra.length>0 ){
                let paquetesId = respuesta.data.data.datosSolicitud.paquetesId
                carritoDeCompra.map(paquete => (
                    paquetesId.push(paquete._id)
                ))
                ActualizarSolicitud({
                    ...respuesta.data.data.datosSolicitud,
                    paquetesId:paquetesId
                })
            }
            setCargando(false)
        } else {
            setCargando(false)  
        }
    }

    const refrescarSolicitud = async() => {
        let idSolicitud

        if ( id ) {
            idSolicitud = id
        } else {
            idSolicitud = idUrl.id
        }
        const respuesta = await consultaSolicitudesId({id: idSolicitud})
        if(respuesta.data.estatus){
            setDatosPaquete(respuesta.data.data.datosPaquetes)
            setDatosSolicitud(respuesta.data.data.datosSolicitud) 
            setDatosCliente(respuesta.data.data.datosCliente)
            setDatosVehiculo(respuesta.data.data.datosVehiculo)
            dispatch(limpiarCarrito())
        }
    }

    const reemplazarVehiculo = (payload) => {
        ActualizarSolicitud({
            ...datosSolicitud,
            vehiculo: payload
        })
    }
    const agregarVehiculoSolicitud = async(payload) => {
        const respuesta = await crearVehiculos(payload)
        if(respuesta.data.estatus){
            ActualizarSolicitud({
                ...datosSolicitud,
                vehiculo: respuesta.data.data.id
            })
        } else if (!respuesta.data.estatus && respuesta.data.data) {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                eventoCallback: EVENTO_REEMPLAZAR_VEHICULO,
                objeto: respuesta.data.data.id,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
    }
    const ActualizarCliente = async(payload) => {
        delete payload.creado
        const respuesta = await actualizarCliente(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            refrescarSolicitud()
            setEditarCliente(false)
            setModalAgregarSoporte(false)
        }
    }

    const ActualizarVehiculo = async(payload) => {
        delete payload.__v
        const respuesta = await actualizarVehiculos(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            refrescarSolicitud()
        } else {
            setEditarVehiculo(false)
            refrescarSolicitud()
        }
    }
    const consultaVehiculoVin = async(payload) => {
        const respuesta = await consultaVehiculosVin(payload)
        if(respuesta.data.estatus){
            setCargando(false)
            setVehiculos(respuesta.data.data.vehiculos)
            setSeleccionarVehiculo(true)
        } else {
            setCargando(false)
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
    }
    //CRUD
    //ACTUALIZAR 
    const ActualizarSolicitud = async(payload) => {
        setCargando(true)
        const respuesta = await actualizarSolicitud(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                eventoCallback: '',
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setCargando(false)
            refrescarSolicitud()
        } else {
            setCargando(false)
            refrescarSolicitud()
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                eventoCallback: '',
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
    }

    const ActualizarEstadoSolicitud = async ( payload ) => {
        setCargando(true)
        let idSolicitud
        if ( idUrl ) {
            idSolicitud = idUrl.id
        } else {
            idSolicitud = id
        }
        const respuesta = await actualizarEstadoSolicitud({
            id: idSolicitud,
            estado: TIPO_ESTADO_SOLICITUDES.ACTIVO
        })
        if(!respuesta.data.estatus){
            setCargando(false)
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            refrescarSolicitud()
        } else {
            setCargando(false)
            refrescarSolicitud()
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        }
    }

    const eliminarPaquete = async ( item ) => {

        setCargando(true)
        try {
            const respuesta = await eliminarPaqueteSolicitud({
                idSolicitud: datosSolicitud._id,
                idPaquete: item._id
            })
            setCargando(false)
            refrescarSolicitud()
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } catch (error) {
            setCargando(false)
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: error.data.resultadoOperacion
            })
        }
        
        
        

        


    }
 
    const enviarWhatsapp = () => {

        const parametros = { _arg: '' }

        parametros._arg = 'https://wa.me/';
        parametros._arg += '57';
        parametros._arg += datosCliente.telefonos.celular;
        parametros._arg += '?text=';
        parametros._arg += 'Por favor accede al siguiente link, para firmar el Acuerdo de Mantenimiento Prepagado: ';
        parametros._arg += '%0A%0A';
        parametros._arg += `${variables.frontend}%23/acuerdo-cliente/${datosSolicitud._id}`;
        parametros._arg += '%0A%0A';

        console.log('Parametros: ', parametros)
        window.open( parametros._arg )
    }

    const renderHeader = () => {
        if ( vistaActiva ) {
            return (
                <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}} container>
                    <Container fluid>
                        <Header 
                            as="h1"
                            size="huge"
                            style={{ 
                                fontSize:50,
                                spacing:'-0.04em',
                                fontWeight:'bold', 
                                fontFamily:'kiaBold', 
                                marginBottom:20, 
                                marginTop:30 }}> 

                                <Header.Content>
                                    Solicitud  
                                </Header.Content>
                                <Header.Subheader>
                                    Codigo de la solicitud: #{datosSolicitud.solicitud.numero} <br></br>
                                    Estado: {datosSolicitud.estado}
                                </Header.Subheader>
                        </Header>
                        <Button
                            onClick={() => setModalacuerdoPrepagado(true)}
                            style={{ 
                                backgroundColor:'transparent', 
                                padding:0, 
                                fontWeight:'bold', 
                                fontFamily:'kiaBold',
                                marginRight: 20,
                                color:'#000000', 
                                hover:{ borderBottom: 'solid', borderColor:'red' } }}
                            > 
                            <strong>Acuerdo de servicios Prepagados</strong><Icon name="chevron right" />
                        </Button>
                        <Button
                            onClick={() => enviarWhatsapp()}
                            style={{ 
                                backgroundColor:'transparent', 
                                padding:0, 
                                fontWeight:'bold', 
                                fontFamily:'kiaBold',
                                marginRight: 20,
                                color:'#000000', 
                                hover:{ borderBottom: 'solid', borderColor:'red' } }}
                            > 
                            <strong>Enviar acuerdo Whatsapp</strong><Icon name="chevron right" />
                        </Button>
                        <NavBarSolicitud
                            evento={evento}
                            vistaActiva={vistaActiva}
                            setVistaActiva={setVistaActiva}
                        />

                        {renderizarVista()}
                    </Container>
                </Grid>
            )
        } else {
            return <Grid  textAlign='left' centered verticalAlign="middle">
                <Grid.Row width={16} style={{minHeight:"82vh"}}>
                    <Grid.Column style={{maxWidth:450}}>
                        <Form>
                            <Segment basic>
                                <Header as="h1">
                                    Bienvenido, <br></br>
                                    Inicia una nueva solicitud
                                </Header>
                            <Form.Select
                                style={{
                                    borderRadius: 0,
                                    lineHeight: '2rem'
                                }}
                                options={tipoIdentificacionOpciones}
                                label="Tipo de documento" 
                                value={peticion.identificacion.tipo}
                                name="tipo"
                                placeholder="Tipo de Documento"
                                onChange={onChangePeticion}
                            />
                            <Form.Input
                                label="Número"
                                name="numero"
                                value={peticion.identificacion.numero}
                                placeholder="Número de Identidad"
                                onChange={onChangePeticion}
                            />
                            

                            {rol === TIPO_ROL.ADMINISTRADOR_SISTEMA || rol === TIPO_ROL.IMPORTADORA 
                            ? 
                            <Form.Input
                                label="Concesionario (CL)"
                                name="concesionario"
                                value={peticion.concesionario}
                                onChange={onChageConcesionario}
                            />
                            :
                            <Form.Select
                                clearable
                                label="Concesionario (CL)"
                                name="concesionario"
                                options={opciones}
                                value={peticion.concesionario}
                                placeholder="CL Concesionario"
                                onChange={onChageConcesionario}
                            />
                            }
                            <Button 
                                style={{
                                    padding: 20,
                                    borderRadius: 0, 
                                    backgroundColor:'#000000',
                                    borderColor: '#000000',
                                    borderStyle:'solid',
                                    borderWidth:'1px',
                                    fontWeight: 'bold',
                                    color:'#FFFFFF'
                                }}
                                primary
                                size="tiny"
                                onClick={validarCliente}
                                content="Buscar"/>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    }
    const renderizarVista = () => {

        if (vistaActiva === VISTA_ACTIVA_FORMULARIO && evento === EVENTO_ACTUALIZAR){
            return(
                <Fragment>
                    <Grid columns={2} stretched>
                        <DatosCliente
                            setEditarCliente={setEditarCliente}
                            datosCliente={datosCliente}
                        />
                        <DatosVehiculo
                            setEditarVehiculo={setEditarVehiculo}
                            datosVehiculos={datosVehiculo}
                        />
                    </Grid>
                    <FormularioSolicitudes
                        actualizar={(payload) => ActualizarSolicitud(payload)}
                        consultaConcesiones={consultaConcesiones}
                        setModalPaquetes={setModalPaquetes}
                        setEditarVehiculo={setEditarVehiculo}
                        setDatosSolicitud={setDatosSolicitud}
                        setCargando={setCargando}
                        consultaVehiculoVin={(payload) => consultaVehiculoVin(payload)}
                        vehiculo={datosVehiculo}
                        datosSolicitud={datosSolicitud}
                        grupos={listaGrupos}
                        evento={evento}
                        concesionarios={listaConcesionarios}
                    />
                    <ModalEdicionCliente
                        cliente={datosCliente}
                        editarCliente={editarCliente}
                        ActualizarCliente={(payload) =>  ActualizarCliente(payload)}
                        setEditarCliente={setEditarCliente}
                    />
                </Fragment>        
            )
        } else if (vistaActiva === VISTA_ACTIVA_PAQUETES){
            return(
                <Fragment>
                    {datosPaquete !== undefined && datosPaquete.length>0 ? 
                    <Fragment>
                    <Header as="h2"style={{fontWeight:'bold', fontFamily:'kiaBold'}}> Paquetes
                        <span>
                            <Button 
                                size="mini"                     
                                style={{
                                    padding: 20,
                                    borderRadius: 0, 
                                    backgroundColor:'#FFFFFF',
                                    borderColor: '#000000',
                                    borderStyle:'solid',
                                    borderWidth:'1px',
                                    fontWeight: 'bold',
                                    color:'#000000'
                                }}
                                primary
                                onClick={() => setModalPaquetes(true)} 
                                content="Agregar paquete"
                                floated='right'
                            />
                        </span>
                    </Header>
                    <Container style={{overflowX: 'scroll', marginTop: 40}}>
                        <Table size="small" textAlign="center" celled structured singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">CODIGO DE PAQUETE</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">TIPO DE PAQUETE</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">ESTATUS INT.</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="3">MODELO</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2">VER MAS DETALLES</Table.HeaderCell>
                                    <Table.HeaderCell rowSpan="2"></Table.HeaderCell>

                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell> CODIGO </Table.HeaderCell>
                                    <Table.HeaderCell> VERSION </Table.HeaderCell>
                                    <Table.HeaderCell> DESCRIPCION</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {datosPaquete !== undefined && datosPaquete.length>0 ?
                                    datosPaquete.map((i, index) => (
                                        <Fragment>
                                        <ItemPaquete
                                            setMostrarDetalles={setMostrarDetalles}
                                            setPaquete={setPaquete}
                                            vistaActiva={vistaActiva}
                                            item={i}
                                            eliminarPaqueteDeLaSolicitud={eliminarPaquete}
                                        />
                                        </Fragment>
                                    ))
                                :
                                null
                                }
                            </Table.Body>
                        </Table>
                    </Container>
                    {paquete !== null ? 
                        <Modal
                            open={mostrarDetalles}
                            style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20 }}
                        >
                        <Modal.Header 
                            style={{ 
                                backgroundColor:'#FFFFFF', 
                                border:'none'}} 
                            as="h5">
                            <Header>
                            Detalles de {paquete.nombre}
                            </Header>
                        </Modal.Header>
                        <Modal.Content>
                            <DatosPaquete
                                i={paquete}
                            />
                        </Modal.Content>
                        <Modal.Actions style={{ backgroundColor:'#FFFFFF', border:'none', textAlign:'left'}}>
                            <Button
                                onClick={() => setMostrarDetalles(false)}
                                style={{
                                    padding: 20,
                                    borderRadius: 0, 
                                    backgroundColor:'#FFFFFF',
                                    borderColor: '#000000',
                                    borderStyle:'solid',
                                    borderWidth:'1px',
                                    fontWeight: 'bold',
                                    color:'#000000'
                                }}
                                primary
                                content="ACEPTAR"
                                size="tiny"
                            />
                        </Modal.Actions>
                        </Modal>: null}
                    </Fragment>
                    : 
                    <Card.Group textAlign="center">
                        <Card
                            style={{minHeight:'70vh'}}
                            fluid
                            color="red"
                        >
                            <Header textAlign="center" as="h1" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20, marginTop:65}}>
                                Esta solicitud no tiene ningún paquete
                            </Header>
                            <Header as="h1" textAlign="center">
                                <Icon size="huge" name="box"/>
                            </Header>
                            <Header textAlign="center">
                                <Button
                                    content="AGREGAR PAQUETE"
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    onClick={() => setModalPaquetes(true)}
                                />
                            </Header>
                        </Card>
                    </Card.Group>
                    }
                </Fragment> 
            )
        } else if (vistaActiva === VISTA_ACTIVA_REDENCION) {
            return(
                <Fragment>
                {datosSolicitud.paquetes.length > 0 ? 
                <Fragment>
                <Header as="h2"style={{fontWeight:'bold', fontFamily:'kiaBold'}}> Servicios a redimir
                </Header>
                <Container style={{overflowX: 'scroll', marginTop: 40}}>
                    <Table size="small" textAlign="center" celled structured singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">DESCRIPCION</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">TIPO</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">CANTIDAD</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">MANO OBRA UNIFICADA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">FRECUENCIA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="3">VIGENCIA</Table.HeaderCell>
                            <Table.HeaderCell colSpan="2">REDENCION</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2"> ACCIONES </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell> KILOMETRAJE</Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                            <Table.HeaderCell> AUTORIZADO </Table.HeaderCell>
                            <Table.HeaderCell> KILOMETRAJE </Table.HeaderCell>
                            <Table.HeaderCell> MESES </Table.HeaderCell>
                            <Table.HeaderCell> ESTADO </Table.HeaderCell>
                            <Table.HeaderCell> FECHA </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                        <Table.Body>
                            {datosSolicitud.paquetes.map( (i) => {
                                return i.detallesServicios.map( (j) => {
                                    return <ItemServicio
                                    setVerDetallesServicio={setVerDetallesServicio}
                                    setDetallesServicio={setDetallesServicio}
                                    verDetallesServicio={verDetallesServicio}
                                    vistaActiva={vistaActiva}
                                    key={j._id}
                                    item={j}
                                />
                                } )
                            } )}
                        </Table.Body>
                    </Table>
                </Container>
                </Fragment>
                : 
                <Card.Group textAlign="center">
                    <Card
                        style={{minHeight:'70vh'}}
                        fluid
                        color="red"
                    >
                        <Header textAlign="center" as="h1" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20, marginTop:65}}>
                            No hay servicios para redimir
                        </Header>
                        <Header as="h1" textAlign="center">
                            <Icon size="huge" name="settings"/>
                        </Header>
                    </Card>
                </Card.Group>
                }
            </Fragment> 
            )
        } else if (vistaActiva === VISTA_ACTIVA_SOPORTES) {

            return datosSolicitud.soportes !== undefined && datosSolicitud.soportes.length>0 ?
            <Fragment>
            <Header as="h2"style={{fontWeight:'bold', fontFamily:'kiaBold'}}> Soportes
            <span>
                <Button 
                    size="mini"                     
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    onClick={() => setModalAgregarSoporte(true)} 
                    content="Agregar soporte"
                    floated='right'
                />
            </span>
            </Header>
            <Container style={{overflowX: 'scroll', marginTop: 40}}>
                <Table textAlign="center" celled structured singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                        <Table.HeaderCell>TIPO</Table.HeaderCell>
                        <Table.HeaderCell>COMENTARIOS</Table.HeaderCell>
                        <Table.HeaderCell>FECHA DE CARGA</Table.HeaderCell>
                        <Table.HeaderCell>REVISADO</Table.HeaderCell>
                        <Table.HeaderCell>VER SOPORTE</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {datosSolicitud.soportes !== undefined && datosSolicitud.soportes.length>0 ?
                        datosSolicitud.soportes.map((i, index) => (
                            <ItemSoporte
                                datosSolicitud={datosSolicitud}
                                setDatosSolicitud={setDatosSolicitud}
                                actualizar={(payload) => ActualizarSolicitud(payload)}
                                item={i}
                                index={index}
                            />
                        ))
                    :
                    null
                    }
                </Table.Body>
            </Table>
            </Container>
            </Fragment>
            : 
            <Card.Group textAlign="center">
                    <Card
                        style={{minHeight:'70vh'}}
                        fluid
                        color="red"
                    >
                    <Header textAlign="center" as="h1" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20, marginTop:65}}>
                        Esta solicitud no tiene ningún soporte
                    </Header>
                    <Header as="h1" textAlign="center">
                        <Icon size="huge" name="clipboard check" />
                    </Header>
                    <Header textAlign="center">
                        <Button
                            content="AGREGAR SOPORTE"
                            style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                            onClick={() => setModalAgregarSoporte(true)}
                        />
                    </Header>
                </Card>
            </Card.Group>
                        
        } else if (vistaActiva === VISTA_ACTIVA_ACTIVACION) {
            return ( 
                <Fragment>
                    {datosPaquete !== undefined && datosPaquete.length>0 ? <Grid.Column style={{marginTop: 10}} stretched>
                        <Segment basic>
                            <Header textAlign="center" as="h1"> Paquetes a Activar</Header>
                            {datosPaquete.map( (i, index) => (
                                <Header key={index}> 
                                    {i.nombre}
                                    <Header.Subheader> {i.descripcion} </Header.Subheader>
                                </Header>
                            ))}
                            <Header>
                                <span>
                                    <Button
                                        content="Activar paquetes"
                                        onClick={() => setModalActivacion(true)}
                                        floated="right"
                                        primary
                                        style={{
                                            padding: 20,
                                            borderRadius: 0, 
                                            backgroundColor:'#FFFFFF',
                                            borderColor: '#000000',
                                            borderStyle:'solid',
                                            borderWidth:'1px',
                                            fontWeight: 'bold',
                                            color:'#000000'
                                        }} 
                                    />
                                </span>
                            </Header>
                        </Segment>
                    </Grid.Column>
                    :
                    <Card.Group textAlign="center">
                    <Card
                        style={{minHeight:'70vh'}}
                        fluid
                        color="red"
                    >
                        <Header textAlign="center" as="h1" style={{fontWeight:'bold', fontFamily:'kiaBold', marginBottom:20, marginTop:65}}>
                            No hay paquetes para activar
                        </Header>
                        <Header as="h1" textAlign="center">
                            <Icon size="huge" name="box"/>
                        </Header>
                    </Card>
                </Card.Group>}
                    <ModalActivacion
                        activar={ActualizarEstadoSolicitud}
                        setModalActivacion={setModalActivacion}
                        modalActivacion={modalActivacion}
                    />
                </Fragment>
                    
            )
        }
    }

    if ( cargando ) {
        return <Preloader />
    }
    return ( 
        <Fragment>
            {renderHeader()}
            {/* {renderizarVista()} */}
            <ModalMensaje
                evento={evento}
                eventoCallback={modalMensajeEstatus.eventoCallback}
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                objeto={modalMensajeEstatus.objeto}
                setEvento={setEvento}
                buscar={consultarPorId}
                reemplazar={ (payload) => reemplazarVehiculo(payload)}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
            <ConsultaModalPaquetes
                datosSolicitud={datosSolicitud}
                modalPaquetes={modalPaquetes}
                setDatosSolicitud={setDatosSolicitud}
                setModalPaquetes={setModalPaquetes}
                actualizar={(payload) => ActualizarSolicitud(payload)}
            />
            <ModalAgregarSoporte
                setModalMensajeEstatus={setModalMensajeEstatus}
                setModalAgregarSoporte={setModalAgregarSoporte}
                setDatosSolicitud={setDatosSolicitud}
                actualizar={(payload) => ActualizarSolicitud(payload)}
                datosSolicitud={datosSolicitud}
                modalAgregarSoporte={modalAgregarSoporte}
            />
            <ModalEdicionVehiculo
                editarVehiculo={editarVehiculo}
                setEditarVehiculo={setEditarVehiculo}
                vehiculo={datosVehiculo}
                agregarVehiculo={ (payload) => agregarVehiculoSolicitud(payload)}
                actualizarVehiculo={(payload) => ActualizarVehiculo(payload)}
            />
            <ModalSeleccionarVin
                vehiculos={vehiculos}
                seleccionarVehiculo={seleccionarVehiculo}
                setSeleccionarVehiculo={setSeleccionarVehiculo}
                agregarVehiculoSolicitud={ (payload) => agregarVehiculoSolicitud(payload)}
            />
            <ModalAcuerdoPrepagado 
                cliente={datosCliente}
                vehiculo={datosVehiculo}
                solicitud={datosSolicitud}
                activo={activoModalAcuerdoPrepagado}
                setEditarVehiculo={setEditarVehiculo}
                setStatusModal={setModalacuerdoPrepagado}
            />
            <ModalDetallesServicio
                verDetallesServicio={verDetallesServicio}
                detallesServicio={detallesServicio}
                consultar={refrescarSolicitud}
                idSolicitud={idUrl ? idUrl : {id}}
                setVerDetallesServicio={setVerDetallesServicio}
            />
        </Fragment>
     );

}
 
export default SolicitudesIC;