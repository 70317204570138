import React from 'react'
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import { Table, Button, Label } from 'semantic-ui-react';


const ItemModelo = ({
    setModalEliminar,
    setObjetoEliminar,
    setModalActivo,
    setEvento,
    setModelo,
    item
}) => {

    const datosInicialModelo = {
        _id: item._id,
        factorAjusteManoObra: item.factorAjusteManoObra,
        activo: item.activo,
        modelo: item.modelo,
        versiones: item.versiones,
        imagenes: item.imagenes
    }

    const botonActualizar = () => {
        setEvento(EVENTO_ACTUALIZAR)
        setModalActivo(true)
        setModelo(datosInicialModelo)
    }

    const botonEliminar = (id) => {
        setObjetoEliminar(id)
        setModalEliminar({
            activo: true
        })
    }

    return ( 
        <Table.Row>
            <Table.Cell> {item.modelo.nombre} </Table.Cell>
            <Table.Cell> {item.modelo.codigo} </Table.Cell>
            <Table.Cell>
                 {item.modelo.origen.map(o => (
                    <Label size="tiny" key={o}> {o} </Label>
                 ))}
            </Table.Cell>
            <Table.Cell> {item.modelo.anoModelo} </Table.Cell>
            <Table.Cell> 
                {item.versiones.map(v =>(
                    <Label size="tiny" key={v.codigo}>{v.codigo}</Label>
                ))}
            </Table.Cell>
            <Table.Cell> {item.factorAjusteManoObra} </Table.Cell>
            <Table.Cell>
                <Button
                    content="EDITAR"
                    onClick={botonActualizar}
                    icon="edit"
                    style={{backgroundColor:'#BB162B', color:'#F6F6F6', borderRadius:25}}
                    size="tiny"
                />
                <Button
                    content="ELIMINAR"
                    icon="trash"
                    onClick={() => botonEliminar(item._id)}
                    style={{borderRadius:25}}
                    size="tiny"
                />
            </Table.Cell>
        </Table.Row>
     );
}
 
export default ItemModelo;