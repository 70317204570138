import React, {useState, Fragment, useEffect } from 'react';
import { estadoInicialUsuario } from '../../estadosIniciales/usuarios/estadoInicial.usuario';
import { Modal, Form, Button, Icon, Grid, Segment, Checkbox, Message } from 'semantic-ui-react';
import { tipoIdentificacionOpciones } from '../../estadosIniciales/listas/identificacion-lista';
import { tipoRolOpciones } from '../../estadosIniciales/listas/roles-lista';
import { TIPO_ROL } from '../../enum/tipo-rol';
import { EVENTO_ACTUALIZAR, EVENTO_CREAR } from '../../enum/eventos/tipo-evento';


const FormularioUsuarios = ({
    evento,
    usuarioSeleccionado,
    actualizar,
    crear,
    concesionarios,
    grupos,
    modalActivo,
    setModalActivo,
    setEvento
}) => {
    
    const [ datosUsuario, setDatosUsuario ] = useState( Object.assign({}, estadoInicialUsuario ))
    const [ concesionesSeleccionada, setConcesionesSeleccionada] = useState([])
    const [ contrasena, setContrasena ] = useState({
        contrasena: '',
        confirmarContrasena: '',
    })
    const [ errorContrasena, setErrorContrasena ] = useState({
       estatus: false, 
       mensaje: ''
    })


    useEffect( ()=> {

        if ( evento === EVENTO_ACTUALIZAR ) {

            let tempConcesionarios = []
            concesionarios.map( i => {
                if ( usuarioSeleccionado.grupo === i.grupo ) {
                    tempConcesionarios.push({
                        key: i.key,
                        text: i.text,
                        value: i.value
                    })
                }
            } )
            setConcesionesSeleccionada( tempConcesionarios )
            setDatosUsuario( usuarioSeleccionado )
        }

    }, [ evento ] )

    useEffect( () => {
        validarContrasena(contrasena.contrasena, contrasena.confirmarContrasena);
    }, [contrasena])
    
    const validarContrasena = ( contrasena, confirmar) => {
        setErrorContrasena({
            estatus: false,
            mensaje: ''
        })

        if ( contrasena ) {

            if ( contrasena.length < 6 ) {
                return setErrorContrasena({
                    estatus: true,
                    mensaje: 'La contrasena debe tener al menos 6 caracteres'
                })
            }
        }

        if ( contrasena && confirmar ) {
            if ( contrasena !== confirmar ) {
                return setErrorContrasena({
                    estatus: true,
                    mensaje: 'Las contrasenas no coinciden'
                })
            } else {
                setDatosUsuario({
                    ...datosUsuario,
                    contrasena: contrasena
                })
            }
        }
    }

    const onChangesDatosUsuarios = (e, {name, value}) => {
        setDatosUsuario({
            ...datosUsuario,
            [name]:value
        })
    }
    const onChangeNombres = e => {
        setDatosUsuario({
            ...datosUsuario,
            nombre: {
                ...datosUsuario.nombre,
                [e.target.name]:e.target.value
            }
        })
    }
    const onChangeIdentificacion = ( e, {name, value}) => {
        setDatosUsuario({
            ...datosUsuario,
            identificacion: {
                ...datosUsuario.identificacion,
                [name]:value
            }
        })
    }
    const onChangeContrasena = ( e, {name, value} ) => {

        setContrasena({
            ...contrasena,
            [name]: value
        })
    }

    const buttonActivo = () => {
        setDatosUsuario({
            ...datosUsuario,
            activo: !datosUsuario.activo
        })
    }
    const switchLectura = () => {
        setDatosUsuario({
        ...datosUsuario,
            permisos: {
                ...datosUsuario.permisos,
                lectura: !datosUsuario.permisos.lectura
            }
        })
    }
    const switchEscritura = () => {
        setDatosUsuario({
        ...datosUsuario,
            permisos: {
                ...datosUsuario.permisos,
                escritura: !datosUsuario.permisos.escritura
            }
        })
    }
    const switchEliminar = () => {
        setDatosUsuario({
        ...datosUsuario,
            permisos: {
                ...datosUsuario.permisos,
                eliminar: !datosUsuario.permisos.eliminar
            }
        })
    }
    const desactivarModal = () => {
        setModalActivo(false)
        setEvento('')
        setDatosUsuario(estadoInicialUsuario)
        setContrasena({
            contrasena: '',
            confirmarContrasena: '',
        })
    }

    const onChangeGrupo = (evento, data) => {
        setDatosUsuario({
            ...datosUsuario,
            grupo: data.value,
            concesiones: []
        })
        let tempConcesionarios = []
        concesionarios.map( i => {
            if (data.value === i.grupo ) {
                tempConcesionarios.push({
                    key: i.key,
                    text: i.text,
                    value: i.value
                })
            }
        } )
        setConcesionesSeleccionada( tempConcesionarios )
    }

    const onChangeConcesionario = (evento, data ) => {
        setDatosUsuario({
            ...datosUsuario,
            concesiones: data.value
        })
    }
    const onChangeConcesionarioAsesor = (evento, data ) => {
        setDatosUsuario({
            ...datosUsuario,
            concesiones: [data.value]
        })
    }

    const seleccionarRol = () => {
        if ( datosUsuario.rol === TIPO_ROL.ADMINISTRADOR_CONCESIONARIO ) {
            return <Fragment>
                <Form.Select
                    placeholder="Requerido"
                    label="Grupo"
                    name="grupo"
                    options={grupos}
                    onChange={onChangeGrupo}
                    value={datosUsuario.grupo}
                />
                <Form.Select
                    multiple
                    label="Concesiones"
                    name="concesiones"
                    value={datosUsuario.concesiones}
                    options={concesionesSeleccionada}
                    onChange={(event, data)=> onChangeConcesionario(event, data)}
                />
            </Fragment>
        } else if ( datosUsuario.rol === TIPO_ROL.ADMINISTRADOR_SISTEMA ) {
            return <Form.Field>
                <p>No es necesario, este usuario tendra acceso a todos los concesionarios</p>
            </Form.Field>
        } else if ( datosUsuario.rol === TIPO_ROL.IMPORTADORA ) {
            return <Form.Field>
                <p>No es necesario, este usuario tendra acceso a todos los concesionarios</p>
            </Form.Field>
        } else {
            return <Fragment>
                <Form.Select
                    placeholder="Requerido"
                    label="Grupo"
                    name="grupo"
                    value={datosUsuario.grupo}
                    options={grupos}
                    onChange={onChangeGrupo}
                />
                <Form.Select
                    label="Concesiones"
                    name="concesiones"
                    value={datosUsuario.concesiones[0]}
                    options={concesionesSeleccionada}
                    onChange={(event, data)=> onChangeConcesionarioAsesor(event, data)}
                />
            </Fragment>
        }
        
    }

    const guardarUsuario = () => {
        if ( errorContrasena.estatus ) {
            return;
        }
        crear( datosUsuario )
    }

    const actualizarUsuario = () => {
        if ( errorContrasena.estatus ) {
            return;
        }

        if ( 
            contrasena.contrasena && contrasena.confirmarContrasena
            && contrasena.contrasena === contrasena.confirmarContrasena
            ) {
            
                setDatosUsuario({
                    ...datosUsuario,
                    contrasena: contrasena.contrasena
                })
        }
        actualizar( datosUsuario )
    }

    return ( 
        <Modal
            open={modalActivo}
        >
            <Modal.Header><Icon name="edit" color="red"/>Formulario Usuarios</Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Identificacion</h3>
                    <Form.Group
                        widths="equal"
                    >
                        <Form.Input
                            label="Nombres"
                            name="nombres"
                            value={datosUsuario.nombre.nombres}
                            onChange={onChangeNombres}
                        />
                        <Form.Input
                            label="Apellidos"
                            name="apellidos"
                            value={datosUsuario.nombre.apellidos}
                            onChange={onChangeNombres}
                        />
                        <Form.Input
                            label="Correo Electronico"
                            name="email"
                            value={datosUsuario.email}
                            onChange={onChangesDatosUsuarios}
                        />
                    </Form.Group>
                    <Form.Group> 
                        <Form.Select
                            label="Tipo de identificacion"
                            width="4"
                            options={tipoIdentificacionOpciones}
                            name="tipo"
                            value={datosUsuario.identificacion.tipo}
                            onChange={onChangeIdentificacion}
                        />
                        <Form.Input
                            label="Numero de identificacion"
                            width="4"
                            name="numero"
                            value={datosUsuario.identificacion.numero}
                            onChange={onChangeIdentificacion}
                        />
                    </Form.Group>
                    <h3>Contraseña</h3>
                    <Form.Group widths="equal" error>
                    <Form.Input
                        autocomplete="off"
                        placeholder="Nueva contrasena"
                        label="Contraseña"
                        type="password"
                        name="contrasena"
                        value={contrasena.contrasena}
                        onChange={onChangeContrasena}
                        error={errorContrasena.estatus}
                    />
                    <Form.Input
                        autocomplete="off"
                        label="Confirmar contrasena"
                        placeholder="Confirmar"
                        type="password"
                        name="confirmarContrasena"
                        value={contrasena.confirmarContrasena}
                        onChange={onChangeContrasena}
                        error={errorContrasena.estatus}
                    />
                    
                    </Form.Group>
                    {errorContrasena.estatus ? <Message
                        color='red'
                        header='Error'
                        content={errorContrasena.mensaje}
                    />: null }
                    <h3>Rol</h3>
                    <Form.Group
                        widths="equal"
                    >
                        
                        <Form.Input
                            label="Cargo"
                            name="cargo"
                            value={datosUsuario.cargo}
                            onChange={onChangesDatosUsuarios}
                        />
                        <Form.Select
                            label="Tipo de rol"
                            options={tipoRolOpciones}
                            value={datosUsuario.rol}
                            name="rol"
                            onChange={onChangesDatosUsuarios}
                        />
                    </Form.Group>
                    <h3>Concesiones</h3>
                    <Form.Group widths="equal">
                        {seleccionarRol()}
                    </Form.Group>
                    <Grid
                        columns={2} divided
                    >
                        <Grid.Row>
                            <Grid.Column>
                            <h3>Activacion De Usuario</h3>
                                <Segment>
                                    <h4>Nota:</h4>
                                    <Checkbox 
                                        label="Si el usuario esta desactivado no estará disponible"
                                        checked={datosUsuario.activo} 
                                        onChange={() => buttonActivo()}
                                        toggle 
                                    />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <h3>Permisos</h3>
                                        <p></p>
                                        <Checkbox 
                                            toggle
                                            label="Lectura"
                                            checked={datosUsuario.permisos.lectura}
                                            onChange={() => switchLectura()}
                                        />
                                        <p></p>
                                        <Checkbox 
                                            toggle
                                            label="Escritura"
                                            checked={datosUsuario.permisos.escritura}
                                            onChange={() => switchEscritura()}
                                        />
                                        <p></p>
                                        <Checkbox 
                                            toggle
                                            label="Eliminar"
                                            checked={datosUsuario.permisos.eliminar}
                                            onChange={() => switchEliminar()}
                                        />
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => desactivarModal()}
                    />
                    {evento === EVENTO_CREAR?<Button 
                        onClick={()=> guardarUsuario()}
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR" 
                        icon="save"
                    />:null}
                    {evento === EVENTO_ACTUALIZAR?<Button 
                        onClick={()=> actualizarUsuario()}
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR" 
                        icon="save"
                    />:null}
            </Modal.Actions>
        </Modal>
     );
}
 
export default FormularioUsuarios;

