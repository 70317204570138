export const estadoInicialFiltro = {
    modelo: '',
    pnc: '',
    referencia: '',
    descripcion: '',
    caracteristicas: {
        aireAcondicionado: '',
        tipoCombustible: '',
        transmision: ''
    },
    salto: 0,
    limite: 10
}