export const estadoInicialClientes  = {
    email: '',
    nombre: {
        nombres: '',
        apellidos: ''
    },
    identificacion:{
        numero: '',
        tipo: ''
    },
    direccion: {
        callePrincipal: '',
        calleSecundaria: '',
        numero: '',
        otros: '',
        barrio: '',
        ciudad: '',
        departamento: ''
    },
    telefonos: {
        celular: '',
        fijo: '',
        otros: ''
    }

}