import React, { useState, useEffect } from 'react';
import { Form, Button, Icon, Modal } from 'semantic-ui-react';
import { estadoInicialTempario} from '../../estadosIniciales/tempario/estadoInicial-tempario';
import Mensaje from '../../components/alertas/alertas.camposObligatorios';
import { EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';


const FormularioTemparios = ({
    tempario,
    modalActivo,
    evento, 
    guardar,
    actualizar,
    setModalActivo,
    setEvento
}) => {
 
    const [ datosTempario, setDatostempario ] = useState(Object.assign({}, estadoInicialTempario))
    const [ mensaje, setMensaje ] = useState(false)


    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatostempario(tempario)
        } else {
            setDatostempario(Object.assign({}, estadoInicialTempario))
        }
    }, [evento])
    const onChangeNumerosTempario = e => {
        const value = parseFloat(e.target.value)
        setDatostempario({
            ...datosTempario,
            [e.target.name] : value
        })
    }
    const onChangeTemparioRM = e => {
        const value = parseFloat(e.target.value)
        setDatostempario({
            ...datosTempario,
            rutinaMantenimiento: {
                ...datosTempario.rutinaMantenimiento,
                [e.target.name] : value
            }
        })
    }
    const onChangeStringTempario = e => {
        setDatostempario({
            ...datosTempario,
            [e.target.name] : e.target.value
        })
    }
    const guardarTempario = (payload) => {
        if(
            datosTempario.cl.trim() === '' ||
            datosTempario.codigoModelo.trim() === '' ||
            datosTempario.nombre.trim() === '' ||
            datosTempario.insumos === '' ||
            datosTempario.manoObra === '' ||
            datosTempario.rutinaMantenimiento.horas === '' ||    
            datosTempario.rutinaMantenimiento.kilometros === '' 
        ){
            setMensaje(true)
            return
        }
        guardar(payload)
    }

    const actualizarTempario = (payload) => {
        if(
            datosTempario.cl.trim() === '' ||
            datosTempario.codigoModelo.trim() === '' ||
            datosTempario.nombre.trim() === '' ||
            datosTempario.insumos === '' ||
            datosTempario.manoObra === '' ||
            datosTempario.rutinaMantenimiento.horas === '' ||    
            datosTempario.rutinaMantenimiento.kilometros === '' 
        ){
            setMensaje(true)
            return
        }
        actualizar(payload)
    }
    
    const desactivarModal = () => {
        setModalActivo(false)
        setEvento('')
    }

    return ( 
        <Modal
            open={modalActivo}
        >
            <Modal.Header><Icon name="edit" color="red"/>Formulario Temparios</Modal.Header>
            <Modal.Content>
                <Form>
                    <h3>Nombre Del Tempario</h3>
                    <Form.Group 
                        widths="equal"
                    >
                        <Form.Input
                            label="Nombre"
                            name="nombre"
                            value={datosTempario.nombre}
                            onChange={onChangeStringTempario}
                        />
                        <Form.Input
                            label="Codigo De Modelo"
                            name="codigoModelo"
                            value={datosTempario.codigoModelo}
                            onChange={onChangeStringTempario}
                        />
                        <Form.Input
                            label="Codigo Concesionario(cl)"
                            maxLength="5"
                            minLength="5"
                            name="cl"
                            value={datosTempario.cl}
                            onChange={onChangeStringTempario}
                        />
                    </Form.Group>
                    <h3>Valores</h3>
                    <Form.Group>
                        <Form.Input
                            label="Valor De los Insumos"
                            type="number"
                            width="5"
                            name="insumos"
                            value={datosTempario.insumos}
                            onChange={onChangeNumerosTempario}
                        />
                        <Form.Input
                            label="Tarifa Mano de Obra"
                            type="number"
                            width="5"
                            name="manoObra"
                            value={datosTempario.manoObra}
                            onChange={onChangeNumerosTempario}
                        />
                    </Form.Group>
                    <h3>Rutina de Mantenimiento</h3>
                    <Form.Group>
                        <Form.Input
                            label="Kilometros"
                            type="number"
                            width="5"
                            name="kilometros"
                            value={datosTempario.rutinaMantenimiento.kilometros}
                            onChange={onChangeTemparioRM}
                        />
                        <Form.Input
                            label="Horas"
                            type="number"
                            width="5"
                            name="horas"
                            value={datosTempario.rutinaMantenimiento.horas}
                            onChange={onChangeTemparioRM}
                        />
                    </Form.Group>
                </Form>
                {mensaje ? <Mensaje/> : null}
            </Modal.Content>
                <Modal.Actions>
                    <Button 
                        content="CANCELAR" 
                        icon="times"
                        size='tiny'
                        style={{ borderRadius:25 }}
                        onClick={() => desactivarModal()}
                    />
                    {evento === EVENTO_ACTUALIZAR ? 
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="ACTUALIZAR"
                        onClick={() => actualizarTempario(datosTempario)} 
                        icon="save"
                    /> :
                    <Button 
                        style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                        size="tiny" 
                        content="GUARDAR"
                        onClick={() => guardarTempario(datosTempario)} 
                        icon="save"
                    />
                    }

                </Modal.Actions>
        </Modal>
     );
}
 
export default FormularioTemparios;