import React, {useState, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Form, Segment, Button, Header, Image } from 'semantic-ui-react';
import { iniciarSesionAction } from '../../actions/usuario.action';
import { INICIAR_SESION } from '../../enum/eventos/tipo-evento'
import ModalMensaje from '../../components/modales/modalMensaje';
import logo2 from '../../assets/img/logos/Logo-mantenimiento-prepagado-rectangular-blanco-con-auto.png';
import imgBackGround from '../../assets/img/bg/bg.jpg';;

const InicioSesionUsuariosIC = () => {
    // eslint-disable-next-line
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })

    const mensajeActivo = useSelector(state => state.usuario.mensajeActivo)
    const mensaje = useSelector(state => state.usuario.mensaje)
    const dispatch = useDispatch();
    const [ datos, setDatos ] = useState({
        contrasena: '',
        email: ''
    })
    const {contrasena, email} = datos
    const onChangeDatos = e => {
        setDatos({
            ...datos,
            [e.target.name] : e.target.value
        })
    }
    
    const iniciarSesion = e => {
        e.preventDefault();
        dispatch(iniciarSesionAction({contrasena, email}))
    }


    return (
        <Fragment>
        <Grid 
            centered 
            style={{
                minHeight:"85vh",
                backgroundSize:"cover", 
                backgroundRepeat:"no-repeat", 
                backgroundPosition:"center"
            }} 
            verticalAlign="middle">
                 <Grid.Row width={16} style={{minHeight:"82vh", backgroundImage:`url(${imgBackGround})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                    <Grid.Column style={{maxWidth:450}}>
                        <Form
                            onSubmit={iniciarSesion}
                        >
                            <Segment basic>
                                <Image size="huge" src={logo2} style={{ padding: 10}} />
                                <Form.Input 
                                    label="Email" 
                                    name="email"
                                    value={datos.email} 
                                    onChange={onChangeDatos}
                                    fluid
                                    placeholder="E-mail address" 
                                />
                                <Form.Input 
                                    label="Contraseña" 
                                    name="contrasena" 
                                    value={datos.contrasena}
                                    onChange={onChangeDatos}
                                    fluid
                                    type="password" 
                                />

                                <Button
                                    content="Acceder"
                                    size='small'
                                    style={{
                                        padding: 20,
                                        borderRadius: 0, 
                                        backgroundColor:'#000000',
                                        borderColor: '#000000',
                                        borderStyle:'solid',
                                        borderWidth:'1px',
                                        fontWeight: 'bold',
                                        color:'#FFFFFF'
                                    }}
                                />  
                            </Segment>
                        </Form>
                    </Grid.Column>
                 </Grid.Row>
            
        </Grid>
        <ModalMensaje
            activo={mensajeActivo}
            mensaje={mensaje}
            evento={INICIAR_SESION}
            setModalMensajeEstatus={setModalMensajeEstatus}
        />
        </Fragment>
     );
}
 
export default InicioSesionUsuariosIC;