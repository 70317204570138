import React, {useState, useEffect} from 'react'
import { estadoInicialFiltro } from '../../estadosIniciales/solicitudes/estadoInicial-solicitud';
import {Grid, Segment, Button, Form} from 'semantic-ui-react'
import {estadoSolicitudesLista} from '../../estadosIniciales/listas/estadoSolicitud-lista';
import { TIPO_ROL } from '../../enum/tipo-rol';


const FiltroSolicitudes = ({setFiltro}) => {
    const [ datosFiltrado, setDatosFiltrado ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ opciones, setOpciones ] = useState([])
    const usuario = localStorage.getItem('usuario')
    const rol = localStorage.getItem('rol')

    useEffect(() => {
        const datosUsuario = JSON.parse(usuario)
        let opcionesConcesiones = []
        if(usuario){
           datosUsuario.concesiones.map(i => {
              return opcionesConcesiones.push({
                    value: i,
                    text: i,
                    key: i
                })
            })
            setOpciones(opcionesConcesiones)
        } 
    }, [usuario])

    const onChangeDatosFiltrado = (e, {name, value}) => {
        setDatosFiltrado({
            ...datosFiltrado,
            [name]:value
        })
    }

    const onChangeDatosFiltradoCl = (e, {name, value}) => {
            setDatosFiltrado({
                ...datosFiltrado,
                solicitud:{
                    ...datosFiltrado.solicitud,
                    concesionario: value
                }
            })
    }

    const onChangeClInput = e => {
        if(e){
            setDatosFiltrado({
                ...datosFiltrado,
                solicitud:{
                    ...datosFiltrado.solicitud,
                    concesionario: e.target.value.split(',')
                }
            })
        }
    }
    const onChangeDatosFiltradoSolicitud = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            solicitud:{
                ...datosFiltrado.solicitud,
                [e.target.name]:e.target.value
            }
        })
    }
    const buscarSolicitudes = () => {
        if(datosFiltrado.solicitud.concesionario.length === 0){
            setDatosFiltrado({
                ...datosFiltrado,
                solicitud:{
                    ...datosFiltrado.solicitud,
                    concesionario: ['']
                }
            })
            setFiltro({
                ...datosFiltrado,
                solicitud:{
                    ...datosFiltrado.solicitud,
                    concesionario: ['']
                }
            })
        } else {
            if ( datosFiltrado.solicitud.concesionario.length > 1) {
                let concesiones = datosFiltrado.solicitud.concesionario
                concesiones.splice( 0, 1 )
                setFiltro({
                    ...datosFiltrado,
                solicitud:{
                    ...datosFiltrado.solicitud,
                    concesionario: concesiones
                }
                })
            } else {
                setFiltro(datosFiltrado)
            }
            
        }
        
    }
    const onChangeFecha = e => {
        setDatosFiltrado({
            ...datosFiltrado,
            fecha:{
                ...datosFiltrado.fecha,
                [e.target.name]:e.target.value
            }
        })
    }

    return ( 
        <Grid style={{ marginTop: 5}}>
            <Grid.Column>
                <Segment
                    size="mini"
                >
                    <Form>
                        <h3>Filtrar Por</h3>
                        <Form.Group
                            widths="equal"
                        >
                            {rol === TIPO_ROL.ADMINISTRADOR_SISTEMA || rol === TIPO_ROL.IMPORTADORA 
                            ? 
                            <Form.Input
                                label="Concesionario (cl)"
                                name="concesionario"
                                value={datosFiltrado.solicitud.concesionario}
                                onChange={onChangeClInput}
                            />
                            :
                            <Form.Select
                                multiple
                                clearable
                                label="Concesionario (cl)"
                                name="concesionario"
                                options={opciones}
                                value={datosFiltrado.solicitud.concesionario}
                                onChange={onChangeDatosFiltradoCl}
                            />
                            }
                            <Form.Input
                                label="Numero"
                                placeholder="Numero de Solicitud"
                                name="numero"
                                value={datosFiltrado.solicitud.numero}
                                onChange={onChangeDatosFiltradoSolicitud}
                            />
                            <Form.Input
                                label="Vin"
                                name="vin"
                                value={datosFiltrado.solicitud.vin}
                                onChange={onChangeDatosFiltradoSolicitud}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                type="Date"
                                label="Desde"
                                name="desde"
                                value={datosFiltrado.fecha.desde}
                                onChange={onChangeFecha}

                            />                            
                            <Form.Input
                                type="Date"
                                label="Hasta"
                                name="hasta"
                                value={datosFiltrado.fecha.hasta}
                                onChange={onChangeFecha}

                            />
                            <Form.Select
                                clearable
                                label="Estado"
                                value={datosFiltrado.estado}
                                name="estado"
                                options={estadoSolicitudesLista}
                                onChange={onChangeDatosFiltrado}
                            />
                        </Form.Group>
                            <span>
                                <Button
                                    style={{ backgroundColor:"#bb162b", color:"#f6f6f6", borderRadius:25}}
                                    size="mini"
                                    icon="search"
                                    content="BUSCAR"
                                    onClick={() => buscarSolicitudes()}
                                />
                            </span>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
     );
}
 
export default FiltroSolicitudes;