import React, {Fragment} from 'react'
import { Route, HashRouter, Switch } from 'react-router-dom';
import VariablesIC from '../../vistas/variables/VariablesIC';
import NavBar from '../../components/navbars/NavBarAutenticada';
import TempariosPrepagadoIC from '../../vistas/tempariosPrepagado/TempariosPrepagadoIC';
import RedemirIC from '../../vistas/redimir/RedimirIC';
import UsuarioIC from '../../vistas/usuarios/UsuariosIC';
import ReportesIC from '../../vistas/reportes/ReportesIC';
import ModelosIC from '../../vistas/modelos/ModelosIC';
import PrincipalUsuarioIC from '../../vistas/principal/PrincipalUsuariosIC';
import FooterComponent from '../../components/footers/FooterComponent';
import ComplementariosIC from '../../vistas/complementarios/ComplementariosIC';
import ConcesionesIC from '../../vistas/concesiones/ConcesionesIC';
import RepuestosIC from '../../vistas/repuestos/RepuestosIC';
import OperacionesIC from '../../vistas/operaciones/operacionesIC';
import PaquetesIC from '../../vistas/paquetes/PaquetesIC';
import SolicitudIC from '../../vistas/solicitudes/SolicitudesIC';
import PresentacionModelosIC from '../../vistas/presentacionModelos/PresentacionModeloIC';
import CargaReporteRetailIC from '../../vistas/cargaReporteRetail/CargaReporteRetailIC';
import ComisionesIC from '../../vistas/comisiones/ComisionesIC';
import TerminosCondicionesIC from '../../vistas/terminosCondiciones/TerminosCondicionesIC'
import VistaPresentacionModelosIC from '../../vistas/vistasPresentacionModelos/vistaPresentacionModelos';
import VistaPresentacionModelosPaquetesIC from '../../vistas/vistasPresentacionModelos/vistaPresentacionPaquetes';
import VersionesIC from '../../vistas/versiones/VersionesIC';
import ClienteIC from '../../vistas/clientes/ClienteIC';
import TemparioNormal from '../../vistas/tempariosNormal/TemparioNormalIC';
import ServiciosIC from '../../vistas/servicios/ServiciosIC'
import DetallesServicio from '../../vistas/detalles/detalles-servicio';
import VehiculosIC from '../../vistas/vehiculos/VehiculosIC';
import DetallesPaquete from '../../vistas/detalles/detalles-paquete';
import ListaSolicitudesIC from '../../vistas/solicitudes/ListaSolicitudesIC';
import TerminosCondicionesCliente from '../../vistas/terminosCondiciones/TerminosCondicionesCliente';
import DetallesSolicitudIC from '../../vistas/solicitudes/DetallesSolicitudIC';
import ServiciosRedimirIC from '../../vistas/redimir/ServiciosRedimirIC';
import { AcuerdoClienteIC } from '../../vistas/acuerdo/AcuerdoClienteIC';
import RedimirServiciosIC from '../../vistas/redimir/RedimirGeneralIC';


const VistaAutenticada = () => {

    return ( 
        <Fragment>
            <HashRouter basename="/kiausers/">
                <NavBar/>
                <Switch>
                    <Route exact path="/kiausers/Home" component={PrincipalUsuarioIC} />
                    <Route exact path="/uploadReport" component={CargaReporteRetailIC} />
                    <Route exact path="/salesCommisions" component={ComisionesIC} />
                    <Route exact path="/clientes" component={ClienteIC} />
                    <Route exact path="/quotation" component={PresentacionModelosIC} />
                    <Route exact path="/vistaModelos/:id" component={VistaPresentacionModelosIC} /> 
                    <Route exact path="/vistaPaquetes/:version" component={VistaPresentacionModelosPaquetesIC} />
                    <Route exact path="/detallesServicio/:id" component={DetallesServicio} />
                    <Route exact path="/detallesPaquete/:id" component={DetallesPaquete} />
                    <Route exact path="/listaSolicitudes" component={ListaSolicitudesIC} />
                    <Route exact path="/detallesSolicitud/:id" component={DetallesSolicitudIC} />
                    <Route exact path="/serviciosRedimir/:id" component={ServiciosRedimirIC} />
                    <Route exact path='/vehiculos' component={VehiculosIC}/>
                    <Route exact path="/nuevaSolicitud" component={SolicitudIC} />
                    <Route exact path="/servicios" component={ServiciosIC} />
                    <Route exact path="/operations" component={OperacionesIC} />
                    <Route exact path="/paquetes" component={PaquetesIC} />
                    <Route exact path="/users" component={UsuarioIC} />
                    <Route exact path="/parts" component={RepuestosIC} />
                    <Route exact path="/dealers" component={ConcesionesIC} />
                    <Route exact path="/variables" component={VariablesIC} />
                    <Route exact path="/complementary" component={ComplementariosIC} />
                    <Route exact path="/temparioNormal" component={TemparioNormal}/>
                    <Route exact path="/temparioPrepagado" component={TempariosPrepagadoIC} />
                    <Route exact path="/redention" component={RedemirIC} />
                    <Route exact path="/redimir-servicios/:vin" component={RedimirServiciosIC} />
                    <Route exact path="/reports" component={ReportesIC} />
                    <Route exact path="/models" component={ModelosIC} />
                    <Route exact path="/terminosCondiciones" component={TerminosCondicionesIC} />
                    <Route exact path="/versiones" component={VersionesIC} />
                    <Route exact path="/acuerdo-prepagado" component={TerminosCondicionesCliente} />
                    <Route exact path="/acuerdo-cliente/:id" component={AcuerdoClienteIC} />
                    <Route component={PrincipalUsuarioIC} />
                </Switch>
                <FooterComponent/>
            </HashRouter>
        </Fragment>
    );

}
 
export default VistaAutenticada;