import React, {useState} from 'react';
import {Grid, Modal, Container, Header, Loader} from 'semantic-ui-react'
import {EVENTO_RETOMAR, EVENTO_ACTUALIZAR } from '../../enum/eventos/tipo-evento';
import { estadoInicialVehiculo } from '../../estadosIniciales/vehiculos/estadoInicial-vehiculos';
import { crearVehiculos, actualizarVehiculos, eliminarVehiculos, consultaVehiculosId} from '../../servicios/vehiculos/vehiculos.srv';
import ModalMensaje from '../../components/modales/modalMensaje'
import ModalEliminar from '../../components/modales/modalEliminar';
import FormularioVehiculos from '../../components/formularios/formulario.vehiculos';

const VehiculosIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Vehiculo',
        mensaje: '¿Esta seguro que quiere eliminar este vehiculo?'
    }
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ vehiculoEliminar, setVehiculoEliminar ] = useState('')
    const [ datosVehiculos, setDatosVehiculos ] = useState(Object.assign({}, estadoInicialVehiculo))
    const [ cargando, setCargando ] = useState(false)
    const [ id, setId ] = useState('')
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    const [ evento, setEvento ] = useState(EVENTO_RETOMAR)

    
    const consultarporId = async() => {
        const respuesta = await consultaVehiculosId({id})
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setEvento(EVENTO_ACTUALIZAR)
            setDatosVehiculos(respuesta.data.data)
        }
    }

    //CRUD
    //CREAR
    const CrearVehiculo = async() => {
        const respuesta = await crearVehiculos(datosVehiculos)
        if(!respuesta.data.estatus && respuesta.data.resultadoOperacion){
            setId(respuesta.data.data.id)
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setEvento('')
            setDatosVehiculos(Object.assign({}, estadoInicialVehiculo))
        }
    }
    //ACTUALIZAR
    const ActualizarVehiculo = async() => {
        const respuesta = await actualizarVehiculos(datosVehiculos)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
        } else {
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            setEvento('')
            setDatosVehiculos(Object.assign({}, estadoInicialVehiculo))
        }
    }
    //ELIMINAR
    const EliminarVehiculo = async(id) => {
        const respuesta = await eliminarVehiculos({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            setEvento('')
            setDatosVehiculos(Object.assign({}, estadoInicialVehiculo))
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
        }
    }
    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid >
                <Header 
                    size="huge" 
                    as="h1" 
                    style={{ 
                        fontSize:50,
                        spacing:'-0.04em',
                        fontWeight:'400', 
                        fontFamily:'kiaBold', 
                        marginBottom:20, 
                        marginTop:30 }}>
                    {evento === EVENTO_ACTUALIZAR? `Actualizar vehiculo ${datosVehiculos.placa}`: 'Crear vehiculo'}
                </Header>
                <FormularioVehiculos
                    datosVehiculos={datosVehiculos}
                    evento={evento}
                    setModalEliminar={setModalEliminar}
                    setObjetoEliminar={setVehiculoEliminar}
                    actualizar={ActualizarVehiculo}
                    guardar={CrearVehiculo}
                    setEvento={setEvento}
                    setDatosVehiculos={setDatosVehiculos}
                />
                <ModalMensaje
                    activo={modalMensajeEstatus.activo}
                    mensaje={modalMensajeEstatus.mensaje}
                    evento={evento}
                    setEvento={setEvento}
                    setModalMensajeEstatus={setModalMensajeEstatus}
                    buscar={consultarporId}
                />
                <ModalEliminar
                    modalEliminarActivo={modalEliminar}
                    objetoEliminar={vehiculoEliminar}
                    contenido={modalEliminarContenido}
                    eliminar={(value) => EliminarVehiculo(value)}
                    setObjetoEliminar={setVehiculoEliminar}
                    setModalEliminar={setModalEliminar}
                    setCargando={setCargando}
                />
            </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
    </Grid>
     );
}
 
export default VehiculosIC;

