import React, {useState, useEffect, Fragment} from 'react';
import {estadoInicialOperacion} from '../../estadosIniciales/operaciones/estadoInicial.operacion';
import {Grid, Table, Button, Loader, Modal , Container, Header, Pagination} from 'semantic-ui-react';
import {EVENTO_CREAR, EVENTO_ACTUALIZAR} from '../../enum/eventos/tipo-evento';
import { estadoInicialFiltro } from '../../estadosIniciales/operaciones/estadoInicial.operacion';
import { consultaOperaciones, crearOperaciones, actualizarOperaciones, eliminarOperaciones} from '../../servicios/operaciones/operaciones.srv';
import FormularioOperaciones from '../../components/formularios/formulario.operaciones';
import ItemOperaciones from '../../components/itemsListados/item-operaciones';
import ConsultaModalRepuestos from '../../components/modales/modalSeleccionRepuestos';
import ModalMensaje from '../../components/modales/modalMensaje';
import ModalEliminar from '../../components/modales/modalEliminar';
import FiltroOperaciones from '../../components/filtros/filtro.operaciones';
import NoItems from '../../components/utilidades/noItems';
import Preloader from '../../components/utilidades/preloader';


const OperacionesIC = () => {

    const modalEliminarContenido = {
        titulo: 'Eliminar Operacion',
        mensaje: '¿Esta seguro que quiere eliminar esta operacion?'
    }
    const [ datosOperacion, setDatosOperacion ] = useState(Object.assign({}, estadoInicialOperacion))
    const [ conjuntoRepuestos, setConjuntoRepuestos ] = useState([])
    const [ operacionEliminar, setOperacionEliminar ] = useState('')
    const [ modalEliminar, setModalEliminar ] = useState(false)
    const [ evento, setEvento ] = useState('')
    const [ modalActivo, setModalActivo ] = useState(false)
    const [ operaciones, setOperaciones ] = useState([])
    const [ operacion, setOperacion ] = useState({})
    const [ cargandoLoader, setCargandoLoader ] = useState(true)
    const [ cargando, setCargando ] = useState(false)
    const [ modalRepuestos, setModalRepuestos ] = useState(false)
    const [ modalMensajeEstatus, setModalMensajeEstatus ] = useState({
        activo: false,
        mensaje: ''
    })
    
    useEffect(() => {
        if(evento === EVENTO_ACTUALIZAR){
            setDatosOperacion(operacion)
        } else{
            setDatosOperacion(Object.assign({}, estadoInicialOperacion))
        }
    }, [evento])
    
    //PETICION A LA LISTA
    const [ filtro, setFiltro ] = useState(Object.assign({}, estadoInicialFiltro))
    const [ paginacion, setPaginacion ] = useState({
        paginasTotales: 1,
        paginaSeleccionada: 1
    })

    const onChangePaginador = (e, {activePage}) => {
        setPaginacion({
            ...paginacion,
            paginaSeleccionada:activePage
        })
        setFiltro({
            ...filtro,
            salto: (activePage - 1) * 10 
        })
    }

    const consultar = async() => {
        const respuesta = await consultaOperaciones(filtro)
        if(!respuesta.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.resultadoOperacion
            })
            setCargandoLoader(false)
        } else {
            setOperaciones(respuesta.data.operaciones)
            setPaginacion({
                ...paginacion,
                paginasTotales: Math.ceil(respuesta.data.total / 10)
            })
            setCargandoLoader(false)
        }
    }
    useEffect(() => {
        if(filtro){
            consultar()
        }
    }, [filtro])
    //CRUD
    //CREAR
    const CrearOperaciones = async(payload) => {
        const respuesta = await crearOperaciones(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    } 
    //ACTUALIZAR
    const ActualizarOperaciones = async(payload) => {
        const respuesta = await actualizarOperaciones(payload)
        if(!respuesta.data.estatus){
            setModalMensajeEstatus({
                ...modalMensajeEstatus,
                activo: true,
                mensaje: respuesta.data.resultadoOperacion
            })
            consultar(filtro)
        } else {
            setModalActivo(false)
            setEvento('')
            consultar(filtro)
        }
    }
    //ELIMINAR
    const EliminarOperaciones = async(id) => {
        const respuesta = await eliminarOperaciones({id})
        if(respuesta.data.estatus){
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        } else{
            setTimeout(() => {
                setModalMensajeEstatus({
                    ...modalMensajeEstatus,
                    activo: true,
                    mensaje: respuesta.data.resultadoOperacion
                })
                setCargando(false)
            }, 500);
            consultar(filtro)
        }
    }

    
    if(cargandoLoader){
        return <Preloader/>
    }

    return ( 
        <Grid style={{marginTop:100, marginBottom:50, marginLeft:50, marginRight:50, minHeight:'70vh'}}>
        <Container fluid>
            <Header 
                size="huge" 
                as="h1" 
                style={{ 
                    fontSize:50,
                    spacing:'-0.04em',
                    fontWeight:'400', 
                    fontFamily:'kiaBold', 
                    marginBottom:20, 
                    marginTop:30 }}>
                Operaciones
                <span>
                <Button 
                    size="mini"
                    onClick={() => { setEvento(EVENTO_CREAR); setModalActivo(true)}}
                    content="Nueva operacion"
                    style={{
                        padding: 20,
                        borderRadius: 0, 
                        backgroundColor:'#FFFFFF',
                        borderColor: '#000000',
                        borderStyle:'solid',
                        borderWidth:'1px',
                        fontWeight: 'bold',
                        color:'#000000'
                    }}
                    primary
                    floated='right'
                />
                </span>
            </Header>
            <FiltroOperaciones
                filtro={filtro}
                setFiltro={setFiltro}
            />
            <FormularioOperaciones
                evento={evento}
                operacion={operacion}
                modalActivo={modalActivo}
                datosOperacion={datosOperacion}
                conjuntoRepuestos={conjuntoRepuestos}
                consultar={consultar}
                actualizar={(payload) => ActualizarOperaciones(payload)}
                guardar={(payload) => CrearOperaciones(payload)}
                setEvento={setEvento}
                setModalActivo={setModalActivo}
                setModalRepuestos={setModalRepuestos}
                setDatosOperacion={setDatosOperacion}
            />
            <ConsultaModalRepuestos
                setDatosOperacion={setDatosOperacion}
                setConjuntoRepuestos={setConjuntoRepuestos}
                setModalRepuestos={setModalRepuestos} 
                datosOperacion={datosOperacion}
                operacion={operacion}
                evento={evento}
                conjuntoRepuestos={conjuntoRepuestos}
                modalActivoRepuestos={modalRepuestos}
            />
            <Fragment>
                <Container fluid style={{overflowX: 'scroll', marginTop:20, marginBottom:20 }}>
                <Table fixed={true} celled padded textAlign ="center">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">NOMBRE</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">CODIGO</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">HORAS</Table.HeaderCell>
                            <Table.HeaderCell colSpan="4">APLICA PARA</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2" singleLine={true}>ACCIONES</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>AIRE</Table.HeaderCell>
                            <Table.HeaderCell>COMBUSTIBLE</Table.HeaderCell>
                            <Table.HeaderCell>TRANSMISION</Table.HeaderCell>
                            <Table.HeaderCell>CILINDRADA</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {operaciones.map(i => (
                            <ItemOperaciones
                                setModalRepuestos={setModalRepuestos}
                                setModalEliminar={setModalEliminar}
                                setObjetoEliminar={setOperacionEliminar}    
                                setModalActivo={setModalActivo}
                                setEvento={setEvento}
                                setOperacion={setOperacion}
                                item={i}
                                key={i._id}
                            />
                        ))}
                    </Table.Body>
                </Table>
                </Container>
                {operaciones.length < 1 ? <NoItems /> : null }
                <Pagination 
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    totalPages={paginacion.paginasTotales}
                    activePage={paginacion.paginaSeleccionada}
                    onPageChange={onChangePaginador}
                />
            </Fragment>
            <ModalMensaje
                activo={modalMensajeEstatus.activo}
                mensaje={modalMensajeEstatus.mensaje}
                setModalMensajeEstatus={setModalMensajeEstatus}
            />
            <ModalEliminar
                modalEliminarActivo={modalEliminar}
                objetoEliminar={operacionEliminar}
                contenido={modalEliminarContenido}
                eliminar={(value) => EliminarOperaciones(value)}
                setObjetoEliminar={setOperacionEliminar}
                setModalEliminar={setModalEliminar}
                setCargando={setCargando}
            />
        </Container>
            {
            cargando 
                ? 
            <Modal open>
            <Loader size="large" inverted active/>
            </Modal>
                : 
            null
            }
        </Grid>
    );
}
 
export default OperacionesIC;